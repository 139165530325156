import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormMessage from "common/func/form/FormMessage";
import BackToLogin from "common/func/navlinks/BackToLogin";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap'
import {Field, reduxForm} from 'redux-form';
import {Auth} from 'aws-amplify';
import FormInput from 'common/func/form/FormInput';
import {history} from "redux/store/configureStore";


class ResendValidationCode extends Component {

    static propTypes = {
        handleSubmit: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            message: null,
            isCodeSent: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(formProps) {
        try {
            if (!this.state.isCodeSent) {
                await Auth.resendSignUp(formProps.username).then(data => {
                    //console.log("ResendValidationCode :: handleSubmit :: Auth.resendSignUp", data)

                    let message;
                    if (data.CodeDeliveryDetails && data.CodeDeliveryDetails.AttributeName) {
                        if (data.CodeDeliveryDetails.AttributeName === 'phone_number') {
                            message = "Please check your SMS for your validation code."
                        } else {
                            message = "Please check your email for your validation code."
                        }
                    } else {
                        //fallback default value
                        message = "Please check your email or SMS for your validation code."
                    }

                    this.setState({
                        message: message,
                        isCodeSent: true
                    })
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        message: err.message
                    })
                });
            }else{
                await Auth.confirmSignUp(formProps.username, formProps.code).then(data => {
                    //console.log("ResendValidationCode :: handleSubmit :: Auth.confirmSignUp", data)

                    this.setState({
                        message: "Validation successful! You'll be redirected to login in 5 seconds."
                    })

                    setInterval(() => {
                        history.push('/login');
                    }, 5000);
                }).catch(err => {
                    //console.log(err)
                    this.setState({
                        message: err.message
                    })
                });
            }
        } catch (e) {
            //console.log('error resending code: ', e);
            this.setState({
                message: e.message
            })
        }
    }

    render() {
        //console.log('ForgotPassword :: Render', this.props);
        const {handleSubmit, submitting} = this.props;
        const {message} = this.state;

        return (
            <section className="login py-5">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-4">
                        <h2 className="">Resend Validation Code</h2>

                        <form method="post" autoComplete="off" onSubmit={handleSubmit(this.handleSubmit)}>
                            <div className="">
                                <input type="hidden" name="username" value={this.state.username}/>
                                <Field
                                    name="username"
                                    component={FormInput}
                                    type="text"
                                    label="Username"
                                    autoComplete="off"
                                    value=""
                                >
                                </Field>
                            </div>

                            {this.state.isCodeSent &&
                            <Field
                                name="code"
                                component={FormInput}
                                type="text"
                                label="Code"
                                autoComplete="off"
                                value=""
                            >
                            </Field>
                            }


                            {message &&
                            <FormMessage message={message}/>
                            }

                            <div className="float-left">
                                <BackToLogin/>
                            </div>

                            {!this.state.sent &&
                                <div className="float-right">
                                    <Button type="submit" className="btn btn-primary mx-1"
                                            disabled={submitting}>Resend
                                    </Button>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </section>
        )
    }
}


/**
 * Connect the component to the Redux store.
 */
export default reduxForm({
    form: 'ForgotPassword', // ←A Unique identifier for this form
    validate: values => {
        const errors = {};

        if (!values.email) {
            errors.email = '(The email field is required.)'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address.'
        }

        return errors
    }  // ←Callback function for client-side validation
})(ResendValidationCode);
