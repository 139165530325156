import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';
//**********************************************************
import configureActions from "redux/store/configureActions";
import {Redirect} from "react-router-dom";

class Logout extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.actions.invalidateToken();
    }

    render() {
        return (
            <Redirect to="/login"/>
        )
    }
}

export default connect(
    (state) => {
        return state.auth;
    },
    (dispatch) => {
        let actions = configureActions(dispatch, _.assign({}));
        return {
            actions: actions
        }
    }
)(Logout);
