import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';

import {Card} from 'react-bootstrap';
import {globalPlayer} from "common/components/player/media-player/AudioPlayer";

class SongListCard extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            song: {}
        };
    }

    handlePlaySong = () => {
        globalPlayer.enqueueSong(this.props.song)
    };

    render() {

        const {song} = this.props;

        return (
            <Card className="songlist-card mb-2 p-2">
                <div className="row align-items-center">
                    <div className="col-md-5">
                        <div className="text-left link" onClick={this.handlePlaySong}>
                            <FontAwesomeIcon icon="play-circle" size="sm"/> {song.artist} - {song.title}
                        </div>
                        <div className="text-left"><small><b>{song.genre}</b></small></div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-2">
                        <div className="text-right"><b>{song.score ? song.score + ' %' : 'N/A'}</b></div>
                    </div>
                    <div className="col-md-2">
                        <div className="text-left"><small>{song.played} plays</small></div>
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-primary">Like</button>
                        <button className="btn btn-primary">Dislike</button>
                    </div>
                </div>
            </Card>
        )
    }
}

export default SongListCard
