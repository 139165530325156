const Env = {
    APP_ENV: process.env.REACT_APP_ENV || "local",
    API_URL: process.env.REACT_APP_API_URL || "http://localhost:8080/api",
    DEBUG:
        process.env.REACT_APP_DEBUG || process.env.REACT_APP_ENV === undefined, //Will be true when run with 'npm run watch'
    PAYMENT: {
        STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
        PRODUCTS: {
            MONTHLY: process.env.REACT_APP_PRODUCT_MONTHLY,
            YEARLY: process.env.REACT_APP_PRODUCT_YEARLY,
        },
    },
};

if (Env.DEBUG) {
    //console.log("*** CLIENT DEBUGGING IS ENABLED ***");
    window["Env"] = Env; //for debugging To debug
}

export default Env;
