import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router'
import {Redirect, Route, Switch} from 'react-router-dom';
//***************************************************************************************
import configureStore, {history} from 'redux/store/configureStore';
//***************************************************************************************
// Import higher order components (HOC)
import RequireAuth from 'common/hoc/RequiredAuth';
//***************************************************************************************
// Import custom components
import {AuthActions} from 'redux/store/actions/Actions';
//***************************************************************************************
// LAYOUTS COMPONENTS & ERROR HANDLER
//***************************************************************************************
import PublicLayout from 'components/layouts/PublicLayout';
import PrivateLayout from 'components/layouts/PrivateLayout';
import DefaultLayout from 'components/layouts/DefaultLayout';
import FullWidthLayout from 'components/layouts/FullWidthLayout';
//***************************************************************************************
import NotFoundPage from 'components/error/NotFoundPage';
import ErrorBoundary from 'components/error/ErrorBoundary';
//***************************************************************************************
// PUBLIC COMPONENTS
//***************************************************************************************
import Login from 'components/routes/public/auth/Login';
import Register from 'components/routes/public/auth/Register';
import ForgotPassword from 'components/routes/public/auth/ForgotPassword';
import ResendValidationCode from 'components/routes/public/auth/ResendValidationCode';
import ResetPassword from 'components/routes/private/account/ResetPassword';

import Logout from 'components/routes/public/auth/Logout';
import Home from './components/routes/public/home/home.component';
import Artists from './components/routes/public/artists/artists.component';
import Songs from './components/routes/public/songs/songs.component';
import Videos from './components/routes/public/videos/videos.component';
import Fans from './components/routes/public/fans/fans.component';
//---------------------
import Artist from './components/routes/public/artist/artist.component';
//***************************************************************************************
// PRIVATE COMPONENTS
//***************************************************************************************
import Dashboard from './components/routes/private/dashboard/dashboard.component';
import MyProfile from './components/routes/private/profile/my.profile.component';
import MySongs from './components/routes/private/profile/my.songs.component';
import PlayerBottom from 'common/components/player/PlayerBottom';
//***************************************************************************************
import Discover from "components/routes/private/demo/discover/Discover";
import Recommendation from "components/routes/private/demo/recommendation/Recommendation";
//***************************************************************************************
// AWS Cognito
import Amplify from 'aws-amplify';
import aws from './configs/Aws';
//***************************************************************************************
// Import font-awesome components
//***************************************************************************************
import {library} from '@fortawesome/fontawesome-svg-core'
import {faInstagram, faTwitter, faVimeo, faYoutube} from '@fortawesome/free-brands-svg-icons'
import {faHeart as faHeartRegular} from '@fortawesome/free-regular-svg-icons'

import {
    faBars,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faFastBackward,
    faFastForward,
    faGripHorizontal,
    faGripLines,
    faGripLinesVertical,
    faGripVertical,
    faHeadphones,
    faHeart,
    faHome,
    faListOl,
    faMusic,
    faPause,
    faPauseCircle,
    faPlay,
    faPlayCircle,
    faRedo,
    faRedoAlt,
    faSearch,
    faSpinner,
    faStepBackward,
    faStepForward,
    faTerminal,
    faTimes,
    faTrash,
    faUserCircle,
    faVideo
} from '@fortawesome/free-solid-svg-icons'

import Subscriptions from 'components/routes/private/account/Subscriptions';
//***************************************************************************************


//***************************************************************************************
// CONFIGURING FONT-AWESOME
//***************************************************************************************
library.add(faInstagram, faYoutube, faVimeo, faTwitter);
library.add(faHeartRegular);
library.add(
    faHome, faMusic, faHeadphones, faUserCircle, faVideo, faSearch,
    faPlayCircle, faPlay, faPauseCircle, faPause,
    faStepBackward, faStepForward, faFastBackward, faFastForward, faRedoAlt,
    faHeart, faSpinner, faTimes, faEllipsisV, faEllipsisH, faGripHorizontal, faGripVertical, faGripLines, faGripLinesVertical,
    faBars, faChevronLeft, faChevronRight, faChevronUp, faChevronDown,
    faTerminal, faRedo, faTrash, faListOl, faEdit
);
//***************************************************************************************
// CONFIGURING Amplify
//***************************************************************************************
Amplify.configure(aws);
//***************************************************************************************
// ENABLING REDUX STORE & AUTHENTICATION
//***************************************************************************************
const store = configureStore();
store.dispatch(AuthActions.verifyToken());

//***************************************************************************************

function App() {
    return (
        <ErrorBoundary>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <Switch>
                        {/*<Route path="*" component={DefaultLayout(Maintenance)}/>*/}
                        <Route exact path="/">
                            <Redirect to="/login"/>
                        </Route>
                        /**************************************************/
                        // Public landing pages
                        <Route path="/home" component={RequireAuth(PublicLayout(Home))}/>
                        <Route path="/artists" component={RequireAuth(PublicLayout(Artists))}/>
                        <Route path="/songs" component={RequireAuth(PublicLayout(Songs))}/>
                        <Route path="/videos" component={RequireAuth(PublicLayout(Videos))}/>
                        <Route path="/fans" component={RequireAuth(PublicLayout(Fans))}/>
                        /**************************************************/
                        // Public profile
                        <Route path="/artist/:id" component={RequireAuth(PublicLayout(Artist))}/>
                        /**************************************************/
                        // AUTH
                        <Route path="/login" component={DefaultLayout(Login)}/>
                        <Route path="/register" component={DefaultLayout(Register)}/>
                        <Route path="/resend-validation-code" component={DefaultLayout(ResendValidationCode)}/>
                        <Route path="/forgot-password" component={DefaultLayout(ForgotPassword)}/>
                        <Route path="/logout" component={DefaultLayout(Logout)}/>
                        /**************************************************/
                        // ACCOUNT
                        <Route path="/reset-password" component={RequireAuth(FullWidthLayout(ResetPassword))}/>
                        <Route path="/settings/subscriptions" component={RequireAuth(FullWidthLayout(Subscriptions))}/>

                        /**************************************************/
                        // COMMUNITY - B2C
                        <Route path="/dashboard" component={RequireAuth(PrivateLayout(Dashboard))}/>

                        <Route path="/profile" component={RequireAuth(PrivateLayout(MyProfile))}>
                            <Route path="/songs" component={RequireAuth(PrivateLayout(MySongs))}/>
                        </Route>
                        /**************************************************/
                        // DEMO - B2B
                        <Route path="/discover" component={RequireAuth(FullWidthLayout(Discover))}/>
                        <Route path="/recommendation" component={RequireAuth(FullWidthLayout(Recommendation))}/>

                        /**************************************************/
                        <Route path="*" component={DefaultLayout(NotFoundPage)}/>
                    </Switch>
                </ConnectedRouter>
                <PlayerBottom/>
            </Provider>
        </ErrorBoundary>
    );
}

export default App;
