import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link, NavLink} from 'react-router-dom';

class LoginHeader extends Component {
    render() {

        let {isAuthenticated} = this.props;

        return (
            <section className="header fixed-top">
                <header className="main-header">
                    <nav className="navbar navbar-expand-lg m-0 p-0 ">
                        <div className="col-lg-1 d-none d-lg-block">&nbsp;</div>
                        <div className="col-lg-10 col-12">
                            <div className="row">
                                <div className="col-sm-6 col-md-4 col-8 m-0 p-0">
                                    <Link className="navbar-brand" to="login">
                                        <img src="/img/logo/logo-hitlab-dna.svg" alt="Hitlab Logo" className="w-100"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 d-none d-lg-block">&nbsp;</div>
                    </nav>
                </header>
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {isAuthenticated: state.auth.isAuthenticated};
    }
)(LoginHeader);
