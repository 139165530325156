//libraries
import _ from 'lodash';

// Import constants
import * as ActionType from 'redux/store/actions/ActionType';

const paginationTemplate = {
    page: 1,
    pageSize: 16,
    count: 0,
    current_page: 1,
    links: {previous: '', next: ''},
    per_page: 16,
    total: 0,
    total_pages: 84
};

let initialState = null;

/**
 * A reducer takes two arguments, the current state and an action.
 */
export default function (state = initialState, action) {
    let newState;
    switch (action.type) {
        /*case ActionType.LOAD_LIST:
           //console.group('PaginationReducer :: ' + action.type + ' => EXECUTING');
            newState = _.assign({}, paginationTemplate, {
                //-----------------------------------------------------------
                // ALIASES
                page: parseInt(action.pagination.current_page),
                pageSize : parseInt(action.pagination.current_page),
                //-----------------------------------------------------------
                current_page: parseInt(action.pagination.current_page),
                per_page: parseInt(action.pagination.per_page),
                total: parseInt(action.pagination.total)
            });
           //console.info({action: action, prevState: state, nextState: newState});
           //console.groupEnd();
            return newState;

        case ActionType.LOAD_LIST_FROM_CACHE:
           //console.group('PaginationReducer :: ' + action.type + ' => EXECUTING');
            newState = _.assign({}, paginationTemplate, {
                //-----------------------------------------------------------
                // ALIASES
                page: parseInt(action.pagination.current_page),
                pageSize : parseInt(action.pagination.current_page),
                //-----------------------------------------------------------
                current_page: parseInt(action.pagination.current_page),
                per_page: parseInt(action.pagination.per_page),
                total: parseInt(action.pagination.total)
            });
           //console.info({action: action, prevState: state, nextState: newState});
           //console.groupEnd();
            return newState;*/

        case ActionType.DATA_CLEAR:
        //case '@@router/LOCATION_CHANGE':
            //------------------------------------------------------------
           //console.group('PaginationReducer :: ' + action.type + ' => EXECUTING');
           //console.info("Resetting to initialState");
           //console.groupEnd();
            //------------------------------------------------------------
            return initialState;

        default:
            return state;
    }
}
