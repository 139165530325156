import _ from "lodash";
import React, {Component} from 'react'

import {controls, Media, Player, utils, withMediaProps} from 'react-media-player'
import {connect} from "react-redux";

import {PlayerActions} from "redux/store/actions/Actions";
import configureActions from "redux/store/configureActions";
import PlayPause from "./PlayPause";
import MuteUnmute from "./MuteUnmute";
import PlayerNotReady from "./PlayerNotReady";
import NextSong from "./NextSong";
import PreviousSong from "./PreviousSong";

const {CurrentTime, Progress, SeekBar, Duration, Volume, Fullscreen} = controls
const {keyboardControls} = utils


class Panner {
    constructor({source, audioContext, panningAmount = 0}) {
        this._source = source;
        this._audioContext = audioContext;
        this._initialPanningAmount = panningAmount;

        //console.log('AudioPlayer :: Panner :: Constructor');
    }

    connect() {
        this._splitter = this._audioContext.createChannelSplitter(2);
        this._gainLeft = this._audioContext.createGain();
        this._gainRight = this._audioContext.createGain();
        this._merger = this._audioContext.createChannelMerger(2);
        this._source.connect(
            this._splitter,
            0,
            0
        );
        this._splitter.connect(
            this._gainLeft,
            0
        );
        this._splitter.connect(
            this._gainRight,
            1
        );
        this._gainLeft.connect(
            this._merger,
            0,
            0
        );
        this._gainRight.connect(
            this._merger,
            0,
            1
        );
        return this._merger
    }

    setPosition(amount) {
        this._gainLeft.gain.value = amount <= 0 ? 1 : 1 - amount;
        this._gainRight.gain.value = amount >= 0 ? 1 : 1 + amount;
    }
}

// ***************************************
// GLOBAL FUNCTION
export const globalPlayer = {
    togglePlayPause: null,
    enqueueSong: null,
    nextSong: null,
    previousSong: null,
    isPlaying: null,
    play: null,
    pause: null,
    playPause: null,
    onStop: null
};

// ***************************************


class AudioPlayer extends Component {

    constructor(props) {
        super(props);

        this.media = React.createRef();
        this.player = React.createRef();
        this.playingComponent = null;


        this.onStop = null;
    }

    _connectSource = (source, audioContext) => {
        this.panner = new Panner({source, audioContext});
        return this.panner.connect()
    };


    componentDidMount() {
        //console.log("AudioPlayer :: componentDidMount", this.props, this.state);


        globalPlayer.togglePlayPause = (song) => {
            const {nowPlaying} = this.props.results || {};
            // console.log('globalPlayer.togglePlayPause', nowPlaying, song)


            //Working with the old playingComponent
            if (this.playingComponent) {
                if (nowPlaying && song && nowPlaying.song_link !== song.song_link) {
                    this.playingComponent().onPause();
                    this.props.actions.enqueueSong(song);
                } else if (this.media && this.media.state) {
                    if (this.media.state.isPlaying) {
                        this.playingComponent().onPause();
                        this.media.pause();
                    } else {
                        this.playingComponent().onPlay();
                        this.media.play();
                    }
                } else {
                    this.playingComponent().onPause();
                    this.props.actions.enqueueSong(song);
                }
            } else {
                this.props.actions.enqueueSong(song);
            }

            if (song && song.playingComponent) {
                song.playingComponent().onPause();
                this.playingComponent = song.playingComponent;
            }
        };


        globalPlayer.enqueueSong = (song, onStop) => {
            if (this.onStop) {
                this.onStop();//Executing onStop() in the previous song's component
            }
            this.props.actions.enqueueSong(song);

            if (onStop) {
                this.onStop = onStop;//Setting the new onStop() function
            }
        };

        globalPlayer.nextSong = () => {
            const {nowPlaying} = this.props.results || {};

            if (this.playingComponent) {
                this.playingComponent().onPause();
            }

            if (nowPlaying.playingComponent) {
                let playingComponent = nowPlaying.playingComponent();

                if (playingComponent.onPause) {
                    playingComponent.onPause();
                }
            }

            this.props.actions.enqueueSong(nowPlaying.nextSong);

            if (nowPlaying.nextSong.playingComponent) {
                let playingComponent = nowPlaying.nextSong.playingComponent();

                if (playingComponent.onPlay) {
                    playingComponent.onPlay();
                }
                this.playingComponent = nowPlaying.nextSong.playingComponent;
            }

        }
        
        globalPlayer.previousSong = () => {
            const {nowPlaying} = this.props.results || {};

            if (this.playingComponent) {
                this.playingComponent().onPause();
            }

            if (nowPlaying.playingComponent) {
                let playingComponent = nowPlaying.playingComponent();

                if (playingComponent.onPause) {
                    playingComponent.onPause();
                }
            }

            this.props.actions.enqueueSong(nowPlaying.previousSong);

            if (nowPlaying.previousSong.playingComponent) {
                let playingComponent = nowPlaying.previousSong.playingComponent();

                if (playingComponent.onPlay) {
                    playingComponent.onPlay();
                }
                this.playingComponent = nowPlaying.previousSong.playingComponent;
            }

        }
    }


    _handleOnReady = () => {
        //const {media, _mediaSetters} = this.context
        //const {autoPlay, onReady} = this.props

        this.media.setVolume(0.5)

        //console.log("autoPlay", autoPlay)
        //if (autoPlay) {
        this.media.play()
        //}

        //this._setLoading(false)

        /* if (typeof onReady === 'function') {
             onReady(media)
         }*/
    }

    _handleEnded = () => {
        globalPlayer.nextSong()
    }

    /*_handlePannerChange = ({target}) => {
        console.log('AudioPlayer :: _handlePannerChange');
        const x = +target.value;
        const y = 0;
        const z = 1 - Math.abs(x);
        this.panner.setPosition(x, y, z)
    };*/

    _handlePrevTrack = () => {
        //this.props.onPrevTrack()
    }

    _handleNextTrack = () => {
        //this.props.onNextTrack()
    }

    render() {
        //console.log('AudioPlayer :: Render', {props: this.props, state: this.state});

        const {nowPlaying} = this.props.results || {};

        let audioSrc;
        if (nowPlaying) {
            //The audio player *MUST* receive a source file with en extension
            audioSrc = (/\.\w{3,5}$/.test(nowPlaying.song_link)) ? nowPlaying.song_link : nowPlaying.song_link + '.mp3';

        }
        //console.log('AudioPlayer :: Render :: audioSrc', audioSrc, nowPlaying);

        if (nowPlaying) {
            return (
                <Media ref={c => (this.media = c)}>
                    <div>
                        <Player
                            ref={c => (this.player = c)}
                            src={audioSrc}
                            connectSource={this._connectSource}
                            crossOrigin='anonymous'
                            autoPlay={true}
                            onReady={this._handleOnReady}
                            onEnded={this._handleEnded}
                        />
                        <div className="media-controls">
                            <table className="table table-borderless">
                                <tbody>
                                <tr>
                                    <td className="p-1" rowSpan={2}>
                                        {nowPlaying &&
                                        <img src={nowPlaying.img_link} alt="Now Playing"/>
                                        }
                                    </td>
                                    <td className="p-1" colSpan={3}>
                                        {nowPlaying &&
                                        <div className="p-0 m-0 h6" style={{
                                            'color': '#666',
                                            'height': '35px',
                                            'whiteSpace': 'nowrap',
                                            'textOverflow': 'ellipsis',
                                            'overflow': 'hidden',
                                            'width': '70vw'
                                        }}>{nowPlaying.artist} - {nowPlaying.title}</div>
                                        }
                                    </td>
                                </tr>
                                <tr>

                                    <td className="row p-0 m-0">
                                        <div className="col-4 col-sm-4 col-md-2">
                                            <span className="pr-4"><PreviousSong className="media-control media-control--previous-track"/></span>
                                            <span className="pr-4"><PlayPause className="media-control media-control--play-pause"/></span>
                                            <span><NextSong className="media-control media-control--next-track"/></span>
                                        </div>
                                        <div className="col-4 col-sm-4 col-md-4">
                                            <small><CurrentTime className="media-control media-control--current-time"/></small>
                                            &nbsp;<SeekBar className="media-control media-control--volume-range"/>&nbsp;
                                            <small><Duration className="media-control media-control--duration"/></small>
                                        </div>
                                        <div className="d-none d-sm-block col-sm-4">
                                            <MuteUnmute className="media-control media-control--mute-unmute"/>
                                            <Volume className="media-control media-control--volume"/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Media>
            );
        } else {
            return (
                <PlayerNotReady/>
            );
        }
    }
}

export default connect(
    (state) => {
        let results;

        if (state.data &&
            state.data instanceof Map &&
            state.data.has('player')) {
            let player = state.data.get('player');
            results = {results: player};
        } else {
            results = {results: null};
        }
        return results;
    },
    (dispatch) => {
        let actions = configureActions(dispatch, _.assign({}, PlayerActions));
        return {
            actions: actions
        }
    }
)(withMediaProps(AudioPlayer));


