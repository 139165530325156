import RecommendationSongListCard from "common/pureComponents/cards/songlist/RecommendationSongListCard";
import Loading from "common/components/loaders/Loading";
import DemoNavLinks from "components/routes/private/demo/common/DemoNavLinks";
import _ from "lodash";
import moment from "moment";
import React, {Component} from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import * as DnDActions from "redux/store/actions/actions/DnDActions";
import * as RequestActions from "redux/store/actions/actions/RequestActions";
import configureActions from "redux/store/configureActions";
import AxiosService from "services/AxiosService";
import {RecommendationUploader} from "components/routes/private/demo/common/RecommendationUploader";
import RecommendationModal from './RecommendationModal';

class Recommendation extends Component {

    constructor(props) {
        super(props);

        this.axios = new AxiosService();
        this.state = {
            user: null,
            recommendationModal: {
                show: false,
                song: null
            }
        };
        this.timer = null;

        this._onUploadSuccess = this._onUploadSuccess.bind(this);
        this._onShowRecommendations = this._onShowRecommendations.bind(this);
        this._onHideRecommendations = this._onHideRecommendations.bind(this);
        this._onDelete = this._onDelete.bind(this);
    }

    componentDidMount() {
        //console.log('Recommendation :: componentDidMount', this);
        this._onLoad();
    }

    componentWillUnmount() {
        this.axios.cancel();
        clearTimeout(this.timer);
    }

    _onLoad() {
        //console.log('Recommendation :: _onLoad', this);
        this.props.actions.load('user/recommendation', {
            pageSize: 16,
            page: 1
        });
    }

    _startPolling() {
        if (this.timer) {
            clearTimeout(this.timer);
        } else {
            this._onLoad(); //Initial fetch
        }

        this.timer = setInterval(() => this._onLoad(), 10000)
    }

    _onDelete(song) {
        //console.log('Recommendation :: _onDelete', this, song);

        try {
            this.axios.delete('recommendation/' + song.id)
                .then((response) => {
                    if (response && response.data) {
                        this._onLoad();
                    }
                })
        } catch (err) {
            //console.log('Discover :: _onLoad :: Error : ', err.message);
        }
    }


    //*********************************************************************************************************************
    // Events
    _onUploadSuccess(data) {
        //console.log('onUploadSuccess', this, data);

        /*
        //TODO: Bind DnDAction to redux store
        this.setState({
            draggedSong: this.state.songs.unshift(data.song)
        });*/


        //Start polling for feature extraction
        this._startPolling();
    }

    //*********************************************************************************************************************
    // RecommendationModal
    _onShowRecommendations(song) {
        this.setState({
            recommendationModal: {
                show: true,
                song: song
            }
        });
    }

    _onHideRecommendations() {
        this.setState({
            recommendationModal: {
                show: false,
                song: null
            }
        });
    }

    render() {
        //console.log('Recommendation :: Render :: ', this.props);

        const {isProcessing, result} = this.props;
        const {user} = (result && result.user ? result : {});

        //***************************************************************
        // Special code to handle automatic polling
        // TODO: Make this a bit cleaner and in sync with any other polling
        if (isProcessing) {
            user.recommendations.map((song) => {
                if (!song.features_ended_at && moment().diff(song.features_started_at, 'minutes') < 2) {
                    this._startPolling()
                }
            });
        } else {
            clearInterval(this.timer)
        }
        //***************************************************************


        if (user) {
            return (
                <section className="dna-recommendation">
                    <DemoNavLinks/>
                    <Row className="my-3">
                        <Col xs={12} md={12} lg={12}>
                            <Row className="my-3">
                                <Col md={12}>
                                    <section className="songlist">
                                        <div className="row justify-content-md-center mb-3">
                                            <h5>Get recommendations based on your uploaded track</h5>
                                        </div>
                                        <div className="row justify-content-md-center m-0">
                                            <p className="text">The pool of songs could be any playlist.</p>
                                        </div>
                                        <div className="row justify-content-md-center mb-3">
                                            <div className="col-md-8" style={{
                                                'backgroundColor': 'white',
                                                'border': '2px dashed #aaa',
                                                'borderRadius': '20px'
                                            }}>
                                                <RecommendationUploader minSize={256} maxSize={20971520}
                                                                        onUploadSuccess={this._onUploadSuccess}/>
                                            </div>
                                        </div>
                                        <div className="row justify-content-md-center">
                                            <div className="col-md-8">
                                                <DragDropContext
                                                    onBeforeDragStart={this.onBeforeDragStart}
                                                    onDragStart={this.onDragStart}
                                                    onDragUpdate={this.onDragUpdate}
                                                    onDragEnd={this.onDragEnd}>

                                                    <Droppable droppableId="droppable-1" type="RECOMMENDATION">
                                                        {(provided, snapshot) => (
                                                            <div ref={provided.innerRef}>
                                                                {user.recommendations && user.recommendations.map((song, index) => (
                                                                    <Draggable draggableId={song.id} key={song.id}
                                                                               index={index}>
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <RecommendationSongListCard song={song}
                                                                                                            onShowRecommendations={() => this._onShowRecommendations(song)}
                                                                                                            onDelete={() => this._onDelete(song)}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </div>
                                        </div>
                                        <RecommendationModal show={this.state.recommendationModal.show}
                                                             song={this.state.recommendationModal.song}
                                                             onHide={this._onHideRecommendations}/>
                                    </section>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>
            );
        } else {
            return <Loading/>;
        }
    }
}


export default connect(
    (state) => {
        //console.group('MapState :: Discover :: ', state);
        let results;

        if (state.data &&
            state.data instanceof Map &&
            state.data.has('user/recommendation')) {
            let recommendationDashboard = state.data.get('user/recommendation');

            let isProcessing = recommendationDashboard.user.recommendations.filter((element, index) => {
                if (!element.is_features_valid) {
                    return true;//return true as soon as there is one song still in process
                }
                return false;
            }).length > 0;

            //console.info('MapState :: Discover :: user/dashboard :: ', discoverDashboard);
            results = {result: recommendationDashboard, isProcessing: isProcessing};
        } else {
            results = {result: null, songs: null};
        }
        //console.groupEnd();
        return results;
    },
    (dispatch) => {
        let actions = configureActions(dispatch, _.assign({}, RequestActions, DnDActions));
        return {
            actions: actions
        }
    }
)(Recommendation);

