import qs from "qs";

export const getQueryParam = (param, defaultVal = null) => {
    const queryParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });

    let queryValue =
        queryParams && param in queryParams ? queryParams[param] : defaultVal;

    //console.log(`queryValue: ${queryValue}`);
    return queryValue;
};
