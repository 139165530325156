import React, {Component} from 'react';


import {Link} from "react-router-dom";
import {Row, Col, Image, Card, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import SongList from 'common/pureComponents/songlist/SongList';

class ArtistProfile extends Component {

    _getCityCountry() {
        const {artist} = this.props;///

        let city = artist.city ? artist.city : '';
        let country = (artist.country && artist.country.name) ? artist.country.name : '';

        if (city !== '' && country !== '') {
            return city + ', ' + country;
        } else if (city !== '' && country === '') {
            return city;
        } else if (country !== '') {
            return country;
        } else {
            return 'Unknown provenance';
        }
    }

    render() {
        const {artist, onRefresh} = this.props;
        //console.log("Render :: ArtistProfile ::", artist);

        return (
            <section className="artist-bio">
                <Row className="">
                    <div className="col-lg-3">
                        <div className="h3 my-3">{artist.username}</div>
                    </div>
                </Row>
                <Row className="my-3">
                    <Col xs={12} md={12} lg={8}>
                        <Row className="">
                            <Col xs={12} md={6} lg={4} >
                                <div>
                                    <Image src={artist.img_link} alt={artist.username} rounded className="" className="w-100"/>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={8}>
                                <Row className="">
                                    <Col xs={4} className="text-left">
                                        {this._getCityCountry()}
                                    </Col>
                                    <Col xs={2} style={{'fontSize': '20pt'}}>
                                        <FontAwesomeIcon icon={['far', 'heart']}/>
                                        {/*<FontAwesomeIcon icon={['fas', 'heart']}/>*/}
                                    </Col>
                                    <Col xs={6} className="text-right " style={{'fontSize': '20pt'}}>
                                        <Link to={''} target="_blank" className="px-2 ">
                                            <FontAwesomeIcon icon={['fab', 'instagram']}/>
                                        </Link>
                                        <Link to={''} target="_blank" className="px-2 ">
                                            <FontAwesomeIcon icon={['fab', 'youtube']}/>
                                        </Link>
                                        <Link to={''} target="_blank" className="px-2 ">
                                            <FontAwesomeIcon icon={['fab', 'vimeo']}/>
                                        </Link>
                                        <Link to={''} target="_blank" className="px-2 ">
                                            <FontAwesomeIcon icon={['fab', 'twitter']}/>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col>
                                        <p>{artist.bio}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col>
                                 <SongList results={artist.songs}/>
                            </Col>
                        </Row>
                    </Col>

                    {/*<Col xs={12} md={6} lg={4}>
                        <Row className="">
                            <Col>
                                <Card className="" style={{'height':'500px'}}>
                                    <div className="row">
                                        <div className="col-md p-4 text-center">Instagram plugin</div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>*/}
                </Row>
            </section>
        );
    }
}

export default ArtistProfile;
