import Loading from "common/components/loaders/Loading";
import React, {Component} from 'react';
import PropTypes from 'prop-types'; // ES6
import {connect} from 'react-redux';
import _ from "lodash";
//**********************************************************
import configureActions from 'redux/store/configureActions';
import {RequestActions} from "redux/store/actions/Actions";
//**********************************************************
import FiltersArtists from "components/routes/public/artists/sections/FiltersArtists";
import FilteredArtists from "components/routes/public/artists/sections/FilteredArtists";
import LoadMore from "common/components/loaders/LoadMore";

class Artists extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clearData: false
        };

        this.formRef = React.createRef();
        this._onSubmit = this._onSubmit.bind(this);
        this._onChange = this._onChange.bind(this);
        this._onLoadMore = this._onLoadMore.bind(this);
    }

    /*static defaultProps = {
        results: [],
        total: 0,
        page: 0,
        pageSize: 16,
    };
    static propTypes = {
        results: PropTypes.array.isRequired,
        page: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        total : PropTypes.number.isRequired
    };*/

    componentDidMount() {
       //console.log('Artists :: componentDidMount', this);
        this._onLoad();
    }

    componentWillUnmount() {
       //console.log('Artists :: componentWillUnmount', this);

        if (this.props.request) {
            this.props.request.cancel();
        }
        //this.axios.cancel();
    }

    _getFormData() {
        const formData = [...this.formRef.current.elements].reduce(function (accumulator, elem) {
            let key = elem.name || elem.id;
            if (key) { //Must have a key
                accumulator[key] = elem.value;
            }
            return accumulator;
        }, {});
       //console.log('Artists :: _getFormData', formData);

        return formData;
    }

    _onLoad() {
       //console.log('Artists :: _onLoad', this);
        const formData = this._getFormData();

        this.props.actions.fetch('artists', {
            filterByGenre: formData.filterByGenre,
            sortBy: formData.sortBy,
            pageSize: 16,
            page: 1
        });
    }

    _onLoadMore() {
       //console.log('Artists :: _onLoadMore', this);
        const formData = this._getFormData();


        const {pagination, results} = this.props.results.artists;
        this.props.actions.fetchMore('artists', {
            filterByGenre: formData.filterByGenre,
            sortBy: formData.sortBy,
            pageSize: pagination ? pagination.per_page : 4,
            page: pagination ? (pagination.current_page + 1) : 1
        });
    }

    _onSubmit(event) {
       //console.log('Artists :: _onSubmit', event, this);
        event.preventDefault();

        const formData = this._getFormData();

        this.props.actions.fetch('artists', {
            filterByGenre: formData.filterByGenre,
            sortBy: formData.sortBy,
            pageSize: this.props.pagination ? this.props.pagination.per_page : 16,
            page: this.props.pagination ? this.props.pagination.current_page + 1 : 1
        });
    }

    _onChange(event) {
       //console.log('Artists :: _onChange', event, this);

        this.setState({clearData: true});
        this._onLoad();
    }

    render() {
       //console.log('Artists :: Render', this.state, this.props);
        const {results, pagination} = /*!this.state.clearData &&*/ this.props.results && this.props.results.artists || {};
        if (this.state.clearData) {
            this.state.clearData = false;
        }

        return (
            <>
                <FiltersArtists onSubmit={this._onSubmit} onChange={this._onChange} formRef={this.formRef}/>
                <FilteredArtists results={results} pagination={pagination}/>
                {results && <LoadMore onClick={this._onLoadMore}/>}
            </>
        );
    }
}

export default connect(
    (state) => {
        //console.group('MapState :: Artists :: ', state);

        let results;
        if (state.data && state.data instanceof Map) {
            if (state.data && state.data instanceof Map && state.data.has('artists')) {
                let artists = state.data.get('artists');
                //console.info(artists);
                results = {results: artists};
            } else {
                results = {results: null};
            }
        } else {
            results = {results: null};
        }

        //console.groupEnd();
        return results;
    },
    (dispatch) => {
        let actions = configureActions(dispatch, _.assign({}, RequestActions));
       //console.log('Artists :: mapDispatch', actions);
        return {
            actions: actions
        }
    }
)(Artists);
