const debugLogger = store => next => action => {
   //console.group('DEBUGGER :: ACTION :: ', action);
    //console.log('prev state', store.getState());
    //console.log('action', action);
    let result = next(action);
    //console.log('next state', store.getState());
   //console.groupEnd();
    return result
};

export default debugLogger;
