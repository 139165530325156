import ENV from "../configs/Env";
import { loadStripe } from "@stripe/stripe-js";

const urlBase = window.location.protocol + "//" + window.location.host + window.location.pathname;

export const purchaseSubscription = async function (email, clientReferenceId, subscriptionType = "monthly") {
    try {
        const price = subscriptionType === "monthly" ? ENV.PAYMENT.PRODUCTS.MONTHLY : ENV.PAYMENT.PRODUCTS.YEARLY;

        const stripe = await loadStripe(ENV.PAYMENT.STRIPE_KEY);

        const { error } = await stripe.redirectToCheckout({
            lineItems: [{ price: price, quantity: 1 }],
            customerEmail: email,
            clientReferenceId: clientReferenceId,
            mode: "subscription",
            successUrl: urlBase + "#success",
            cancelUrl: urlBase + "#cancel",
        });

        if (error) {
            console.error(error);
        }
    } catch (error) {
        console.error("Monthly subscription", error);
    }
};
