import {ActionType, ExceptionActions} from 'redux/store/actions/Actions';
//**********************************************************************************

export function clearData() {
   //console.log('DataActions :: clearData');

    return (dispatch) => {
        return dispatch({
            type: ActionType.DATA_CLEAR
        });
    }
}

