import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormMessage from "common/func/form/FormMessage";
import SubmitButton from "common/func/form/SubmitButton";
import BackToLogin from "common/func/navlinks/BackToLogin";
import ClickLink from "common/func/navlinks/ClickLink";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap'
import {Field, reduxForm} from 'redux-form';
import {Auth} from 'aws-amplify';
import FormInput from 'common/func/form/FormInput';
import {history} from "redux/store/configureStore";


class ForgotPassword extends Component {

    static propTypes = {
        handleSubmit: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            message: null,
            isCodeSent: false
        }
        this._onRetry = this._onRetry.bind(this);
        this.clearMessage = this.clearMessage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    clearMessage() {
        this.setState({
            message: null
        })
    }

    _onRetry() {
        this.setState({
            message: null,
            isCodeSent: false
        })
    }


    async handleSubmit(formProps) {
        try {
            if (!this.state.isCodeSent) {
                Auth.forgotPassword(formProps.username)
                    .then(data => {
                        this.setState({
                            message: "Please check your email for your validation code.",
                            isCodeSent: true
                        })
                    })
                    .catch(err => {
                        //console.log(err)
                        this.setState({
                            message: err.message
                        })
                    });
            } else {
                Auth.forgotPasswordSubmit(formProps.username, formProps.code, formProps.new_password)
                    .then(data => {
                        this.setState({
                            message: "Password changed succesfully! You'll be redirected to login in 5 seconds.",
                            isCodeSent: true
                        })
                        //console.log("ResetPassword :: handleSubmit :: Auth.forgotPasswordSubmit", data)
                        history.push('/login');
                    })
                    .catch(err => {
                        //console.log(err)
                        this.setState({
                            message: err.message
                        })
                    });
            }
        } catch (error) {
            //console.log('error', error);
            this.setState({
                message: error.message
            })
        }

    }

    render() {
        //console.log('ForgotPassword :: Render', this.props);
        const {handleSubmit, submitting} = this.props;
        const {message} = this.state;

        return (
            <section className="login py-5">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-4">
                        <h2 className="">Forgot Password</h2>
                        <form method="post" autoComplete="off" onClick={this.clearMessage} onSubmit={handleSubmit(this.handleSubmit)}>

                            {!this.state.isCodeSent &&
                            <div className="">
                                <p className="">Reset your password by entering your email</p>
                                <div className="">
                                    <Field
                                        name="username"
                                        component={FormInput}
                                        type="text"
                                        label="Email"
                                        autoComplete="off"
                                    >
                                    </Field>
                                </div>
                            </div>
                            }

                            {this.state.isCodeSent &&
                            <div className="">
                                <p className="">Please check your email for your reset password code</p>
                                <input type="hidden" name="email" value={this.state.email}/>
                                <Field
                                    name="code"
                                    component={FormInput}
                                    type="text"
                                    label="Code"
                                    autoComplete="off"
                                    value=""
                                >
                                </Field>
                                <Field
                                    name="new_password"
                                    component={FormInput}
                                    type="password"
                                    label="New Password"
                                    autoComplete="new-password"
                                    value=""
                                >
                                </Field>
                            </div>
                            }

                            <FormMessage message={message}/>

                            <div className="">
                                <div className="float-left">
                                    <BackToLogin/>
                                </div>
                                <div className="float-right">
                                    {this.state.isCodeSent &&
                                        <ClickLink title='Retry' faIcon={{iconPos: 'right', icon: 'redo'}} onClick={this._onRetry}/>
                                    }

                                    <SubmitButton submitting={submitting} title='Submit'/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        )
    }
}


/**
 * Connect the component to the Redux store.
 */
export default reduxForm({
    form: 'ForgotPassword', // ←A Unique identifier for this form
    validate: values => {
        const errors = {};

        if (!values.email) {
            errors.email = '(The email field is required.)'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address.'
        }

        return errors
    }  // ←Callback function for client-side validation
})(ForgotPassword);
