import cookie from 'react-cookie';
import { history } from 'redux/store/configureStore';

class StorageService {
    static setToken = token => cookie.save('token', token, {path: '/'})
    static getToken = () => cookie.load('token')
    static clearToken = () => cookie.remove('token', {path: '/'})

    static setUser = user => localStorage.setItem('user', JSON.stringify(user))
    static getUser = () => this.getCognitoUser() || this.getHitlabUser()
    static clearUser = () => localStorage.removeItem('user')

    static getUserDisplayedName = () => {
        let user = this.getUser();

        if(user.hasOwnProperty('username') && user.username){
            return user.username;
        }else if(user.hasOwnProperty('first') && user.hasOwnProperty('last') && user.first && user.last){
            return user.first + ' ' + user.last;
        }else if(user.hasOwnProperty('firstName') && user.hasOwnProperty('lastName') && user.firstName && user.lastName){
            return user.firstName + ' ' + user.lastName;
        }else if(user.hasOwnProperty('email') && user.email){
            return user.email;
        }else{
            return "ACCOUNT";
        }
    }


    /**
     * fixme : we need to encapsulates the usage of the hitlabUser and store only what's necessary for the app to run
     * @param user
     */
    static setHitlabUser = hitlabUser => {this.clearHitlabUser(); localStorage.setItem('hitlabUser', JSON.stringify(hitlabUser))}
    static getHitlabUser = () => JSON.parse(localStorage.getItem('hitlabUser'));
    static clearHitlabUser = () => localStorage.removeItem('hitlabUser');

    /**
     * fixme : we need to encapsulates the usage of the cognitoUser and store only what's necessary for the app to run
     * @param user
     */
    static setCognitoUser = cognitoUser => {this.clearCognitoUser(); localStorage.setItem('cognitoUser', JSON.stringify(cognitoUser))}
    static getCognitoUser = () => JSON.parse(localStorage.getItem('cognitoUser'));
    static clearCognitoUser = () => localStorage.removeItem('cognitoUser');


}
export default StorageService;
