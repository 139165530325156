//libraries
import _ from 'lodash';

/**
 * Import actionType.
 */
import * as ActionType from 'redux/store/actions/ActionType';


const messageTemplate = {
    type: null,
    text: null,
};

let initialState = {
    messages: new Set()
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
export default function (state= initialState, action) {
    let newState;
    switch (action.type) {
        case ActionType.FLASH_ADD_MESSAGE:
           //console.group('FlashReducer :: ' + action.type + ' => EXECUTING');
            let newMessage = _.cloneDeep(messageTemplate);
            newMessage.type = action.message.type;
            newMessage.text = action.message.text;

            newState = _.cloneDeep(state);
            newState.messages.add(newMessage);

           //console.info({action: action, prevState: state, nextState: newState});
           //console.groupEnd();
            return newState;


        case ActionType.FLASH_CLEAR_MESSAGES:
           //console.group('FlashReducer :: ' + action.type + ' => EXECUTING');
           //console.info("Resetting to initialState");
           //console.groupEnd();
            return initialState;

        default:
            return state;
    }
}
