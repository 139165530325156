import React, {Component} from 'react';

import {Link} from "react-router-dom";

class NavBreadcrumb extends Component {

    render() {
        const {user, artist, song, fan} = this.props;
        let url, section, slug = null;

        if (user) {
            url = '/artists';
            section = 'Artists';
            slug = user.slug;
        } else if (artist) {
            url = '/artists';
            section = 'Artists';
            slug = artist.slug;
        } else {
            url = '#';
            section = 'unknown';
            slug = 'slug';
        }

        return (
            <section className="breadcrumb">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb my-3">
                        <li className="breadcrumb-item">
                            <Link className="link" to={url}>
                                {section}
                            </Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{slug}</li>
                    </ol>
                </nav>
            </section>
        );
    }
}

export default NavBreadcrumb;
