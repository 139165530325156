import FormMessage from "common/func/form/FormMessage";
import Back from "common/func/navlinks/Back";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom"; // ES6
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import { RequestActions, ExceptionActions } from "redux/store/actions/Actions";
import configureActions from "redux/store/configureActions";
import { Auth } from "aws-amplify";
import FormInput from "common/func/form/FormInput";
import { history } from "redux/store/configureStore";

class ResetPassword extends Component {
    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            message: null,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(formProps) {
        const { isAuthenticated } = this.props;
        try {
            Auth.currentAuthenticatedUser()
                .then((user) => {
                    return Auth.changePassword(user, formProps.old_password, formProps.new_password);
                })
                .then((data) => {
                    //console.log("ResetPassword :: handleSubmit :: Auth.currentAuthenticatedUser", data)
                    this.setState({
                        message: isAuthenticated
                            ? "Password changed successfully."
                            : "Password changed successfully. You'll be redirected to login in 5 seconds.",
                    });
                })
                .catch((err) => {
                    let message;
                    if (typeof err === "object" && err.hasOwnProperty("code") && err.hasOwnProperty("message")) {
                        switch (err.code) {
                            case "InvalidParameterException":
                                message = "Old password incorrect";
                                break;
                            case "InvalidPasswordException":
                                message = "New password is not long enough (must be at least 6 characters with Uppercase, lowercase and special characters)";
                                break;
                            default:
                                message = err.message;
                        }
                    } else {
                        //Fallback when Cognito returns 'not authenticated' when user is not logged to Cognito
                        history.push("/login");
                    }

                    this.setState({
                        message: err.message,
                    });
                });
        } catch (error) {
            //console.log('error', error);
            this.setState({
                message: error.message,
            });
        }
    }

    render() {
        //console.log('ResetPassword :: Render', this.props);
        const { handleSubmit, submitting } = this.props;
        const { message } = this.state;

        return (
            <section className="login py-5">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-4">
                        <h2 className="">Reset Password</h2>
                        <p className="">Please enter your old and new passwords</p>

                        <form method="post" onSubmit={handleSubmit(this.handleSubmit)}>
                            <Field name="old_password" component={FormInput} type="password" label="Old Password">
                                <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                            </Field>

                            <Field name="new_password" component={FormInput} type="password" label="New Password">
                                <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                            </Field>

                            {message && <FormMessage message={message} />}

                            <div className="col-xs-4 float-left">
                                <Back />
                            </div>
                            <div className="col-xs-4 float-right">
                                <Button type="submit" className="btn btn-primary mx-1" disabled={submitting}>
                                    Reset
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        );
    }
}

/**
 * Connect the component to the Redux store.
 */
export default connect(
    (state) => {
        return {
            token: state.auth && state.auth.token,
            isAuthenticated: state.auth && state.auth.isAuthenticated,
            message: "", //state.flash.message
        };
    },
    (dispatch) => {
        let actions = configureActions(dispatch, _.assign({}, RequestActions));
        return {
            actions: actions,
        };
    }
)(
    reduxForm({
        form: "ResetPassword", // ←A Unique identifier for this form
        validate: (values) => {
            const errors = {};
            if (!values.email) {
                errors.email = "(The email field is required.)";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = "Invalid email address.";
            }

            if (!values.code) {
                errors.code = "(The code field is required.)";
            }

            if (!values.new_password) {
                errors.new_password = "(The new password field is required.)";
            }

            return errors;
        }, // ←Callback function for client-side validation
    })(ResetPassword)
);
