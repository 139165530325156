import React from "react";
import PropTypes from 'prop-types';

const FormMessage = ({message, type}) => {
    if(message && message.length > 0){
        return (
            <div className="alert alert-primary" role="alert">
                {message}
            </div>
        )
    }else{
        return <></>
    }
};

FormMessage.propTypes = {
    message: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'secondary','success','danger','warning','info','light','dark']).isRequired,
};

FormMessage.defaultProps = {
    type: 'primary'
};

export default FormMessage;
