import React from 'react';
import {Col, Row} from "react-bootstrap";
import {NavLink, useLocation} from 'react-router-dom'


const DemoNavLinks = () => {
    let location = useLocation();
    //console.log("location.pathname", location );

    let isDiscover = location.pathname === '/discover'
    let isRecommendation = location.pathname === '/recommendation'

    return <Row>
        <Col>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <NavLink to={'/discover'} activeClassName={isDiscover ? 'active' : ''} className="nav-link">Dashboard</NavLink>
                </li>
            </ul>
        </Col>
    </Row>
};

export default DemoNavLinks;
