import React, { Component } from 'react';

// Import custom components


class Fans extends Component {
    render(){
        //console.log('Fans :: Render', this.state);
        return (
            <div>
                <h1>Fans</h1>
                <h2>À venir...</h2>
            </div>
        );
    }
}

export default Fans
