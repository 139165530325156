import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {globalPlayer} from "common/components/player/media-player/AudioPlayer";
import React from 'react';

import {Card, Button} from 'react-bootstrap';
import {Animated} from "react-animated-css";
import Content from "common/components/content/content.component";
import {NavLink} from "react-router-dom";

class ArtistCard extends React.PureComponent {
    constructor(props) {
        super(props);
        ////console.log('construct', props);
    }

    handlePlaySong = () => {
        globalPlayer.enqueueSong(this.props.artist.bestSong)
    };

    render() {
        const {artist} = this.props;
        //console.log(artist);

        return (
            <Card className="artist-card my-2">
                <div className="song-card-img text-center">
                    <div className="card-img">
                        <Card.Img variant="primary" src={artist.img_link} alt={artist.username} className="rounded w-100"/>
                    </div>
                </div>
                <Card.Body>
                    <div className="card-title">
                        <div className="row">
                            <NavLink to={'/artist/' + artist.id} activeClassName="active" className="col-md text-center link">
                                {artist.username}
                            </NavLink>
                        </div>
                    </div>
                    <div className="row py-1">
                        {!this.props.artist.bestSong &&
                        <div className="col-md text-left">&nbsp;</div>
                        }
                        {this.props.artist.bestSong &&
                        <div className="col-md text-center">
                            <div className="col-md text-center link" onClick={this.handlePlaySong}>
                                <FontAwesomeIcon icon="play-circle" size="sm"/> {artist.bestSong.title}
                                {artist.bestSong.score &&
                                <span className="pl-3">{artist.bestSong.score} % </span>
                                }
                            </div>
                        </div>
                        }
                    </div>
                    <div className="row py-1">
                        {artist.totalViewed &&
                        <div className="col-md text-left">{parseInt(artist.totalViewed)} Views</div>
                        }
                        {artist.totalPlayed &&
                        <div className="col-md text-right">{parseInt(artist.totalPlayed)} plays</div>
                        }
                    </div>
                    <div className="row py-1">
                        <div className="col-md text-right">&nbsp;{artist.songsAllGenres && <small><b><i>{artist.songsAllGenres}</i></b></small>}</div>
                    </div>
                    <div className="row py-1">
                        <div className="col-md text-right"><small><i>&nbsp;{artist.origin}</i></small></div>
                    </div>
                </Card.Body>
            </Card>

        )
    }
}

export default ArtistCard
