import { ActionType, ApiActions, ExceptionActions } from "redux/store/actions/Actions";
//**********************************************************************************
import AxiosService from "services/AxiosService";
import _ from "lodash";

//******************************************************************************************************
// Thunks
//******************************************************************************************************
export function load(apiPath, params) {
    return (dispatch, getState) => {
        //--------------------------------------------------------
        //Check in cache, then call API
        let cacheKey = apiPath + (params ? "_" + JSON.stringify(params) : "");
        let cacheValue = getState().cache.lists.get(cacheKey);
        //--------------------------------------------------------
        //console.group('RequestActions :: fetching ::', (cacheValue ? 'FROM CACHE' : 'FROM SERVER'));
        //console.info({apiPath: apiPath, params: params, prevState: getState(), cacheKey: cacheKey, cacheValue: cacheValue});
        //console.groupEnd();

        if (cacheValue) {
            dispatch({
                type: ActionType.LOAD_ENTITY_FROM_CACHE,
                payload: cacheValue,
            });
        } else {
            const axiosService = new AxiosService();
            dispatch(ApiActions.apiRequest(axiosService, "RequestActions :: load :: " + apiPath)); //start-request

            axiosService
                .fetch(apiPath, params)
                .then((response) => {
                    dispatch(ApiActions.apiResponse(response)); //end-request

                    //console.log('RequestActions :: load :: ', response);
                    if (response && response.data) {
                        let payload = _.assign(
                            {},
                            {
                                apiPath: apiPath,
                                params: params,
                            },
                            response.data
                        );

                        //***********************************************************
                        dispatch({
                            type: ActionType.LOAD_ENTITY,
                            payload: payload,
                        });
                        //***********************************************************
                        dispatch({
                            type: ActionType.CACHE_ADD_ENTITY,
                            payload: {
                                key: cacheKey,
                                value: payload,
                            },
                        });
                        //***********************************************************
                    } else {
                        ExceptionActions.errorHandler(dispatch, response, ActionType.EXCEPTION);
                    }
                })
                .catch((error) => {
                    ExceptionActions.errorHandler(dispatch, error.response, ActionType.FAILURE);
                });
        }
    };
}

export function fetch(apiPath, params) {
    return (dispatch, getState) => {
        //--------------------------------------------------------
        //Check in cache, then call API
        let cacheKey = apiPath + "_" + JSON.stringify(params);
        let cacheValue = getState().cache.lists.get(cacheKey);
        //--------------------------------------------------------
        //console.group('RequestActions :: fetching ::', (cacheValue ? 'FROM CACHE' : 'FROM SERVER'));
        //console.info({apiPath: apiPath, params: params, prevState: getState(), cacheKey: cacheKey, cacheValue: cacheValue});
        //console.groupEnd();

        if (cacheValue) {
            dispatch({
                type: ActionType.LOAD_RESULTS_FROM_CACHE,
                payload: cacheValue,
            });
        } else {
            const axiosService = new AxiosService();
            dispatch(ApiActions.apiRequest(axiosService, "RequestActions :: fetch :: " + apiPath)); //start-request

            axiosService
                .fetch(apiPath, params)
                .then((response) => {
                    dispatch(ApiActions.apiResponse(response)); //end-request

                    //console.log('RequestActions :: fetch :: ', response);
                    if (response && response.data) {
                        let payload = _.assign(
                            {},
                            {
                                apiPath: apiPath,
                                params: params,
                            },
                            response.data
                        );

                        //***********************************************************
                        dispatch({
                            type: ActionType.LOAD_RESULTS,
                            payload: payload,
                        });
                        //***********************************************************
                        dispatch({
                            type: ActionType.CACHE_ADD_LIST,
                            payload: {
                                key: cacheKey,
                                value: payload,
                            },
                        });
                        //***********************************************************
                    } else {
                        ExceptionActions.errorHandler(dispatch, response, ActionType.EXCEPTION);
                    }
                })
                .catch((error) => {
                    ExceptionActions.errorHandler(dispatch, error.response, ActionType.FAILURE);
                });
        }
    };
}

export function fetchMore(apiPath, params) {
    return (dispatch, getState) => {
        //--------------------------------------------------------
        //Check in cache, then call API
        let cacheKey = apiPath + "_" + JSON.stringify(params);
        let cacheValue = getState().cache.lists.get(cacheKey);
        //--------------------------------------------------------
        //console.group('HomeActions :: fetching more ::', (cacheValue ? 'FROM CACHE' : 'FROM SERVER'));
        //console.info({apiPath: apiPath, params: params, prevState: getState(), cacheKey: cacheKey, cacheValue: cacheValue});
        //console.groupEnd();

        if (cacheValue) {
            dispatch({
                type: ActionType.LOAD_RESULTS_FROM_CACHE,
                payload: cacheValue,
            });
        } else {
            const axiosService = new AxiosService();
            dispatch(ApiActions.apiRequest(axiosService, "RequestActions :: fetch :: " + apiPath)); //start-request

            axiosService
                .fetch(apiPath, params)
                .then((response) => {
                    dispatch(ApiActions.apiResponse(response)); //end-request

                    //console.log('HomeActions :: fetch :: ', response);
                    if (response && response.data) {
                        let payload = _.assign(
                            {},
                            {
                                apiPath: apiPath,
                                params: params,
                            },
                            response.data
                        );
                        //***********************************************************
                        dispatch({
                            type: ActionType.DATA_APPEND_RESULTS,
                            payload: payload,
                        });
                        //***********************************************************
                        dispatch({
                            type: ActionType.CACHE_ADD_LIST,
                            payload: {
                                key: cacheKey,
                                value: payload,
                            },
                        });
                    } else {
                        ExceptionActions.errorHandler(dispatch, response, ActionType.INVALID_RESPONSE_FORMAT);
                    }
                })
                .catch((error) => {
                    ExceptionActions.errorHandler(dispatch, error.response, ActionType.FAILURE);
                });
        }
    };
}
