import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';

import {Card} from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import {globalPlayer} from "common/components/player/media-player/AudioPlayer";

class SongCard extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            song: null,
            icon: 'play-circle',
            buttons: {
                feature: {
                    disabled: false
                },
                analyze: {
                    disabled: false
                }
            }
        };

        this._onStop = this._onStop.bind(this);
        this._handlePlaySong = this._handlePlaySong.bind(this);
    }

    componentWillUnmount() {
        this._onStop = null
    }

    _onStop() {
        this.setState({'icon': 'play-circle'});
    }

    _handlePlaySong() {
        globalPlayer.enqueueSong(this.props.song, this._onStop);
        this.setState({'icon': 'pause-circle'});
    };


    render() {
        const {song} = this.props;
        //console.log(song);

        return (
            <Card className="song-card my-2">
                <div className="song-card-img text-center">
                    <div className="card-img">
                        <Card.Img variant="primary" src={song.user.img_link} alt={song.user.username} className="rounded w-100"/>
                    </div>
                </div>
                <Card.Body>
                    <div className="card-title">
                        <div className="row">
                            <div className="col-md text-center link" onClick={this.handlePlaySong}>
                                <FontAwesomeIcon icon="play-circle" size="sm"/> {song.title}
                            </div>
                        </div>
                    </div>
                    <div className="row py-1">
                        <NavLink to={'/artist/' + song.user.id} activeClassName="active" className="col-md text-center link">
                            <div className="col-md text-center">{song.user.username}</div>
                        </NavLink>
                    </div>
                    <div className="row py-1">
                        <div className="col-md text-left">Score</div>
                        <div className="col-md text-right">{song.score} %</div>
                    </div>
                    <div className="row py-1">
                        <div className="col-md text-left">{song.played} plays</div>
                        <div className="col-md text-right"><small><b><i>{song.genre}</i></b></small></div>
                    </div>
                </Card.Body>
            </Card>
        )
    }
}

export default SongCard
