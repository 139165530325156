//***************************************************************************************
// This must be the first line in src/index.js
import "babel-polyfill"; //IE11 supports
//import * as serviceWorker from './serviceWorker';
//***************************************************************************************
// GLOBAL CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import "isomorphic-fetch"; // IE11 supports
//***************************************************************************************
import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';


import {render} from 'react-dom';
//***************************************************************************************
import App from './App';
import './scss/app.scss';
//***************************************************************************************

const renderApp = RootComponent => {
    render(
        <App>
            <RootComponent/>
        </App>,
        document.getElementById('root')
    );
};
renderApp(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
