import AxiosService from "./AxiosService";


class CommunityDriver {

    constructor(props) {

        this.axios = new AxiosService();

    }

    analyseSong(song) {
        return this.axios.fetch('song/' + song.id + '/refeature', {})
        .then((response) => {

            //transform data ??

            //console.log('Song :: _onRefeature :: ', response);
            if (!response || !response.data) {
                throw "DataNotFound";
            }

            var transformUser = response.data.user;

            return transformUser;
        })
    }
}

export default CommunityDriver;