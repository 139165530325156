import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import {history} from "redux/store/configureStore";

const Back = () => (
    <div onClick={history.goBack} activeClassName="active" className="link mx-1">
        <FontAwesomeIcon icon={['fas', 'chevron-left']}/> Back
    </div>
)

export default Back
