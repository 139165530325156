import React, {Component} from 'react';

class Content extends Component {
    render() {
        return (
            <section className="content col-lg content-top-spacer">
                    {this.props.children}
            </section>
        );
    }
}

export default Content
