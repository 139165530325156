import React from 'react'
import { Modal } from 'react-bootstrap'

export default ({show, onHide}) => {
    return (
        <Modal show={show} size="lg" onHide={onHide} >
            <Modal.Header>Terms & Conditions</Modal.Header>
            {/* <Modal.Title>Terms & Conditions</Modal.Title> */}
            <Modal.Body>
                Content ....
            </Modal.Body>
        </Modal>
    )
}
