import {ActionType, ExceptionActions} from 'redux/store/actions/Actions';
//**********************************************************************************
import {history} from "redux/store/configureStore";
import StorageService from "services/StorageService";
import AxiosService from "services/AxiosService";
//**********************************************************************************


/**
 * These are the actions dispatched whenever the API is used
 */
export function verifyToken() {
    return (dispatch) => {
        const token = StorageService.getToken();
        //console.log('AuthActions :: verifyToken', token);
        if (token) {
            dispatch({
                type: ActionType.AUTH_SUCCESS,
                token: token
            });
        } else {
            dispatch({
                type: ActionType.AUTH_ANONYMOUS
            });
        }
    };
}

export function refreshToken() {
    return (dispatch) => {
        const token = StorageService.getToken();
        //console.log('AuthActions :: refreshToken', token);
        if (token) {
            dispatch({
                type: ActionType.AUTH_REFRESH,
                token: token
            });
        }
    }
}

export function invalidateToken() {
    return (dispatch) => {
        const token = StorageService.getToken();
        //console.log('AuthActions :: invalidateToken', token);
        if (token) {
            StorageService.clearToken();
            dispatch({
                type: ActionType.AUTH_LOGOUT
            });
        }
    };
}


//**************************************************************************************************************************************************************

/**
 * Login to backend using email and password
 *
 * @param email
 * @param password
 * @returns {function(...[*]=)}
 */
export function authUsingCredentials(email, password) {
    return function (dispatch) {
        const axiosService = new AxiosService();

        axiosService.post('auth/login',
            {
                "data": {
                    "type": "authentication-identity",
                    "attributes": {
                        "kind": "user",
                        "credentials": {
                            "method": "hitlab-identity",
                            "email": email,
                            "password": password
                        }
                    }
                }
            }).then((response) => {
            if (response && response.data) {
                if (response.data.token) {
                    dispatch({
                        type: ActionType.AUTH_SUCCESS,
                        payload: response.data
                    });

                    //console.log( 'StorageService.setUser', response.data.user);

                    //StorageService.setHitlabUser(response.data.user);
                    StorageService.setToken(response.data.token);

                    if (response.data.redirectTo) {
                        history.push('/' + response.data.redirectTo);
                    } else {
                        history.push('/dashboard');
                    }
                } else {
                    history.push('/login');
                }
            }
        }).catch((error) => {
            ExceptionActions.authErrorHandler(dispatch, error.response, ActionType.AUTH_FAILURE);
            //dispatch(FlashMessage.addFlashMessage('error', 'Invalid username and password.'));
        });
    };
}


/**
 * Login to backend using AWS Cognito UserSession
 *
 * @param cognitoUserSession
 * @returns {function(...[*]=)}
 */
export function authUsingCognitoUserSession(cognitoUserSession) {
    return function (dispatch) {
        const axiosService = new AxiosService();

        let accessToken = cognitoUserSession.getIdToken()
        let token = accessToken.getJwtToken()

        axiosService.post('auth/token', {
            "data": {
                "type": "authentication-token",
                "attributes": {
                    "kind": "user",
                    "credentials": {
                        "method": "aws-identity-token",
                        "token": token
                    }
                }
            }
        }).then((response) => {
            if (response && response.data) {
                if (response.data.token) {
                    dispatch({
                        type: ActionType.AUTH_SUCCESS,
                        payload: response.data
                    });

                    //console.log("response", response.data.token);

                    //StorageService.setHitlabUser(response.data.user);
                    StorageService.setToken(response.data.token);

                    if (response.data.redirectTo) {
                        history.push('/' + response.data.redirectTo);
                    } else {
                        history.push('/dashboard');
                    }
                } else {
                    history.push('/login');
                }
            }
        }).catch((error) => {
            ExceptionActions.authErrorHandler(dispatch, error.response, ActionType.AUTH_FAILURE);
            //dispatch(FlashMessage.addFlashMessage('error', 'Invalid username and password.'));
        });
    };
}

