import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import _ from "lodash";

//**********************************************************
import configureActions from 'redux/store/configureActions';
import {HomeActions, LeaderboardActions} from "redux/store/actions/Actions";
//**********************************************************
import LeaderboardSongCard from "common/pureComponents/cards/song/LeaderboardSongCard";
import Loading from "common/components/loaders/Loading";


class Leaderboard extends PureComponent {

    constructor(props) {
        super(props);
        this._onLoad = this._onLoad.bind(this);
    }

    componentDidMount() {
       //console.log('Leaderboard :: componentDidMount', this);
        this._onLoad();
    }

    componentWillUnmount() {
       //console.log('Leaderboard :: componentWillUnmount', this);
        if (this.props.request) {
            this.props.request.cancel();
        }
    }

    _onLoad() {
       //console.log('Leaderboard :: _onLoad', this);
        this.props.actions.fetchLeaderboard({pageSize: 30, page: 1});
    }

    /*shouldComponentUpdate(nextProps, nextState){
       //console.log('Leaderboard :: shouldComponentUpdate', {'nextProps' : nextProps, nextState: nextState, props : this.props, state: this.state});
        if(this.props.leaderboard !== nextProps.leaderboard){
           //console.log('Leaderboard :: shouldComponentUpdate :: TRIGGER RENDER');
            return true;
        }else{
           //console.log('Leaderboard :: shouldComponentUpdate :: NO RENDER');
            return false;
        }
    }*/

    render() {
        //console.group('Leaderboard :: Render :: ', {props: this.props, state: this.state});
        const {isReady, leaderboard} = this.props || {};
        //const {leaderboard} = this.props.results || {};
        //console.info(this.props);
        //console.groupEnd();

        if (!isReady) {
            return <Loading/>;
        } else {
            return (
                <section className="leaderboard">
                    <div className="row">
                        <h5 className="col-md">Leaderboard</h5>
                    </div>
                    <div className="row">
                        {!leaderboard.results && <div className="col-md-12">Problem loading the learderboard</div>}
                        {leaderboard.results && leaderboard.results.map((song, index) =>
                            <div key={song.id} className="col-md-12">
                                <LeaderboardSongCard key={song.id} position={index + 1} song={song}/>
                            </div>
                        )}
                    </div>
                </section>
            );
        }
    }
}

export default connect(
    (state) => {
        //console.group('MapState :: Leaderboard :: ', state);

        let results;
        if (state.data &&
            state.data instanceof Map &&
            state.data.has('leaderboard')) {

            results = {
                isReady: true,
                leaderboard: state.data.get('leaderboard').leaderboard
            };

        } else {
            results = {isReady: false};
        }

        //console.info(results);
        //console.groupEnd();
        return results;
    },
    (dispatch) => {
        //console.group('MapDispatch :: Leaderboard');
        let actions = configureActions(dispatch, _.assign({}, HomeActions, LeaderboardActions));
        //console.info({actions: actions});
        //console.groupEnd();
        return {
            actions: actions
        }
    }
)(Leaderboard);

