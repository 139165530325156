import React, {Component, lazy, PureComponent, Suspense} from 'react';
import PropTypes from 'prop-types'; // ES6
import {connect} from 'react-redux';
import AxiosService from "services/AxiosService";


//const VideoCard = lazy(() => import('../../common/cards/video.card.component'));
import VideoCard from 'common/pureComponents/cards/VideoCard';
import LoadMore from "common/components/loaders/LoadMore";


class NewTopVideos extends PureComponent {


    constructor(props) {
        super(props);
        this.axios = new AxiosService();
        this.state = {
            isLoading: false,
            results: props.results,
            total: 0,
            page: 0,
            pageSize: 4
        };
        this._onLoadMore = this._onLoadMore.bind(this);
    }

    componentDidMount() {
        //console.log('NewTopVideos :: componentDidMount', this);
        //this._onLoad();
    }

    componentDidUpdate(prevProps) {
        //console.log('NewTopVideos :: componentDidUpdate', prevProps);

        if (prevProps.results !== this.props.results) {
            this.setState({
                results: this.props.results
            });
        }
    }

    componentWillUnmount() {
        //console.log('NewTopVideos :: componentWillUnmount', this);
        this.axios.cancel();
    }

    /*_onLoad = async () => {
        try {
            this.setState({isLoading: true});
            this.axios.fetch('videos', {
                'page-size': this.state.pageSize,
                'page': this.state.page
            }).then((response) => {
                //console.log('NewTopVideos :: _onLoadMore', this.props, this.state);
                this.setState({
                    isLoading: true,
                    results: response.data.results,
                    total: response.data.total,
                    page: response.data.page,
                    pageSize: response.data.pageSize,
                });
            });
        } catch (err) {
            //console.log('NewTopVideos :: _onLoad :: Error : ', err.message); // => prints: Api is being canceled
            this.setState({isLoading: false});
        }
    };*/

    _onLoadMore() {
        if (Array.isArray(this.state.results) && this.state.results.length !== 0) {
            this.setState({
                page: Number.parseInt(this.state.page) + 1,
                }, () => {
                    try {
                        this.axios.fetch('videos', {
                            'pageSize': this.state.pageSize,
                            'page': this.state.page
                        }).then((response) => {
                            //console.log('NewTopVideos :: _onLoadMore', this.props, this.state);
                            if (response && response.data) {
                                this.setState({
                                    isLoading: true,
                                    results: this.state.results.concat(response.data.results),
                                    total: response.data.total,
                                    page: response.data.page,
                                    pageSize: response.data.pageSize,
                                });
                            }
                        });
                    } catch (err) {
                        //console.log('NewTopVideos :: _onLoadMore :: Error', err.message); // => prints: Api is being canceled
                        this.setState({isLoading: false});
                    }
                }
            );
        }
    }


    render() {
        //const {results} = this.state;
        //console.log('NewTopVideos :: Render', results);

        if (this.state.results && this.state.results.length === 0) {
            return <div></div>;
        } else {
            return (
                <>
                    <section className="new-top-videos">
                        <div className="row ">
                            <h2 className="col-md h5">New Top Videos</h2>
                        </div>
                        <div className="row ">
                            {this.state.results && this.state.results.map((video, index) =>
                                <div key={video.id} className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                    <VideoCard key={video.id} video={video}/>
                                </div>
                            )}
                        </div>
                        <LoadMore onClick={this._onLoadMore}/>
                    </section>
                </>
            );
        }


    }
}

export default NewTopVideos
