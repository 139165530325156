import React from "react";
import PropTypes from 'prop-types';

class LoadMore extends React.PureComponent {
    render() {
        const { onClick } = this.props;

        return (
            <div className="row my-3">
                <div className="col-md text-center">
                    <button className="btn btn-primary" onClick={onClick}>Load more</button>
                </div>
            </div>

        )
    }
}
export default LoadMore



