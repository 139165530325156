import {ActionType, ExceptionActions} from 'redux/store/actions/Actions';
//**********************************************************************************

export function apiRequest(request, log) {
    //console.info('ApiAction :: API_REQUEST :: ' + log);
    return {
        type: ActionType.API_REQUEST,
        request: request,
    }
}

export function apiResponse(response, log) {
    //console.info('ApiAction :: API_RESPONSE :: ' + log);
    return {
        type: ActionType.API_RESPONSE,
        response: response,
    }
}

export function apiCancel(request) {
    //console.info('ApiAction :: API_CANCEL :: ', {request: request});
    request.cancel();
    return {
        type: ActionType.API_CANCEL,
        request: request,
    }
}
