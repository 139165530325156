import {Auth} from "aws-amplify";
import FormInput from "common/func/form/FormInput";
import FormMessage from "common/func/form/FormMessage";
import SubmitButton from "common/func/form/SubmitButton";
import _ from "lodash";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {Field, reduxForm} from "redux-form";
import {RequestActions} from "redux/store/actions/Actions";
import configureActions from "redux/store/configureActions";
import StorageService from "services/StorageService";

class Login extends Component {
    static propTypes = {
        isCognito: PropTypes.bool.isRequired,
        isAuthenticated: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        isCognito: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            message: null,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearMessage = this.clearMessage.bind(this);
    }

    async handleSubmit(formProps) {
        try {
            if (!this.props.isCognito) {
                this.props.actions.authUsingCredentials(
                    formProps.email,
                    formProps.password
                );
            } else {
                const user = await Auth.signIn(
                    formProps.email,
                    formProps.password.padEnd(6, 0)
                );
                StorageService.setCognitoUser(user); //fixme: we need to keep only the valuable data and hide whatever could be harmful form the user storage

                Auth.currentSession().then((data) => {
                    this.props.actions.authUsingCognitoUserSession(data);
                });
            }
        } catch (error) {
            //console.log('error signing in', error);
            this.setState({
                message: error.message,
            });
        }
    }

    clearMessage() {
        this.setState({
            message: null,
        });
    }

    render() {
        const {handleSubmit, handleOnBlur, submitting} = this.props;
        const {message} = this.state;

        return (
            <section className="login py-5">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-4">
                        <h2 className="">Login</h2>
                        <p className="">Login to start your session</p>

                        <form
                            method="post"
                            onClick={this.clearMessage}
                            onSubmit={handleSubmit(this.handleSubmit)}
                        >
                            <div className="">
                                <Field
                                    name="email"
                                    component={FormInput}
                                    type="email"
                                    label="Email"
                                    autoComplete="off"
                                ></Field>

                                <Field
                                    name="password"
                                    component={FormInput}
                                    type="password"
                                    label="Password"
                                    autoComplete="new-password"
                                ></Field>
                            </div>

                            <FormMessage message={message}/>

                            <div className="float-left">
                                <NavLink
                                    to={"/forgot-password"}
                                    className="link"
                                >
                                    I forgot my password
                                </NavLink>
                            </div>

                            <div className="float-right">
                                <NavLink to={"/register"} className="mr-3">
                                    Register
                                </NavLink>
                                <SubmitButton
                                    submitting={submitting}
                                    title="Login"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        );
    }
}

/**
 * Connect the component to the Redux store.
 */
export default connect(
    (state) => {
        return {
            token: state.auth && state.auth.token,
            isAuthenticated: state.auth && state.auth.isAuthenticated,
            message: "", //state.flash.message
        };
    },
    (dispatch) => {
        let actions = configureActions(dispatch, _.assign({}, RequestActions));
        return {actions: actions};
    }
)(
    reduxForm({
        form: "Login", // ←A Unique identifier for this form
        validate: (values) => {
            const errors = {};
            if (!values.email) {
                errors.email = "(The email field is required.)";
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = "Invalid email address.";
            }

            if (!values.password) {
                errors.password = "(The password field is required.)";
            }
            return errors;
        }, // ←Callback function for client-side validation
    })(Login)
);
