import React, {Component} from 'react';


import {Link} from "react-router-dom";
import {Row, Col, Image, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AxiosService from "services/AxiosService";

import ArtistSongList from 'components/routes/private/dashboard/sections/ArtistSongList';

class ArtistProfile extends Component {


    constructor(props) {
        super(props);

        this.axios = new AxiosService();
        this.state = {
            isLoading: false,
            artist: props.artist
        };
    }

    componentDidMount() {
        //console.log('ArtistProfile :: componentDidMount', this);
        //this._onLoad();
    }

    componentDidUpdate(prevProps) {
        //console.log('ArtistProfile :: componentDidUpdate', prevProps);

        if (prevProps.results !== this.props.results) {
            this.setState({
                results: this.props.results
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        //console.log('ArtistProfile :: shouldComponentUpdate', nextProps, nextState, nextState.results != this.state.results);
        return nextState.results != this.state.results;
    }

    componentWillUnmount() {
        //console.log('ArtistProfile :: componentWillUnmount', this);
        this.axios.cancel();
    }

    render() {
        const {artist, onRefresh} = this.props;
        //console.log("Render :: ArtistProfile ::", artist);

        return (
            <section className="artist-profile">
                <Row className="">
                    <div className="col-lg-3">
                        <div className="h3 my-3">{artist.username}</div>
                    </div>
                </Row>
                <Row className="my-3">
                    <Col xs={12} md={12} lg={12}>
                        <Row className="">
                            <Col xs={12} md={6} lg={4}>
                                <div>
                                    <Image src={artist.img_link} alt={artist.username} rounded className="w-100"/>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={8}>
                                <Row className="">
                                    <Col xs={4} className="text-left">
                                        {artist.origin}
                                    </Col>
                                    <Col xs={2} style={{'fontSize': '20pt'}}>
                                        <FontAwesomeIcon icon={['far', 'heart']}/>
                                        {/* <FontAwesomeIcon icon={['fas', 'heart']}/>*/}
                                    </Col>
                                    <Col xs={6} className="text-right " style={{'fontSize': '20pt'}}>
                                        <Link to={''} target="_blank" className="px-2 ">
                                            <FontAwesomeIcon icon={['fab', 'instagram']}/>
                                        </Link>
                                        <Link to={''} target="_blank" className="px-2 ">
                                            <FontAwesomeIcon icon={['fab', 'youtube']}/>
                                        </Link>
                                        <Link to={''} target="_blank" className="px-2 ">
                                            <FontAwesomeIcon icon={['fab', 'vimeo']}/>
                                        </Link>
                                        <Link to={''} target="_blank" className="px-2 ">
                                            <FontAwesomeIcon icon={['fab', 'twitter']}/>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className="artist-bio">
                                    <Col>
                                        <p>{artist.bio}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col className="text-right">
                                <button className="btn btn-dark" onClick={onRefresh}>Refresh</button>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col md={12}>
                                <ArtistSongList results={artist.songs}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default ArtistProfile;
