import {ActionType} from 'redux/store/actions/Actions';
//**********************************************************************************

export function authErrorHandler(dispatch, error, type) {
    let errorMessage = (error.data.message) ? error.data.message : error.data;
    if (error.status === 401) {
        errorMessage = 'You are not authorized to perform this action.';
    }
    dispatch({
        type,
        payload: errorMessage,
    });
}


export function errorHandler(dispatch, error, type) {
    let errorMessage = null;// (error.data.message) ? error.data.message : error.data;

    switch (type) {
        case ActionType.FAILURE:
            errorMessage = 'FAILURE...';
            break;
        case ActionType.EXCEPTION:
            errorMessage = 'EXCEPTION...';
            break;
        case ActionType.INVALID_RESPONSE_FORMAT:
            errorMessage = 'INVALID_RESPONSE_FORMAT...';
            break;
        default:
            errorMessage = 'Generic error';
    }

    if(error && error.status){
       switch(error.status){
           case 401 :
               errorMessage += 'You are not authorized to do this. Please login and try again.';
               break;
           case 500 :
               errorMessage += 'Server error. Please contact administrator.';
               break;
       }
    }

   //console.error("AN ERROR OCCURRED :: ", type);


    dispatch({
        type: type,
        payload: {
            message : errorMessage,
            error : error
        },
    });
}
