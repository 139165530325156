/**
 * Global - ActionTypes
 */
export const EXCEPTION = 'EXCEPTION';
export const FAILURE = 'FAILURE';
export const INVALID_RESPONSE_FORMAT = 'INVALID_RESPONSE_FORMAT';
/**
 * API / Axios - ActionTypes
 */
export const API_REQUEST = 'API_REQUEST';
export const API_RESPONSE = 'API_RESPONSE';
export const API_CANCEL = 'API_CANCEL';
export const API_EXCEPTION = 'API_EXCEPTION';
/**
 * Authentication & Token ActionTypes
 */
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ANONYMOUS = 'AUTH_ANONYMOUS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_REFRESH = 'AUTH_REFRESH';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
/**
 * User Model ActionTypes
 */
export const USER_STORE_ENTITY = 'USER_STORE_ENTITY';

/***************************************************************************************************************************************************************/

/**
 * Generic Model ActionTypes
 */
//export const LOAD_ENTITY = 'LOAD_ENTITY';
export const SAVE_LIST = 'SAVE_LIST';
export const SAVE_ENTITY = 'SAVE_ENTITY';
export const DELETE_LIST = 'DELETE_LIST';
export const DELETE_ENTITY = 'DELETE_ENTITY';
export const CLEAR_LIST = 'CLEAR_LIST';
export const CLEAR_ENTITY = 'CLEAR_ENTITY';
//----------------------------------------------------------------------------------------------
export const LOAD_RESULTS = 'LOAD_RESULTS';
export const LOAD_RESULTS_FROM_CACHE = 'LOAD_RESULTS_FROM_CACHE';

export const LOAD_ENTITY = 'LOAD_ENTITY';
export const LOAD_ENTITY_FROM_CACHE = 'LOAD_ENTITY_FROM_CACHE';

export const LOAD_LIST = 'LOAD_LIST';
export const LOAD_LIST_FROM_CACHE = 'LOAD_LIST_FROM_CACHE';

/**
 * Specific Model - ActionTypes
 */
export const LOAD_LEADERBOARD = 'LOAD_LEADERBOARD';
export const LOAD_LEADERBOARD_FROM_CACHE = 'LOAD_LEADERBOARD_FROM_CACHE';

/***************************************************************************************************************************************************************/

/**
 * Components - ActionTypes
 */
export const ENQUEUE_SONG = 'ENQUEUE_SONG';
export const DnD_REORDER = 'DnD_REORDER';
export const FLASH_ADD_MESSAGE = 'FLASH_ADD_MESSAGE';
export const FLASH_CLEAR_MESSAGES = 'FLASH_CLEAR_MESSAGES';

/***************************************************************************************************************************************************************/

/**
 * Redux Store Data - ActionTypes
 */
export const DATA_APPEND_RESULTS = 'DATA_APPEND_RESULTS';
export const DATA_CLEAR = 'DATA_CLEAR';
export const CACHE_ADD_LIST = 'CACHE_ADD_LIST';
export const CACHE_ADD_ENTITY = 'CACHE_ADD_ENTITY';
export const CACHE_LOAD_LIST = 'CACHE_LOAD_LIST';
export const CACHE_LOAD_ENTITY = 'CACHE_LOAD_ENTITY';
export const CACHE_CLEAR_LIST = 'CACHE_CLEAR_LIST';
export const CACHE_CLEAR_ENTITY = 'CACHE_CLEAR_ENTITY';
/***************************************************************************************************************************************************************/





