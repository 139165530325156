import React, {Component} from 'react';
import PropTypes from 'prop-types'; // ES6
import {connect} from 'react-redux';
import { history } from 'redux/store/configureStore';

export default function (ComposedComponent) {
    class RequiredAuth extends Component {
        static propTypes = {
            isAuthenticated: PropTypes.bool.isRequired,
        };

        componentDidMount() {
           //console.log("RequiredAuth :: componentDidMount", this.props, this.props.isAuthenticated, this.context.router);
            if (!this.props.isAuthenticated) {
               //console.log("Authenticate :: componentDidMount :: isAuthenticated === FALSE :: Redirecting to home");
                //history.push('/login');
            }
        }
        /*componentDidUpdate(nextProps) {
          //console.log("RequiredAuth :: componentWillUpdate", this.props, nextProps);
            if (!nextProps.isAuthenticated) {
               //console.log("Authenticate :: componentWillUpdate :: nextProps.isAuthenticated === FALSE :: Redirecting to profile");
                history.push('/dashboard');
            }
        }*/
        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    return connect(state => {
       //console.log("RequiredAuth :: mapStateToProps", state);
        return {
            isAuthenticated: (state.auth && state.auth.isAuthenticated) ? state.auth.isAuthenticated : false
        };
    })(RequiredAuth);
}
