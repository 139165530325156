import {ActionType, ExceptionActions} from 'redux/store/actions/Actions';
//**********************************************************************************

export function reorder(storeKey, list) {
    //console.log('DataActions :: clearData');

    return (dispatch) => {
        return dispatch({
            type: ActionType.DnD_REORDER,

        });
    }
}

