import React, {Component} from 'react';
import {connect} from "react-redux";
import {NavLink} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown'
import StorageService from "services/StorageService";

class UserMenu extends Component {
    render() {
        const {isAuthenticated} = this.props
        return (
            <>
                {!isAuthenticated &&
                <div className="mr-3">
                    <NavLink to={'/login'}>Login</NavLink>
                    <NavLink to={'/register'}>Register</NavLink>
                </div>
                }
                {isAuthenticated &&
                <Dropdown as="div" className="navbar-nav mr-auto float-right">
                    <Dropdown.Toggle id="dropdown-basic" as="div" className="nav-item dropdown nav-link m-0 p-0 pt-1">
                        <span className="text-white d-none d-sm-inline">{StorageService.getUserDisplayedName()}</span>
                        <span className="text-white d-inline d-sm-none">Menu</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="nav-item">
                        <Dropdown.Item href={'/discover'}>DNA Scoring</Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item href={'/settings/subscriptions'}>Subscriptions</Dropdown.Item>
                        <Dropdown.Item href={'/reset-password'}>Reset Password</Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item href="/logout">Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                }
            </>
        )
    }
}

export default connect(
    (state) => {
        return {isAuthenticated: state.auth.isAuthenticated};
    }
)(UserMenu);
