import { createBrowserHistory } from 'history'
import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import {connectRouter, routerMiddleware} from 'connected-react-router'

//-------------------------------------------------------------------------------
// middlewares
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware'
import loggerMiddleware from 'redux-logger';
import debugLoggerMiddleware from 'redux/store/middlewares/debugLogger';
import {AuthReducer, UserReducer, ApiReducer, FlashReducer, CacheReducer, DataReducer, PaginationReducer, FormReducer, RouterReducer} from 'redux/store/reducers/Reducers';
//-------------------------------------------------------------------------------
// enhancers
//import monitorReducersEnhancer from './enhancers/monitorReducer';
//-------------------------------------------------------------------------------
import Env from "configs/Env";
//-------------------------------------------------------------------------------
export const history = createBrowserHistory();

const createRootReducer = (history) => combineReducers({
    auth: AuthReducer,
    api: ApiReducer,
    flash: FlashReducer,
    //************************************************
    cache: CacheReducer,
    data: DataReducer,
    pagination: PaginationReducer,
    //************************************************
    //page: pageReducer,
    //************************************************
    form: FormReducer,
    routing: RouterReducer,
    router: connectRouter(history),
});

export default function configureStore(preloadedState) {
    //***********************************************************************************************
    let middlewares;
    if(Env.DEBUG) {
       //console.log("*** REDUX STORE DEBUGGING IS ENABLED ***");
        middlewares = [routerMiddleware(history), thunkMiddleware, /*promiseMiddleware,*/ /*loggerMiddleware,*/ debugLoggerMiddleware];
    }else{
        middlewares = [routerMiddleware(history),thunkMiddleware, /*promiseMiddleware*/];
    }
    const middlewareEnhancer = applyMiddleware(...middlewares);
    //***********************************************************************************************
    let enhancers;
    if(Env.DEBUG) {
        enhancers = [middlewareEnhancer/*, monitorReducersEnhancer*/];
    }else{
        enhancers = [middlewareEnhancer];
    }
    //***********************************************************************************************
    let composedEnhancers;

    // NOTE: don't move the following condition out of the if loop (e.g. into a constant).
    // The following condition has to be inline or babel during complilation won't evaluate the expression
    // and it will throw an error if the `redux-devtools-extension` is not installed (which it won't in production environment)
    if (process.env.NODE_ENV !== "production") {
        const { composeWithDevTools } = require("redux-devtools-extension");
        composedEnhancers = composeWithDevTools(...enhancers);
    } else {
        composedEnhancers = compose(...enhancers);
    }
    //***********************************************************************************************
    const store = createStore(createRootReducer(history), preloadedState, composedEnhancers);
    return store;
}
