import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PlayPause from "common/components/player/media-player/PlayPause";
import MuteUnmute from "common/components/player/media-player/MuteUnmute";
import React, {Component} from "react";

class PlayerNotReady extends Component {
    render() {
        return (
            <div className="text-center py-2 h4">
                <span className="p-2" style={{'lineHeight':'40px'}}>
                    <img src="/img/logo-hitlab-150x150.png" alt="Hitlab Community" height="40px"/>
                </span>
                <span className="p-2" style={{'lineHeight':'40px'}}>
                    Hitlab DNA
                </span>
            </div>
        )
    }
}

export default PlayerNotReady

