import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {globalPlayer} from "common/components/player/media-player/AudioPlayer";
import React, {Component} from "react";

class RecommendationTableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            icon: 'play-circle',
            song: null
        }
        this._onStop = this._onStop.bind(this);
    }

    componentWillUnmount() {
        this._onStop = null
    }

    _onStop(){
        this.setState({'icon' : 'play-circle'});
    }

    handlePlaySong(){
        globalPlayer.enqueueSong(this.props.song, this._onStop);
        this.setState({'icon' : 'pause-circle'});
    };

    render() {
        const {song} = this.props;
        return (
            <>
                <td>
                    <span className="text-left link" onClick={() => this.handlePlaySong(song)}>
                        <FontAwesomeIcon icon={this.state.icon} size="lg"/> {song.artist} - {song.title}
                    </span>
                </td>
                <td style={{ 'whiteSpace': 'nowrap' }}>{song.genre}</td>
                <td style={{ 'whiteSpace': 'nowrap' }}>{song.year}</td>
                <td style={{ 'whiteSpace': 'nowrap' }}><small><i><b>{song.source}</b></i></small></td>
            </>
        );
    }
}

export default RecommendationTableRow;
