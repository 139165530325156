import React, {Component} from 'react';

import axios from "axios";
import configs from "configs/Env";
import Spinner from "react-bootstrap/Spinner";
import cookie from "react-cookie";
import AxiosService from "services/AxiosService";

import NavBreadcrumb from 'common/components/nav/NavBreadcrumb';
import ArtistProfile from 'components/routes/private/dashboard/sections/ArtistProfile';
import Loading from 'common/components/loaders/Loading'


class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.axios = new AxiosService();

        this.state = {
            isLoading: false,
            result: {
                artist: null,
                fan: null,
                user: null,
            },
        };

        this._onLoad = this._onLoad.bind(this);
        this._onRefresh = this._onRefresh.bind(this);
    }

    componentDidMount() {
       //console.log('Dashboard :: componentDidMount', this);
        this._onLoad();
    }

    componentWillUnmount() {
        this.axios.cancel();
    }

    _onLoad = async () => {
        try {
           //console.log('Dashboard :: _onLoad', this);
            this.setState({isLoading: true});

            this.axios.fetch('user/dashboard', {})
                .then((response) => {
                    //console.log('Dashboard :: _onLoad :: ', response);
                    if(response && response.data) {
                        this.setState({
                            isLoading: true,
                            result: response.data.result
                        });
                    }
                });

        } catch (err) {
            //console.log('Profile :: _onLoad :: Error : ', err.message);
            this.setState({isLoading: false});
        }
    };

    _onRefresh(){
       //console.log('ArtistProfile :: _onRefresh');
        this._onLoad();
    }


    render() {
        //console.log('Render dashboard :: ', this.state);
        const {user, artist, fan} = this.state.result;

        if (artist) {
            return (
                <>
                    <NavBreadcrumb user={artist}/>
                    <ArtistProfile artist={artist} onRefresh={this._onRefresh}/>
                </>
            );
        } else if (fan) {
            return (
                <>
                    <NavBreadcrumb user={fan}/>
                    <h6>Fan profile</h6>
                </>
            );
        } else if (user) {
            return (
                <>
                    <NavBreadcrumb user={fan}/>
                    <h6>User profile</h6>
                </>
            );
        } else {
            return <Loading/>;
        }
    }
}

export default Dashboard
