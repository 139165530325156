import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {globalPlayer} from "common/components/player/media-player/AudioPlayer";
import React from "react";
import {Table} from "react-bootstrap";

class DiscoverTableRow extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
            playing: false,
            song: null
        }
        this._toggleHover = this._toggleHover.bind(this);
        this._togglePlayPause = this._togglePlayPause.bind(this);

        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
    }

    componentWillUnmount() {
        this._onStop = null
    }

    onPlay() {
        this.setState({'playing': true});
    }

    onPause() {
        this.setState({'playing': false});
    }

    _togglePlayPause() {
        this.props.song.playingComponent = () => this;
        globalPlayer.togglePlayPause(this.props.song)
        this.setState({'playing': !this.state.playing})
    }

    _toggleHover() {
        this.setState({'hover': !this.state.hover})
    }


    render() {
        const {song} = this.props;

        //******************************************
        var rowClass = 'song-row';
        var playPauseIcon;
        if (this.state.hover) {
            rowClass += ' hover'
        }
        if (this.state.playing) {
            rowClass += ' playing'
            playPauseIcon = 'pause-circle';
        } else {
            playPauseIcon = 'play-circle';
        }
        //******************************************

        return (
            <tr className={rowClass} onMouseEnter={this._toggleHover} onMouseLeave={this._toggleHover} onClick={this._togglePlayPause}>
                <td>
                    <div className="song-cover-container">
                        <img className="song-cover-img" src={song.img_link} alt={song.user.username}/>
                        <div className="song-cover-play-overlay">
                            <FontAwesomeIcon className="song-cover-play-icon" icon={playPauseIcon} size="4x"/>
                        </div>
                    </div>
                </td>
                <td>{song.dhs_artist} - {song.dhs_title}</td>
                <td>{song.score}</td>
                <td>
                    <Table className="text-right" responsive="sm" size="sm" style={{'fontSize': '12px'}}>
                        <thead>
                        <tr>
                            {/*<th>Score</th>*/}
                            <th>Week</th>
                            <th>Peak</th>
                            <th>Year</th>
                            <th>Proximity</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {/*<td className="text-right"
                                style={{'whiteSpace': 'nowrap'}}>{Number(song.score * 100).toFixed(2)} %
                            </td>*/}
                            <td>{song.week}</td>
                            <td>{song.peak}</td>
                            <td>{song.year}</td>
                            <td className="text-right"
                                style={{'whiteSpace': 'nowrap'}}>{Number(song.proximity * 100).toFixed(2)} %
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </td>
            </tr>
        );
    }
}

export default DiscoverTableRow;
