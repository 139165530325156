import Loading from "common/components/loaders/Loading";
import React, {Component, lazy, Suspense} from 'react';
import PropTypes from 'prop-types'; // ES6
import {connect} from 'react-redux';

import axios from 'axios';
import cookie from 'react-cookie';

import SongCard from "common/pureComponents/cards/song/SongCard";
//const SongCard = import('components/common/cards/song.card.component');

class FilteredSongs extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { results, pagination } = this.props;
       //console.log('render :: FilteredSongs',results, pagination);

        return (
            <section className="songs">
                <div className="row my-3 ml-3">
                    {!pagination &&
                    <Loading/>
                    }
                    {pagination && pagination.total > 0 &&
                    <h6 className="col-md">{pagination.total} result(s)</h6>
                    }
                </div>
                <div className="row my-3">
                    {results && results.map((song, index) =>
                        <div key={song.id} className="col-md-3">
                            <Suspense fallback={<div>Loading...</div>}>
                                <SongCard key={song.id} song={song}/>
                            </Suspense>
                        </div>
                    )}
                </div>
            </section>
        );
    }
}
export default FilteredSongs
