import React, {Component} from 'react';


// Import custom components
//import UserPanel from './user-panel.component';
//import Search from './search.component';

import AudioPlayer from 'common/components/player/media-player/AudioPlayer';

class PlayerBottom extends Component {

    shouldComponentUpdate(nextProps, nextState) {
       //console.log('NavBottom :: shouldComponentUpdate', nextProps, nextState);
        return false;
    }

    render() {
       //console.info('NavBottom :: Render :: ', {props: this.props, state: this.state});

        return (
            <section className="nav">
                <nav className="nav-bottom container-fluid">
                    <div className="row">
                        <div className="col-lg-1"></div>
                        <div className="col-lg">
                            <div className="row">
                                <div className="col-lg">
                                    <AudioPlayer/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                    </div>
                </nav>
            </section>
        );
    }
}

export default PlayerBottom
