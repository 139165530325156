import axios from "axios";
import cookie from "react-cookie";
import Env from "configs/Env";
import StorageService from "services/StorageService";

axios.defaults.baseURL = Env.API_URL;
axios.defaults.timeout = 30000; // 30 seconds

const CancelToken = axios.CancelToken;

class AxiosService {
    constructor() {
        this.isPending = true;
        this.isRejected = false;
        this.isFulfilled = false;
        this.isCanceled = false;

        this.source = CancelToken.source();
        this.request = axios.create({
            headers: {
                Accept: "application/vnd.hitlab.v0_1+json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + StorageService.getToken(),
            },
            cancelToken: this.source.token,
        });
        this.request.interceptors.request.use(
            function (config) {
                /*console.log("******************************************* request interceptor");
            console.log(config);
            console.log("******************************************* request interceptor");*/
                return config;
            },
            function (error) {
                /*console.log("******************************************* request interceptor error");
            console.log(error);
            console.log("******************************************* request interceptor error");*/
                return Promise.reject(error);
            }
        );

        this.request.interceptors.response.use(
            function (response) {
                /* console.log("******************************************* response interceptor");
            console.log(response);
            console.log("******************************************* response interceptor");*/
                return response;
            },
            function (error) {
                /*console.log("******************************************* response interceptor error");
            console.log(error);
            console.log("******************************************* response interceptor error");*/
                return Promise.reject(error);
            }
        );
    }

    cancel(message) {
        if (this.request) {
            this.source.cancel(message);
            this.isCanceled = true;
        }
    }

    fetch(apiPath, params) {
        let queryString = "";
        if (params) {
            queryString =
                "?" +
                Object.keys(params)
                    .map((key) => key + "=" + params[key])
                    .join("&"); //always ends with '&' as a security
        }

        this.isPending = true;
        this.isRejected = false;
        this.isFulfilled = false;
        this.isCanceled = false;
        return this.request
            .get(apiPath + queryString)
            .then((res) => {
                if (res.data && res.data.type) {
                    //transform data ??
                }

                this.isPending = false;
                this.isFulfilled = true;
                //console.group('AxiosService :: fetch :: ' + apiPath + queryString);
                //console.info('Response : ', res);
                //console.groupEnd();
                return res;
            })
            .catch((err) => {
                this.isPending = false;
                this.isRejected = true;
                this.source.cancel();
                //console.group('AxiosService :: fetch  :: ' + apiPath + queryString);
                //console.info('Error : ', {request: err.request, response: err.response});

                //******************************************************************************************************
                if (this.request && this.request.isCancel === "function" && this.request.isCancel(err)) {
                    //console.error('Request canceled', err.message);
                } else {
                    // handle error
                    if (err.response) {
                        if (err.response.status === 401) {
                            window.location = "/login";
                        } else {
                            //console.error('Handle error canceled', err.message);
                        }
                    }
                }
                //console.error('AxiosService :: fetch => ', error);
                //errorHandler(dispatch, error.response, ActionType.FAILURE);
                //console.groupEnd();
            });
    }

    get(apiPath, params) {
        this.isPending = true;
        this.isRejected = false;
        this.isFulfilled = false;
        this.isCanceled = false;
        return this.request
            .get(apiPath, params)
            .then((res) => {
                this.isPending = false;
                this.isFulfilled = true;
                //console.group('AxiosService :: get :: ' + apiPath + "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&'));
                //console.info('Response : ', res);
                //console.groupEnd();
                return res;
            })
            .catch((err) => {
                this.isPending = false;
                this.isRejected = true;
                this.source.cancel();

                //console.group('AxiosService :: get  :: ' + apiPath + "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&'));
                //console.info('Error : ', {request: err.request, response: err.response});
                //******************************************************************************************************
                if (this.request && this.request.isCancel === "function" && this.request.isCancel(err)) {
                    //console.error('Request canceled', err.message);
                } else {
                    // handle error
                    if (err.response) {
                        if (err.response.status === 401) {
                            window.location = "/login";
                        } else {
                            //console.error('Handle error canceled', err.message);
                        }
                    }
                }
                //console.error('AxiosService :: fetch => ', error);
                //errorHandler(dispatch, error.response, ActionType.FAILURE);
                //console.groupEnd();
            });
    }

    post(apiPath, params) {
        this.isPending = true;
        this.isRejected = false;
        this.isFulfilled = false;
        this.isCanceled = false;
        return this.request
            .post(apiPath, params)
            .then((res) => {
                this.isPending = false;
                this.isFulfilled = true;
                //console.group('AxiosService :: post :: ' + apiPath + "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&'));
                //console.info('Response : ', res);
                //console.groupEnd();
                return res;
            })
            .catch((err) => {
                this.isPending = false;
                this.isRejected = true;

                //console.group('AxiosService :: post  :: ' + apiPath + "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&'));
                //console.info('Error : ', {request: err.request, response: err.response});
                //******************************************************************************************************
                if (this.request && this.request.isCancel === "function" && this.request.isCancel(err)) {
                    //console.error('Request canceled', err.message);
                } else {
                    // handle error
                    if (err.response) {
                        if (err.response.status === 401) {
                            window.location = "/login";
                        } else {
                            throw err;
                        }
                    }
                }
                //console.error('AxiosService :: fetch => ', error);
                //errorHandler(dispatch, error.response, ActionType.FAILURE);
                //console.groupEnd();
            });
    }
    patch(apiPath, params) {
        this.isPending = true;
        this.isRejected = false;
        this.isFulfilled = false;
        this.isCanceled = false;
        return this.request
            .patch(apiPath, params)
            .then((res) => {
                this.isPending = false;
                this.isFulfilled = true;
                return res;
            })
            .catch((err) => {
                this.isPending = false;
                this.isRejected = true;
                this.source.cancel();

                //console.group('AxiosService :: post  :: ' + apiPath + "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&'));
                //console.info('Error : ', {request: err.request, response: err.response});
                //******************************************************************************************************
                if (this.request && this.request.isCancel === "function" && this.request.isCancel(err)) {
                    //console.error('Request canceled', err.message);
                } else {
                    // handle error
                    if (err.response) {
                        if (err.response.status === 401) {
                            window.location = "/login";
                        }
                    }
                }
            });
    }

    delete(apiPath) {
        this.isPending = true;
        this.isRejected = false;
        this.isFulfilled = false;
        this.isCanceled = false;
        return this.request
            .delete(apiPath)
            .then((res) => {
                this.isPending = false;
                this.isFulfilled = true;
                return res;
            })
            .catch((err) => {
                this.isPending = false;
                this.isRejected = true;
                this.source.cancel();
                //******************************************************************************************************
                if (this.request && this.request.isCancel === "function" && this.request.isCancel(err)) {
                    //console.error('Request canceled', err.message);
                } else {
                    // handle error
                    if (err.response) {
                        if (err.response.status === 401) {
                            window.location = "/login";
                        } else {
                            //console.error('Handle error canceled', err.message);
                        }
                    }
                }
                //errorHandler(dispatch, error.response, ActionType.FAILURE);
            });
    }

    upload(apiPath, formData) {
        //console.log("AxiosService :: upload", formData);

        this.isPending = true;
        this.isRejected = false;
        this.isFulfilled = false;
        this.isCanceled = false;
        return this.request
            .post(apiPath, formData, {
                timeout: 300000, // 5 minutes
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    // do whatever you like with the percentage complete
                    // maybe dispatch an action that will update a progress bar or something
                    //console.log("AxiosService :: onUploadProgress", percentCompleted);
                },
            })
            .then((res) => {
                this.isPending = false;
                this.isFulfilled = true;
                return res;
            })
            .catch((err) => {
                this.isPending = false;
                this.isRejected = true;
                //******************************************************************************************************
                if (this.request && this.request.isCancel === "function" && this.request.isCancel(err)) {
                    //console.error('Request canceled', err.message);
                } else {
                    // handle error
                    if (err.response) {
                        if (err.response.status === 401) {
                            window.location = "/login";
                        } else {
                            throw err;    
                        }
                    }
                }
                //errorHandler(dispatch, error.response, ActionType.FAILURE);
            });
    }
}

export default AxiosService;
