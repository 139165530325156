import React, { Component } from 'react';

// Import custom components


class Songs extends Component {
    render(){
        //console.log('Songs :: Render', this.state);
        return (
            <div>
                <h1>Songs</h1>
                <h2>À venir...</h2>
            </div>
        );
    }
}

export default Songs
