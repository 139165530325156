import React, {Component} from 'react';

class ContentCompact extends Component {
    render() {
        return (
            <section className="content col-lg content-compact-top-spacer">
                    {this.props.children}
            </section>
        );
    }
}
export default ContentCompact
