import _ from "lodash";
import React, {Component} from 'react';
import PropTypes from 'prop-types'; // ES6
import {connect} from 'react-redux';
import * as HomeActions from "redux/store/actions/actions/HomeActions";
import configureActions from "redux/store/configureActions";

import Loading from "common/components/loaders/Loading";
import LoadMore from "common/components/loaders/LoadMore";
import ArtistCard from 'common/pureComponents/cards/artist/ArtistCard';


class NewTopArtists extends Component {

    constructor(props) {
        super(props);
        this._onLoad = this._onLoad.bind(this);
        this._onLoadMore = this._onLoadMore.bind(this);
    }

    componentDidMount() {
       //console.log('NewTopArtists :: componentDidMount', this);
        this._onLoad();
    }

    componentWillUnmount() {
       //console.log('NewTopArtists :: componentWillUnmount', this);
        if (this.props.request) {
            this.props.request.cancel();
        }
    }
    _onLoad() {
       //console.log('NewTopArtists :: _onLoad', this);
        this.props.actions.fetch('home/newTopArtists', {
            pageSize: 4,
            page: 1
        });
    }
    _onLoadMore() {
       //console.log('NewTopArtists :: _onLoadMore', this);
        const {pagination, results} = this.props.results.newTopArtists;
        this.props.actions.fetchMore('home/newTopArtists', {
            pageSize: pagination ? pagination.per_page : 4,
            page: pagination ? (pagination.current_page + 1) : 1
        });
    }
    render() {
       //console.group('NewTopArtists :: Render :: ', {props: this.props, state: this.state});
        const {newTopArtists} = this.props.results || {};
       //console.info(newTopArtists);
       //console.groupEnd();

        if (!newTopArtists || !newTopArtists.results) {
            return <Loading/>;
        } else {
            return (
                <section className="new-top-artists">
                    <div className="row">
                        <h5 className="col-md">New Top Artists</h5>
                    </div>
                    <div className="row ">
                        {newTopArtists.results && newTopArtists.results.map((artist, index) =>
                            <div key={artist.id} className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                <ArtistCard key={artist.id} artist={artist}/>
                            </div>
                        )}
                    </div>
                    <LoadMore onClick={this._onLoadMore}/>
                </section>
            );
        }
    }
}


export default connect(
    (state) => {
       //console.group('MapState :: NewTopArtists :: ',  state);
        let results;
        if (state.data && state.data instanceof Map) {
            if (state.data && state.data instanceof Map && state.data.has('home/newTopArtists')) {
                let newTopArtists = state.data.get('home/newTopArtists');
               //console.info(newTopArtists);
                results = {results: newTopArtists};
            }else{
                results = {results: null};
            }
        } else {
            results = {results: null};
        }

       //console.groupEnd();
        return results;
    },
    (dispatch) => {
       //console.group('MapDispatch :: NewTopArtists');
        let actions = configureActions(dispatch, _.assign({}, HomeActions));
       //console.info({actions: actions});
       //console.groupEnd();
        return {
            actions: actions
        }
    }
)(NewTopArtists);
