import DiscoverSongListCard from "common/pureComponents/cards/songlist/DiscoverSongListCard";
import DemoNavLinks from "components/routes/private/demo/common/DemoNavLinks";
import _ from "lodash";
import moment from "moment";
import React, {Component} from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Col, Row} from "react-bootstrap";
import {connect} from "react-redux";
import * as RequestActions from "redux/store/actions/actions/RequestActions";
import * as DnDActions from "redux/store/actions/actions/DnDActions";
import configureActions from "redux/store/configureActions";
import AxiosService from "services/AxiosService";
import {DiscoverUploader} from "components/routes/private/demo/common/DiscoverUploader";
import Loading from 'common/components/loaders/Loading'
import DiscoverModal from 'components/routes/private/demo/discover/DiscoverModal';
import EditModal from 'components/routes/private/demo/discover/EditModal';
import {Link} from "react-router-dom";
import CommunityDriver from 'services/CommunityDriver'

class Discover extends Component {


    constructor(props) {
        super(props);

        this.axios = new AxiosService();
        this.communityDriver = new CommunityDriver();
        this.songContainerRefs = [];
        this.state = {
            user: null,
            //pagination: null,
            latestAnalysisModal: {
                show: false,
                song: null
            },
            editSong: {
                show: false,
                song: null
            }
        };
        this.timer = null;

        this._onLoad = this._onLoad.bind(this);
        this._startPolling = this._startPolling.bind(this);

        this.onBeforeDragStart = this.onBeforeDragStart.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragUpdate = this.onDragUpdate.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);

        this._onUploadSuccess = this._onUploadSuccess.bind(this);
        this._onRefeature = this._onRefeature.bind(this);//ADMIN ONLY
        this._onReanalyze = this._onReanalyze.bind(this);//ADMIN ONLY

        this._onShowSimilars = this._onShowSimilars.bind(this);
        this._onHideSimilars = this._onHideSimilars.bind(this);
        this._onDelete = this._onDelete.bind(this);
        this._onShowEditModal = this._onShowEditModal.bind(this)
        this._onHideEditModal = this._onHideEditModal.bind(this)

        this._prepareSong = this._prepareSong.bind(this)
    }

    componentDidMount() {
        //console.log('Discover :: componentDidMount', this);
        this._onLoad();
    }


    componentWillUnmount() {
        this.axios.cancel();
        clearTimeout(this.timer);
    }

    _onLoad() {
        //console.log('Discover :: _onLoad', this);
        //const formData = this._getFormData();

        this.props.actions.load('user/discover', {
            //filterByGenre: formData.filterByGenre,
            //sortBy: formData.sortBy,
            pageSize: 16,
            page: 1
        });
    }

    _startPolling() {
        if (this.timer) {
            clearTimeout(this.timer);
        } else {
            this._onLoad(); //Initial fetch
        }
        this.timer = setInterval(() => this._onLoad(), 10000);
    }

    _onDelete(song) {
        //console.log('Discover :: _onDelete', this, song);

        try {
            this.axios.delete('song/' + song.id)
                .then((response) => {
                    if (response && response.data) {
                        this._onLoad();
                    }
                })
        } catch (err) {
            //console.log('Discover :: _onLoad :: Error : ', err.message);
        }
    }


    //*********************************************************************************************************************
    // Events
    _onUploadSuccess(data) {
        //console.log('onUploadSuccess', this, data);

        /*
        //TODO: Bind DnDAction to redux store
        this.setState({
            draggedSong: this.state.songs.unshift(data.song)
        });*/


        //Start polling for feature extraction
        this._startPolling();
    }

    _onRefeature(song) {
        try {
            console.log('Song :: _onRefeature', song);

            this.communityDriver.analyseSong(song)
                .then((response) => {
                    if (!response) {
                        //display error
                    } else {
                        this.setState({
                            user: response
                        });
                    }
                })
        } catch (err) {
            console.log('Song :: _onRefeature :: Error : ', err.message);
        }
    }

    _onReanalyze(song) {
        try {
            this.axios.get('song/' + song.id + '/reanalyze', {})
                .then((response) => {
                    if (response && response.data) {
                        this._startPolling();
                    }
                })
        } catch (err) {
            //console.log('Song :: _onReanalyze :: Error : ', err.message);
        }
    };

    //*********************************************************************************************************************
    // LatestAnalysisModal
    _onShowEditModal(song) {
        this.setState({
            editSong: {
                show: true,
                song: song
            }
        });
    }

    _onHideEditModal() {
        this.setState({
            editSong: {
                show: false,
                song: null
            }
        });
    }

    //*********************************************************************************************************************
    // LatestAnalysisModal
    _onShowSimilars(song) {
        this.setState({
            latestAnalysisModal: {
                show: true,
                song: song
            }
        });
    }

    _onHideSimilars() {
        this.setState({
            latestAnalysisModal: {
                show: false,
                song: null
            }
        });
    }

    _setSongContainerRef = (ref) => {
        this.songContainerRefs.push(ref);
    };

    _prepareSong(song, songs, actualIndex) {
        let nextIndex = actualIndex + 1
        let previousIndex = actualIndex - 1

        if (songs.length <= nextIndex) {
            nextIndex = 0
        }

        let nextSong = songs[nextIndex];

        if (previousIndex < 0) {
            previousIndex = songs.length - 1
        }

        let previousSong = songs[previousIndex];



        song.nextSong = null
        if (nextSong) {
            nextSong.playingComponent = () => this.songContainerRefs[nextIndex]
            song.nextSong = nextSong
        }

        song.previousSong = null
        if (previousSong) {
            previousSong.playingComponent = () => this.songContainerRefs[previousIndex]
            song.previousSong = previousSong
        }

        return song;
    }


    //*********************************************************************************************************************
    // Drag n' Drop
    reorder = (list, startIndex, endIndex) => {
        //TODO: Bind DnDAction to redux store
        const reordering = Array.from(list);
        const [removed] = reordering.splice(startIndex, 1);
        reordering.splice(endIndex, 0, removed);
        return reordering;
    };

    onBeforeDragStart = () => {
        //TODO: Bind DnDAction to redux store
        /*...*/
    };

    onDragStart = () => {
        //TODO: Bind DnDAction to redux store
        /*...*/
    };
    onDragUpdate = () => {
        //TODO: Bind DnDAction to redux store
        /*...*/
    };
    onDragEnd = (draggedSong) => {
        const {source, destination} = draggedSong;

        // dropped outside the list
        if (!destination) {
            return;
        }
        return;
        /*
        const songsReordered = this.reorder(
            this.props.result.user.songs,
            draggedSong.source.index,
            draggedSong.destination.index
        );
        //console.log( "*********** songsReordered : ", songsReordered);this.setState({
            songs: songsReordered
        });*/

        //TODO: Bind DnDAction to redux store
        /*this.props.actions.reorder(
            this.props.result.user.songs,
            draggedSong.source.index,
            draggedSong.destination.index
        )*/
    };

    //*********************************************************************************************************************

    render() {
        //console.log('Discover :: Render :: ', this.props);

        const {isProcessing, result} = this.props;
        const {user} = (result && result.user ? result : {});

        //***************************************************************
        // Special code to handle automatic polling
        // TODO: Make this a bit cleaner and in sync with any other polling
        if (isProcessing) {
            user.songs.map((song) => {
                if (!song.features_ended_at && moment().diff(song.features_started_at, 'minutes') < 2) {
                    this._startPolling()
                } else if (song.is_features_valid && song.status !== "ANALYZED") {
                    this._onReanalyze(song);
                }
            });
        } else {
            clearInterval(this.timer)
        }
        //***************************************************************


        if (user) {
            return (
                <section className="dna-scoring">
                    <DemoNavLinks/>
                    <Row>
                        <Col>
                            <section className="songlist nav-tabs-content">
                                <Row className="mt-3">
                                    <Col className="justify-content-md-center">
                                        <h6>US Airplay 2012-2020</h6>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col className="justify-content-md-center" style={{
                                        'backgroundColor': 'white',
                                        'border': '2px dashed #aaa',
                                        'borderRadius': '20px'
                                    }}>
                                        {user.available_uploads > 0 &&
                                        <DiscoverUploader minSize={256} maxSize={20971520}
                                                          onUploadSuccess={this._onUploadSuccess}/>
                                        }
                                        {user.available_uploads == 0 &&
                                        <h6>You have hit your upload quota. <Link to="/settings/subscriptions">Upgrade
                                            today</Link></h6>
                                        }
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col className="col-12">
                                        <DragDropContext
                                            onBeforeDragStart={this.onBeforeDragStart}
                                            onDragStart={this.onDragStart}
                                            onDragUpdate={this.onDragUpdate}
                                            onDragEnd={this.onDragEnd}>

                                            <Droppable droppableId="droppable-1" type="SONG">
                                                {(provided, snapshot) => (
                                                    <div className="row" ref={provided.innerRef}>
                                                        {user.songs && user.songs.map((song, index) => (
                                                            <Draggable draggableId={song.id} key={song.id} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div className="col-12 mb-3"
                                                                         ref={provided.innerRef}
                                                                         {...provided.draggableProps}
                                                                         {...provided.dragHandleProps}
                                                                    >
                                                                        <DiscoverSongListCard
                                                                            ref={this._setSongContainerRef}
                                                                            song={this._prepareSong(song, user.songs, index)}
                                                                            onShowSimilars={() => this._onShowSimilars(song)}
                                                                            onDelete={() => this._onDelete(song)}
                                                                            onEdit={() => this._onShowEditModal(song)}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Col>
                                </Row>
                                <Row className="mt-3" style={{'minHeight': '500px'}}>
                                    <Col className="justify-content-md-center">
                                    </Col>
                                </Row>
                            </section>
                        </Col>
                    </Row>
                    <DiscoverModal show={this.state.latestAnalysisModal.show}
                                   song={this.state.latestAnalysisModal.song}
                                   onHide={this._onHideSimilars}/>

                    <EditModal show={this.state.editSong.show}
                               song={this.state.editSong.song}
                               onHide={this._onHideEditModal}
                               onSave={this._onLoad}/>
                </section>
            );
        } else {
            return <Loading/>;
        }
    }
}

export default connect(
    (state) => {
        //console.group('MapState :: Discover :: ', state);
        let results;

        if (state.data &&
            state.data instanceof Map &&
            state.data.has('user/discover')) {
            let discoverDashboard = state.data.get('user/discover');

            let isProcessing = discoverDashboard.user.songs.filter((element, index) => {
                if (!element.features_ended_at || (element.is_features_valid && element.status !== "ANALYZED")) {
                    return true;
                }
                return false;
            }).length > 0

            //console.info('MapState :: Discover :: user/dashboard :: ', discoverDashboard);
            results = {result: discoverDashboard, isProcessing: isProcessing};
        } else {
            results = {result: null};
        }
        //console.groupEnd();
        return results;
    },
    (dispatch) => {
        let actions = configureActions(dispatch, _.assign({}, RequestActions, DnDActions));
        return {
            actions: actions
        }
    }
)(Discover);
