import Loading from "common/components/loaders/Loading";
import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from "lodash";
//**********************************************************
import configureActions from 'redux/store/configureActions';
import * as HomeActions from 'redux/store/actions/actions/HomeActions';
import * as LeaderboardActions from 'redux/store/actions/actions/LeaderboardActions';
//**********************************************************

// Import custom components
import TopBanner from 'components/routes/public/home/sections/TopBanner';
import Leaderboard from "components/routes/public/home/sections/Leaderboard";
import SuggestedArtists from 'components/routes/public/home/sections/SuggestedArtists';
import SuggestedSongs from 'components/routes/public/home/sections/SuggestedSongs';
import NewTopSongs from 'components/routes/public/home/sections/NewTopSongs';
import NewTopArtists from 'components/routes/public/home/sections/NewTopArtists';
import NewTopVideos from 'components/routes/public/home/sections/NewTopVideos';


//import ListenOnceMore from './sections/ListenOnceMore';
//import MoreLikedSongs from './sections/MoreLikedSongs';
//import MoreLikedArtists from './sections/MoreLikedArtists';

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isReady: false
        };

        //this.updateSuggestedArtist = React.createRef();
    }

    componentDidMount() {
       //console.log('Home :: componentDidMount', this);
        //this._onLoad();
    }

    componentWillUnmount() {
       //console.log('Home :: componentWillUnmount', this);

        if (this.props.request) {
            this.props.request.cancel();
        }
    }

    _handleIsReady(isReady) {
       //console.log('Home :: _handleIsReady', isReady);
        this.setState({
            isReady: isReady
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
       //console.log('Home :: shouldComponentUpdate', nextProps, nextState);
        return true;
    }

    /*_onLoad() {
       //console.log('Home :: _onLoad', this);
        this.props.actions.fetchAll('home', this._handleIsReady);
    }*/

    render() {
        //console.info('Home :: Render :: ', {props: this.props, state: this.state});
        /*const {isReady} = this.state || {};
        console.info(this.props);
        console.groupEnd();*/

        return (
            <div className="row">
                <div className="col-md-9">
                    <TopBanner/>
                    {/*{!isReady && <Loading/>}*/}
                    {/*{isReady &&*/}
                    <>
                        <SuggestedArtists/>
                        <SuggestedSongs/>
                        {/*<NewTopArtists/>
                        <NewTopSongs/>*/}
                        {/*<NewTopVideos results={results.newTopVideos}/>*/}
                    </>
                    {/*}*/}
                </div>
                <div className="col-md-3">
                    <Leaderboard/>
                </div>
            </div>
        );
    }
}

export default Home;
/*
export default connect(
    null,
    /*(state) => {
        console.group('MapState :: Home :: ', {state: state});

        let results;
        if (state.data &&
            state.data instanceof Map &&
            state.data.has('home/suggestedArtists') &&
            state.data.has('home/suggestedSongs')) {

            results = {isReady: true};
        } else {
            results = {isReady: false};
        }

        console.info(results);
        console.groupEnd();
        return results;
    },*/
   /* (dispatch) => {
        console.group('MapDispatch :: Home');
        let actions = configureActions(dispatch, _.assign({}, HomeActions));
        console.info({actions: actions});
        console.groupEnd();
        return {
            actions: actions
        }
    }
)(Home);*/

