import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';

import {Card} from 'react-bootstrap';
import AxiosService from "services/AxiosService";
import {globalPlayer} from "common/components/player/media-player/AudioPlayer";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";

class DiscoverSongListCard extends React.PureComponent {

    constructor(props) {
        super(props);

        this.axios = new AxiosService();

        this.state = {
            hover: false,
            playing: false,
            song: null
        }
        this._toggleHover = this._toggleHover.bind(this);
        this._togglePlayPause = this._togglePlayPause.bind(this);

        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
    }

    componentWillUnmount() {
        this._onStop = null
    }

    onPlay() {
        this.setState({'playing': true});
    }

    onPause() {
        this.setState({'playing': false});
    }

    _togglePlayPause() {
        this.props.song.playingComponent = () => this;
        globalPlayer.togglePlayPause(this.props.song)
        this.setState({'playing': !this.state.playing})
    }

    _toggleHover() {
        this.setState({'hover': !this.state.hover})
    }


    render() {
        const {song, onShowSimilars, onDelete, onEdit} = this.props;

        //******************************************
        var rowClass = 'songlist-card';
        var playPauseIcon;
        if (this.state.hover) {
            rowClass += ' hover'
        }
        if (this.state.playing) {
            rowClass += ' playing'
            playPauseIcon = 'pause-circle';
        } else {
            playPauseIcon = 'play-circle';
        }
        //******************************************


        let isFeaturing = Boolean(song.features_sent_at && song.features_started_at && !song.features_ended_at && moment().diff(song.features_started_at, 'minutes') < 2);
        let isFeatured = Boolean(song.is_features_valid);
        let isFeaturedError = Boolean(
            (!isFeatured && song.features_ended_at && moment().diff(song.features_started_at, 'minutes') >= 2)
        );

        //*********************
        let isAnalyzed = Boolean(isFeatured && !isFeaturedError && song.status == 'ANALYZED');
        let isAnalyzing = Boolean(isFeatured && !isAnalyzed);

        // FIXME Until we have a failsafe way to manage the song capacity constraints for unpaid users 
        // i.e. a user can not upload more than x number of songs when not subscribed
        // we are disabling the delete feature. Currently it serves as a loophole to allow a user to upload as 
        // many songs as possible without subscribing.

        // let isOnDeleteEnabled = Boolean(onDelete);
        let isOnDeleteEnabled = false; // force disabling the delete button


        return (
            <Card className={rowClass} onMouseEnter={this._toggleHover} onMouseLeave={this._toggleHover}>
                <div className="align-items-center">
                    <span className="float-left song-cover-container">
                        <img className="song-cover-img" src={song.img_link} alt={song.artist + ' - ' + song.title}/>
                        <div className="song-cover-play-overlay">
                            <FontAwesomeIcon className="song-cover-play-icon" icon={playPauseIcon} size="4x"  onClick={this._togglePlayPause} />
                        </div>
                    </span>
                    <span className="float-left mt-1 mx-2" style={{"minWidth": "30%", "maxWidth": "50%"}}>
                        <div className="float-left">
                            <div className="text-left link">{song.artist} - {song.title}</div>
                            <div className="text-left"><small><i>{song.album} {song.genre} {song.year}</i></small></div>
                            <div className="text-left"><small><i>{song.score_version ? 'v' + song.score_version : ''}</i></small></div>
                        </div>
                    </span>
                    <span className="float-right mt-4 mx-2 mb-2">
                        <span className="mx-2"><b>{song.score ? song.score + ' %' : ''}</b></span>
                        {isFeaturedError &&
                        <span className="mx-1"><small>File error, please try re-encoding</small></span>
                        }
                        {isFeaturing &&
                        <span className="mx-1"><Spinner animation="border" variant="dark"
                                                        size="sm"/><small>&nbsp;Fingerprinting...</small></span>
                        }
                        {isAnalyzing &&
                        <span className="mx-1"><Spinner animation="border" variant="dark"
                                                        size="sm"/><small>&nbsp;Analyzing...</small></span>
                        }
                        <button className="btn btn-sm btn-primary mx-1" onClick={onEdit}>
                            <FontAwesomeIcon icon={['fas', 'edit']}/>
                        </button>
                        {isAnalyzed &&
                        <button className="btn btn-sm btn-primary mx-1" onClick={onShowSimilars}
                                title="Get Similars">
                            <FontAwesomeIcon icon={['fas', 'list-ol']}/>
                        </button>
                        }
                        {isOnDeleteEnabled &&
                        <button className="btn btn-sm btn-primary mx-1" onClick={onDelete} title="Delete this Song">
                            <FontAwesomeIcon icon={['fas', 'trash']}/>
                        </button>
                        }
                    </span>
                </div>
            </Card>
        )
    }
}

export default DiscoverSongListCard
