import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return (
            <section className="footer">
                <footer className="container h-100">
                    <div className="row h-100">
                        <div className="col-sm my-auto">
                            <div className="text-center">
                                <strong>Copyright &copy; 2019</strong> All rights reserved. - <b>Version</b> 1.0.1
                            </div>
                        </div>
                    </div>
                </footer>
            </section>
        );
    }
}

export default Footer
