import Loading from "common/components/loaders/Loading";
import DiscoverTableRow from "components/routes/private/demo/discover/DiscoverTableRow";
import React, {Component} from "react";
import {Button, Modal, Table} from "react-bootstrap";
import AxiosService from "services/AxiosService";


class DiscoverModal extends Component {
    constructor(props) {
        super(props);
        //console.log('LatestAnalysisModal :: constructor', this);
        this.state = {
            show: null,
            song: null
        }
        this.axios = new AxiosService();
        this.songContainerRefs = [];

        this._onLoad = this._onLoad.bind(this);
        this._onExited = this._onExited.bind(this);

        this._prepareSong = this._prepareSong.bind(this)
    }

    _onLoad() {
        try {
            //console.log('LatestAnalysisModal :: _onLoad', this.props);

            this.axios.fetch('song/' + this.props.song.id + '/showWithLatestAnalysis', {})
                .then((response) => {
                    //console.log('LatestAnalysisModal :: _onLoad :: ', response);
                    if (response && response.data) {
                        this.setState({
                            song: response.data.song
                        });
                    }
                })
        } catch (err) {
            //console.log('LatestAnalysisModal :: _onLoad :: Error : ', err.message);
        }
    }

    _onExited() {
        this.setState({
            song: null
        });
    }

    _setSongContainerRef = (ref) => {
        this.songContainerRefs.push(ref);
    };

    _prepareSong(song, songs, actualIndex) {
        let nextIndex = actualIndex + 1
        let previousIndex = actualIndex - 1

        if (songs.length <= nextIndex) {
            nextIndex = 0
        }
        let nextSong = songs[nextIndex];

        if (previousIndex < 0) {
            previousIndex = song.length - 1
        }

        let previousSong = songs[previousIndex];

        song.nextSong = null
        if (nextSong) {
            nextSong.playingComponent = () => this.songContainerRefs[nextIndex]
            song.nextSong = nextSong
        }

        song.previousSong = null
        if (previousSong) {
            previousSong.playingComponent = () => this.songContainerRefs[previousIndex]
            song.previousSong = previousSong
        }

        debugger;
        return song;
    }

    render() {
        //console.log('LatestAnalysisModal :: render', this.props, this.state);

        return (
            <>
                <Modal show={this.props.show} onShow={this._onLoad} onHide={this.props.onHide} onExited={this._onExited}
                       size="xl" scrollable="true" className="similars">
                    <Modal.Header closeButton>
                        {this.props.song &&
                        <Modal.Title>Similars for {this.props.song.title}</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        {!this.state.song &&
                        <Loading/>
                        }
                        {this.state.song && this.state.song.latestAnalysis && this.state.song.latestAnalysis.similars &&
                        <Table className="table table-condensed table-hover" responsive="sm" size="sm"
                               style={{'fontSize': '16px'}}>
                            <tbody>
                            {this.state.song.latestAnalysis.similars.map((similar, index) => (
                                <DiscoverTableRow key={index}
                                                  ref={this._setSongContainerRef}
                                                  song={this._prepareSong(similar, this.state.song.latestAnalysis.similars, index)}
                                />
                            ))}
                            </tbody>
                        </Table>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.onHide}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default DiscoverModal
