//libraries
import _ from 'lodash';

// Import constants
import * as ActionType from 'redux/store/actions/ActionType';

let initialState = {
    isRequesting: false,
    numberOfRequests: 0,
    //-------------------------------
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
export default function (state = initialState, action) {
    let newState;
    switch (action.type) {
        case ActionType.API_REQUEST:
           //console.group('ApiReducer :: ' + action.type + ' => numberOfRequests : ' + (state.numberOfRequests + 1));
            newState = _.assign({}, state, {
                isRequesting: true,
                numberOfRequests: state.numberOfRequests + 1
            });
           //console.info({action: action, prevState: state, nextState: newState});
           //console.groupEnd();
            return newState;

        case ActionType.API_RESPONSE:
           //console.group('ApiReducer :: ' + action.type + ' => numberOfRequests : ' + (state.numberOfRequests - 1));
            newState = _.assign({}, state, {
                isRequesting: state.numberOfRequests <= 0,
                numberOfRequests: state.numberOfRequests - 1
            });
           //console.info({action: action, prevState: state, nextState: newState});
           //console.groupEnd();
            return newState;

        default:
            return state;
    }
}
