import React from 'react';
import PropTypes from 'prop-types';
import Env from "configs/Env";

const EnvironmentTag = ({env}) => {
    if (env !== 'local') {
        return (<div className="text-uppercase text-center red font-weight-bold">{env}</div>)
    }
    return (<></>)
};

EnvironmentTag.propTypes = {
    env: PropTypes.oneOf(['local', 'dev', 'demo', 'staging', 'production']),
};

EnvironmentTag.defaultProps = {
    env: Env.APP_ENV.toLowerCase()
}

export default EnvironmentTag;
