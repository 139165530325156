//libraries
import _ from 'lodash';

/**
 * Import all constants as an object.
 */
import * as ActionType from 'redux/store/actions/ActionType';

var initialState = {
    user: null,
    token: null,
    isAuthenticated: false,
    accessControl: null
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
export default function (state = initialState, action) {
    let newState;
    switch (action.type) {
        case ActionType.AUTH_CHECK:
            //console.group('AuthReducer :: ' + action.type + ' => EXECUTING');
            newState = _.assign({}, state, {
                isAuthenticated: 'in-progress',
                token: 'in-progress'
            });
            //console.info({action: action, prevState: state, nextState: newState});
            //console.groupEnd();
            return newState;

        case ActionType.AUTH_SUCCESS:
           //console.group('AuthReducer :: ' + action.type + ' => EXECUTING');
            newState = _.assign({}, state, {
                isAuthenticated: true,
                token: action.token,
                user: action.user,
                accessControl: action.accessControl
            });
           //console.info({action: action, prevState: state, nextState: newState});
           //console.groupEnd();
            return newState;

        case ActionType.AUTH_FAILURE:
            //console.group('AuthReducer :: ' + action.type + ' => EXECUTING');
            newState = _.assign({}, state, {
                isAuthenticated: false,
                token: null,
            });
            //console.info({action: action, prevState: state, nextState: newState});
            //console.groupEnd();
            return newState;

        case ActionType.AUTH_REFRESH:
            //console.group('AuthReducer :: ' + action.type + ' => EXECUTING');
            newState = _.assign({}, state, {
                isAuthenticated: true,
                token: action.token,
            });
            //console.info({action: action, prevState: state, nextState: newState});
            //console.groupEnd();
            return newState;

        case ActionType.AUTH_LOGOUT:
            //console.group('AuthReducer :: ' + action.type + ' => EXECUTING');
            newState = _.assign({}, state, {
                isAuthenticated: false,
                token: null,
                username: null
            });
            //console.info({action: action, prevState: state, nextState: newState});
            //console.groupEnd();
            return newState;

        default:
            return state;
    }
}
