import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';
import moment from 'moment';
import {Card, Button} from 'react-bootstrap';
import {Animated} from "react-animated-css";
import Spinner from 'react-bootstrap/Spinner'
import Content from "common/components/content/content.component";
import {Link} from "react-router-dom";

class FileCard extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            //isCanceled: false,
            file: props.file,
            message: props.message
        };
    }

    onCancel = () => {
        //console.log('onCancel', this.state);

        if (this.state.file) {
            this.state.file.request.cancel("DnaServicesUploads has been cancelled by user.");
            //this.setState({isCanceled: true});
            this.forceUpdate()
        }
    };

    render() {
        //console.log('Render FileCard :: ', this.state, this.state.file.request);


        if (this.state.file && this.state.file.length === 0) {
            return <div></div>;
        } else {
            let isPending = this.state.file.request ? this.state.file.request.isPending : true;
            let isCanceled = this.state.file.request ? this.state.file.request.isCanceled : false;

            return (
                <Card className="file-card mb-3">
                    <Card.Body className="p-2">
                        <div className="card-title" >
                            <div className="row">
                                <div className="col-md-9 text-left">
                                    {isPending && !isCanceled && <Spinner animation="border" variant="dark" size="sm" className="mr-3"/>}
                                    <span>{this.state.file.name}</span>
                                </div>
                                <div className="col-md-3 text-right">
                                    {isCanceled && <span><small>Request cancelled by user.</small></span>}
                                    {!isCanceled && <button className="btn btn-sm btn-secondary" onClick={this.onCancel}>
                                        <FontAwesomeIcon icon={['fas', 'times']}/>
                                    </button>}
                                </div>
                            </div>
                        </div>
                        {this.state.message && <div className="row py-1">
                            <span><small>{this.state.message}</small></span>
                        </div>}
                        <div className="row py-1">
                            <div className="col-md text-left">Size</div>
                            <div className="col-md text-right">{this.state.file.size}</div>
                        </div>
                        <div className="row py-1">
                            <div className="col-md text-left">Type</div>
                            <div className="col-md text-right">{this.state.file.type}</div>
                        </div>
                        <div className="row py-1">
                            <div className="col-md text-left">Date</div>
                            <div className="col-md text-right">{moment(this.state.file.lastModifiedDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                        </div>
                    </Card.Body>
                </Card>

            )
        }
    }
}

export default FileCard
