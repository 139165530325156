import {ActionType, ExceptionActions} from 'redux/store/actions/Actions';
//**********************************************************************************

export function addList(storeKey, list) {
   //console.log('CacheActions :: addList ::', storeKey);

    return (dispatch) => {
        dispatch({
            type: ActionType.CACHE_ADD_LIST,
            key: storeKey,
            value: list
        });
    }
}

export function addEntity(storeKey, entity) {
   //console.log('CacheActions :: addEntity ::', storeKey);

    return (dispatch) => {
        dispatch({
            type: ActionType.CACHE_ADD_LIST,
            key: storeKey,
            value: entity
        });
    }
}


export function loadList(storeKey) {
   //console.log('CacheActions :: loadList ::', storeKey);

    return (dispatch) => {
        dispatch({
            type: ActionType.CACHE_LOAD_LIST,
            key: storeKey
        });
    }
}


export function loadEntity(storeKey) {
   //console.log('CacheActions :: loadEntity ::', storeKey);

    return (dispatch) => {
       //console.log('CacheActions :: dispaching ::', storeKey);
        dispatch({
            type: ActionType.CACHE_LOAD_ENTITY,
            key: storeKey
        });
    }
}
