import PropTypes from "prop-types";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";

const SubmitButton = ({title,submitting}) => {
    return (
        <Button type="submit" className="mx-1" variant='primary'
                disabled={submitting}>{title}
        </Button>
    )
}

SubmitButton.propTypes = {
    title :  PropTypes.string.isRequired,
    submitting: PropTypes.bool.isRequired,
}

SubmitButton.defaultProps = {
    title: 'Submit'
}

export default SubmitButton
