import React, {Component} from 'react'
import {withMediaProps} from 'react-media-player'
import Transition from 'react-motion-ui-pack'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {globalPlayer} from "./AudioPlayer";

class ScaleX extends Component {
    render() {
        return (
            <Transition
                component="g"
                enter={{scaleX: 1}}
                leave={{scaleX: 0}}
            >
                {this.props.children}
            </Transition>
        )
    }
}

class NextSong extends Component {
    _handleNextSong = () => {
        globalPlayer.nextSong();
    }

    render() {
        return (

            <FontAwesomeIcon icon="step-forward" onClick={this._handleNextSong} style={{"cursor": "pointer"}}/>


            /*<svg
                role="button"
                width="28px"
                height="28px"
                viewBox="0 0 28 28"
                className={className}
                onClick={this._handlePlayPause}
            >
                <circle fill="#373D3F" cx="14" cy="14" r="14">
                    <FontAwesomeIcon icon="step-forward"/>
                </circle>

                {/!*<ScaleX>
                    <polygon
                        key="play"
                        fill="#CDD7DB"
                        points="8,0 8,4.8 0,0 0,12 8,7.2 8,12 10,12 10,0"
                        style={{transformOrigin: '100% 50%'}}
                    />
                </ScaleX>*!/}
            </svg>*/
        )
    }
}

export default withMediaProps(NextSong)
