import FileCard from "common/pureComponents/cards/FileCard";
import React, {useMemo, useState} from "react";
import {useDropzone} from "react-dropzone";
import AxiosService from "services/AxiosService";
import { Alert } from "react-bootstrap";

export function DiscoverUploader(props) {
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
        rejectedFiles
    } = useDropzone({
        accept: '.mp3, .mp4, .m4a',
        minSize: props.minSize,
        maxSize: props.maxSize,
        multiple: true,
        onDrop: acceptedFiles => {
            //console.log('acceptedFiles', acceptedFiles);

            setFiles(acceptedFiles.map(file => {
                setError(null)
                const axios = new AxiosService();

                let formData = new FormData();
                formData.append("file", file);

                axios.upload('upload/songs', formData)
                    .then((response) => {
                        if (response && response.data && response.data.results && response.data.results.file && response.data.results.file.song) {
                            //console.log('Uploaders :: onDrop :: response', response.data.results.file.song.title, response);
                            props.onUploadSuccess({
                                song: response.data.results.file.song
                            });
                        }
                    }).catch(err => {
                        err && err.response && err.response.data && err.response.data.errors 
                        && err.response.data.errors.file && setError(err.response.data.errors.file)
                    });
                return Object.assign(file, {request: axios});
            }));
        }
    });

    /*useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        //files.forEach(file => URL.revokeObjectURL(file.preview));
       //console.log('useEffect', files);
    }, [files]);*/


    const acceptedFilesItems = acceptedFiles
        .filter(file => {
            //console.log('acceptedFilesItems :: filter', file, (!file.request || file.request.isPending));
            return !file.request || file.request.isPending || file.request.isCanceled
        }).map(file => {
            //console.log('acceptedFilesItems :: filter', file);
            return (
                <div key={file.name} className="row">
                    <div className="col-md"><FileCard file={file}/></div>
                </div>
            )
        });


    const rejectedFilesItems = rejectedFiles
        .map(file => {
            //console.log('rejectedFilesItems', file, props.minSize, props.maxSize);
            const fileTooSmall = file && file.size < props.minSize ? "File is too small." : "";
            const fileTooLarge = file && file.size > props.maxSize ? "File is too large." : "";
            const fileType = !fileTooSmall && fileTooLarge ? "Invalid file type" : "";

            const message = fileTooSmall + fileTooLarge + fileType;

            return (
                <div className="row">
                    <div className="col-md"><FileCard file={file} message={message}/></div>
                </div>
            )
        });

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '5px 10px',
        margin: '10px 0px',
        borderWidth: '1px',
        borderRadius: '10px',
        borderColor: '#cacaca',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const activeStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject
    ]);

    return (
        <section className="dropzone">
            <div {...getRootProps({className: 'dropzone', style})}>
                <input {...getInputProps()} />
                <div>Drag 'n' drop some files here, or click to select files (max. 5 files [mp3, mp4, m4a])</div>
            </div>
            <section className="container">
                {acceptedFilesItems && acceptedFilesItems.length > 0 &&
                <div>{acceptedFilesItems}</div>
                }
                {acceptedFilesItems && acceptedFilesItems.length > 0 &&
                <div>{rejectedFilesItems}</div>
                }
            </section>
            {error && <Alert variant="danger">
                {error}
            </Alert>}
        </section>
    );
}
