import React, {Component} from "react";
import Loading from "common/components/loaders/Loading";
import RecommendationTableRow from "./RecommendationTableRow";
import {Button, Modal, Table} from "react-bootstrap";
import AxiosService from "services/AxiosService";

class RecommendationModal extends Component {
    constructor(props) {
        super(props);
        //console.log('RecommendationModal :: constructor', this);
        this.state = {
            show: null,
            song: null
        }
        this.axios = new AxiosService();

        this._onLoad = this._onLoad.bind(this);
        this._onExited = this._onExited.bind(this);
    }

    componentWillUnmount() {
        //console.log('RecommendationModal :: componentWillUnmount', this);
        this.axios.cancel();
    }

    _onLoad() {
        try {
            //console.log('RecommendationModal :: _onLoad', this.props);

            this.axios.request.defaults.timeout = 30000; // 30 seconds
            this.axios.post('recommendation/' + this.props.song.id + '/fetch', {shouldRefresh : false})
                .then((response) => {
                    if (response && response.data) {
                        this.setState({
                            song: response.data.song
                        });
                    }
                })
        } catch (err) {
            //console.log('RecommendationModal :: _onLoad :: Error : ', err.message);
        }
    }

    _onExited() {
        this.setState({
            song: null
        });
    }

    render() {
        //console.log('RecommendationModal :: render', this.props, this.state);

        const {song} = this.state;

        return (
            <>
                <Modal show={this.props.show} onShow={this._onLoad} onHide={this.props.onHide} onExited={this._onExited} size="xl" scrollable="true">
                    <Modal.Header closeButton>
                        {this.props.song &&
                        <Modal.Title>Recommendations for {this.props.song.title}</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        {!song &&
                        <Loading/>
                        }
                        {song && song.similars &&
                        <Table className="table table-condensed table-hover" responsive="sm" size="sm" style={{ 'fontSize': '14px'}}>
                            <thead>
                            <tr>
                                <th>Song</th>
                                <th>Genre</th>
                                <th>Year</th>
                                <th>Source</th>
                            </tr>
                            </thead>
                            <tbody>
                            {song.similars.map((similar, index) => (
                                <tr key={index}>
                                    <RecommendationTableRow song={similar}/>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.onHide}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default RecommendationModal;
