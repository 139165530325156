import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EnvironmentTag from "common/func/EnvironmentTag";
import React, {Component} from 'react';
import {connect} from "react-redux";
import {Link, NavLink} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown'

class DefaultHeader extends Component {
    render() {
        return (
            <section className="header fixed-top">
                <header className="main-header">
                    <nav className="navbar navbar-expand-lg m-0 p-0 ">
                        <div className="col-lg-1 d-none d-lg-block">&nbsp;</div>
                        <div className="col-lg-10 col-12">
                            <div className="row">
                                <div className="col-sm-6 col-md-4 col-8 m-0 p-0">
                                    <Link className="navbar-brand" to="home">
                                        <img src="/img/logo-hitlab-community.svg" alt="Hitlab Logo" className="w-100"/>
                                    </Link>
                                </div>
                                <div className="col-sm-6 col-md-8 col-4 float-right text-right">
                                    <button className="navbar-toggler navbar-dark mt-2" type="button" data-toggle="collapse"
                                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                            aria-expanded="false" aria-label="Toggle navigation">
                                        <FontAwesomeIcon icon={['fas', 'bars']}/>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="col-sm navbar-nav mr-auto">
                                            {/* <li className="col-sm nav-item">
                                                <a className="nav-link" href="#">ABOUT US</a>
                                            </li>
                                            <li className="col-sm nav-item">
                                                <a className="nav-link" href="#">BLOG</a>
                                            </li>
                                            <li className="col-sm nav-item">
                                                <a className="nav-link" href="#">CONTESTS</a>
                                            </li>*/}
                                        </ul>
                                        <EnvironmentTag/>
                                        {!this.props.isAuthenticated &&
                                        <div className="mr-3">
                                            <button type="button" className="btn btn-primary btn-block btn-flat">Sign Up</button>
                                        </div>
                                        }
                                        <Dropdown as="ul" className="navbar-nav mr-auto">
                                            <EnvironmentTag/>
                                            <Dropdown.Toggle id="dropdown-basic" as="li" className="nav-item dropdown nav-link">
                                                {!this.props.isAuthenticated && <span>Login</span>}
                                                {this.props.isAuthenticated && <span>Account</span>}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="nav-item">
                                                <Dropdown.Item href="/dashboard">Dashboard</Dropdown.Item>
                                                <Dropdown.Divider/>
                                                <Dropdown.Item href="/logout">Logout</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 d-none d-lg-block">&nbsp;</div>
                    </nav>
                    <nav className="nav-top container-fluid">
                        <div className="row">
                            <div className="col-lg-1 d-none d-lg-block sidebar">&nbsp;</div>
                            <div className="col-lg-10 col-12 container">
                                <div className="row" style={{'borderBottom': '1px solid rgba(0,0,0,.125)'}}>
                                    <div className="col-sm-8 col-md-8 col-lg-9">
                                        <ul className="nav">
                                            <NavLink to={'/home'} activeClassName="active">
                                                <li className="nav-item text-center">
                                                    <span className="nav-link">HOME</span>
                                                </li>
                                            </NavLink>
                                            <NavLink to={'/artists'} activeClassName="active">
                                                <li className="nav-item text-center">
                                                    <span className="nav-link">ARTISTS</span>
                                                </li>
                                            </NavLink>
                                            <NavLink to={'/songs'} activeClassName="active">
                                                <li className="nav-item text-center">
                                                    <span className="nav-link">SONGS</span>
                                                </li>
                                            </NavLink>
                                            <NavLink to={'/videos'} activeClassName="active">
                                                <li className="nav-item text-center">
                                                    <span className="nav-link">VIDEOS</span>
                                                </li>
                                            </NavLink>
                                            <NavLink to={'/fans'} activeClassName="active">
                                                <li className="nav-item text-center">
                                                    <span className="nav-link">FANS</span>
                                                </li>
                                            </NavLink>
                                        </ul>
                                    </div>
                                    <div className="col-sm-4 col-md-4 col-lg-3">
                                        <form className="form-inline search">
                                            <div className="float-left col-10">
                                                <input className="form-control w-100" type="search" placeholder="Search" aria-label="Search"/>
                                            </div>
                                            <div className="float-right col-2">
                                                <button className="btn" type="submit">
                                                    <FontAwesomeIcon icon="search"/>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1 d-none d-lg-block sidebar">&nbsp;</div>
                        </div>
                    </nav>
                </header>
            </section>
        );
    }
}

export default connect(
    (state) => {
        return {isAuthenticated: state.auth.isAuthenticated};
    }
)(DefaultHeader);
