import React, {PureComponent} from 'react';


import SongListCard from "common/pureComponents/cards/songlist/SongListCard";

import AxiosService from "services/AxiosService";


class SongList extends PureComponent {

    constructor(props) {
        super(props);

        //console.log("SongList :: constructor", props);

        this.axios = new AxiosService();
        this.state = {
            isLoading: false,
            results: props.results,
            pagination : null
        };
        //this._onLoadMore = this._onLoadMore.bind(this);
    }


   /* componentDidUpdate(prevProps) {
        //console.log('SongList :: componentDidUpdate', prevProps);

        if(prevProps.results !== this.props.results){
            this.setState({
                results: this.props.results
            });
        }
    }*/

    render() {
        const {results} = this.state;
        //console.log("Render :: SongList ::", results);

        if (results === undefined || !Array.isArray(results) || results.length === 0) {
            return <div>Songlist is empty</div>
        }else{
            return (
                <section className="songlist">
                    {results && results.map((song, index) => (
                        <SongListCard key={song.id} song={song}/>
                    ))}
                </section>
            )
        }
    }
}

export default SongList
