import React from "react";
import Spinner from "react-bootstrap/Spinner";

class Loading extends React.PureComponent {
    render() {
        return (
            <section className="loading">
                <div className="my-3"><Spinner animation="border" variant="dark" size="sm"/><span className="mx-3">Loading ...</span></div>
            </section>
        )
    }
}
export default Loading



