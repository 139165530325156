
import _ from "lodash";
import React, {Component} from 'react';
import PropTypes from 'prop-types'; // ES6
import {connect} from 'react-redux';

import {HomeActions} from "redux/store/actions/Actions";
import configureActions from "redux/store/configureActions";

import Loading from "common/components/loaders/Loading";
import LoadMore from "common/components/loaders/LoadMore";
import SongCard from 'common/pureComponents/cards/song/SongCard';


class SuggestedSongs extends Component {

    constructor(props) {
        super(props);
        this._onLoadMore = this._onLoadMore.bind(this);
    }

    componentDidMount() {
      //console.log('SuggestedSongs :: componentDidMount', this);
        this._onLoad();
    }

    componentWillUnmount() {
      //console.log('SuggestedSongs :: componentWillUnmount', this);
        if (this.props.request) {
            this.props.request.cancel();
        }
    }

    _onLoad() {
       //console.log('SuggestedSongs :: _onLoad', this);

        this.props.actions.fetch('home/suggestedSongs', {
            pageSize: 4,
            page: 1
        });
    }
    _onLoadMore() {
       //console.log('SuggestedSongs :: _onLoadMore', this);
        const {pagination, results} = this.props.results.suggestedSongs;
        this.props.actions.fetchMore('home/suggestedSongs', {
            pageSize: pagination ? pagination.per_page : 4,
            page: pagination ? (pagination.current_page + 1) : 1
        });
    }

    render() {
       //console.group('SuggestedSongs :: Render :: ', {props: this.props, state: this.state});
        const {suggestedSongs} = this.props.results || {};
       //console.info(suggestedSongs);
       //console.groupEnd();

        if (!suggestedSongs || !suggestedSongs.results) {
            return <Loading/>;
        } else {
            return (
                <section className="suggested-songs">
                    <div className="row ">
                        <h5 className="col-md">Suggested Songs</h5>
                    </div>
                    <div className="row ">
                        {suggestedSongs.results && suggestedSongs.results.map((song, index) =>
                            <div key={song.id} className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                <SongCard key={song.id} song={song}/>
                            </div>
                        )}
                    </div>
                    <LoadMore onClick={this._onLoadMore}/>
                </section>
            );
        }
    }
}


export default connect(
    (state) => {
        //console.group('MapState :: SuggestedSongs :: ', state);
        let results;

        if (state.data &&
            state.data instanceof Map &&
            state.data.has('home/suggestedSongs')) {
            let suggestedSongs = state.data.get('home/suggestedSongs');
            //console.info(suggestedSongs);
            results = {results: suggestedSongs};
        } else {
            results = {results: null};
        }
        //console.groupEnd();
        return results;
    },
    (dispatch) => {
       //console.group('MapDispatch :: SuggestedSongs');
        let actions = configureActions(dispatch, _.assign({}, HomeActions));
       //console.info({actions: actions});
       //console.groupEnd();
        return {
            actions: actions
        }
    }
)(SuggestedSongs);
