import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';

import {Card} from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";
import AxiosService from "services/AxiosService";
import {globalPlayer} from "common/components/player/media-player/AudioPlayer";

class DashboardSongListCard extends React.PureComponent {

    constructor(props) {
        super(props);

        this.axios = new AxiosService();
        this.state = {
            song: {}
        };

        this._onReanalyze = this._onReanalyze.bind(this);
    }

    _onReanalyze() {
        //console.log('_onReanalyze');

        try {
            //console.log('Song :: _onReanalyze', this.props);

            //this.setState({isLoading: true});
            this.axios.fetch('song/' + this.props.song.id + '/reanalyze', {})
                .then((response) => {
                    //console.log('Song :: _onReanalyze :: ', response);
                    if (response && response.data) {
                        this.setState({
                            //isLoading: true,
                            song: response.data.result
                        });
                    }
                })
        } catch (err) {
            //console.log('Song :: _onReanalyze :: Error : ', err.message);
            //this.setState({isLoading: false});
        }
    };


    handlePlaySong = () => {
        globalPlayer.enqueueSong(this.props.song)
    };

    render() {

        const {song, onReanalyze} = this.props;

        return (
            <Card className="songlist-card mb-2 p-2">
                <div className="row align-items-center">
                    <div className="col-md-5">
                        <div className="text-left link" onClick={this.handlePlaySong}>
                            <FontAwesomeIcon icon="play-circle" size="sm"/> {song.artist} - {song.title}
                        </div>
                        <div className="text-left"><small><b>{song.genre}</b></small></div>
                    </div>
                    <div className="col-md-2">
                        <div className="text-right"><b>{song.score ? song.score + ' %' : 'N/A'}</b></div>
                    </div>
                    <div className="col-md-2">
                        <div className="text-left"><small>{song.played} plays</small></div>
                    </div>
                    <div className="col-md-2">
                        {song.analysed &&
                        <button className="btn btn-primary">Similars</button>
                        }
                        {!song.analysed &&
                        <div>
                            <Spinner animation="border" variant="dark" size="sm"/><small>&nbsp;Analyzing</small>
                            <button className="btn btn-sm btn-primary" onClick={this._onReanalyze}>Reanalyze</button>
                        </div>
                        }
                    </div>
                    <div className="col-md-1">
                        <FontAwesomeIcon icon={['fas', 'grip-lines']} className="text-secondary icon-full"/>
                    </div>
                </div>
            </Card>
        )
    }
}

export default DashboardSongListCard
