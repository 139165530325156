import {ActionType, ApiActions, ExceptionActions} from 'redux/store/actions/Actions';
//**********************************************************************************
import AxiosService from "services/AxiosService";
import {history} from "redux/store/configureStore";
import _ from "lodash";
//**********************************************************************************


//******************************************************************************************************
// Thunks
//******************************************************************************************************
export function fetchAll(apiPath, handleIsReady) {

    let apiPathSuffix = 'home/';//TODO: MAKE THIS LOOKING BETTER

    return (dispatch, getState) => {
       //console.log("************************************************************");
        let params = {
            pageSize: 4,
            page: 1
        };//FIXME
        //--------------------------------------------------------
        //Check in cache, then call API
        let cacheKey = apiPath + '_' + JSON.stringify(params);
        let cacheValue = getState().cache.lists.get(cacheKey);
        //--------------------------------------------------------
       //console.group('HomeActions :: fetching all ::', (cacheValue ? 'FROM CACHE' : 'FROM SERVER'));
       //console.info({apiPath: apiPath, prevState: getState(), cacheKey: cacheKey, cacheValue: cacheValue});
       //console.groupEnd();


        if (cacheValue) {
            dispatch({
                type: ActionType.LOAD_RESULTS_FROM_CACHE,
                payload: cacheValue
            });
        } else {
            const axiosService = new AxiosService();
            dispatch(ApiActions.apiRequest(axiosService, 'HomeActions :: fetch :: ' + apiPath));//start-request

            axiosService.fetch(apiPath)
                .then((response) => {
                    dispatch(ApiActions.apiResponse(response));//end-request

                   //console.log('HomeActions :: fetchAll :: ', response);
                    if (response && response.data) {
                        /*let payload = _.assign({}, {
                            apiPath: apiPathSuffix + apiPath,
                            params: params,
                        }, response.data);
                        //!***********************************************************
                        //COULD BE AVOIDED
                        dispatch({
                            type: ActionType.LOAD_RESULTS,
                            payload: payload
                        });*/
                        //!***********************************************************
                        _.forIn(response.data.results, (element, index) => {
                            let elementPayload = _.assign({}, {
                                apiPath: apiPathSuffix + index,
                                params: params
                            });
                            elementPayload.results = {};
                            elementPayload.results[index] = response.data.results[index];
                            //------------------------------------------------------------------------------

                            dispatch({
                                type: ActionType.LOAD_LIST,
                                payload: elementPayload
                            });
                        });
                        //***********************************************************
                        _.forIn(response.data.results, (element, index) => {
                            let elementCacheKey = index + '_' + JSON.stringify(params);
                            let elementPayload = _.assign({}, {
                                apiPath: apiPathSuffix + index,
                                params: params
                            });
                            elementPayload.results = {};
                            elementPayload.results[index] = response.data.results[index];
                            //------------------------------------------------------------------------------

                            dispatch({
                                type: ActionType.CACHE_ADD_LIST,
                                payload: {
                                    key: elementCacheKey,
                                    value: elementPayload
                                }
                            });
                        });
                        //***********************************************************
                    } else {
                        ExceptionActions.errorHandler(dispatch, response, ActionType.EXCEPTION);
                    }
                    handleIsReady(true);
                })
                .catch((error) => {
                    ExceptionActions.errorHandler(dispatch, error.response, ActionType.FAILURE);
                });
        }
    };
}

export function fetchMore(apiPath, params) {
    return (dispatch, getState) => {
        //--------------------------------------------------------
        //Check in cache, then call API
        let cacheKey = apiPath + '_' + JSON.stringify(params);
        let cacheValue = getState().cache.lists.get(cacheKey);
        //--------------------------------------------------------
       //console.group('HomeActions :: fetching more ::', (cacheValue ? 'FROM CACHE' : 'FROM SERVER'));
       //console.info({apiPath: apiPath, params: params, prevState: getState(), cacheKey: cacheKey, cacheValue: cacheValue});
       //console.groupEnd();

        if (cacheValue) {
            dispatch({
                type: ActionType.LOAD_RESULTS_FROM_CACHE,
                payload: cacheValue
            });
        } else {
            const axiosService = new AxiosService();
            dispatch(ApiActions.apiRequest(axiosService, 'HomeActions :: fetch :: ' + apiPath));//start-request

            axiosService.fetch(apiPath, params)
                .then((response) => {
                    dispatch(ApiActions.apiResponse(response));//end-request

                   //console.log('HomeActions :: fetch :: ', response);
                    if (response && response.data) {
                        let payload = _.assign({}, {
                            apiPath: apiPath,
                            params: params,
                        }, response.data);
                        //***********************************************************
                        dispatch({
                            type: ActionType.DATA_APPEND_RESULTS,
                            payload: payload
                        });
                        //***********************************************************
                        dispatch({
                            type: ActionType.CACHE_ADD_LIST,
                            payload: {
                                key: cacheKey,
                                value: payload
                            }
                        });
                    } else {
                        ExceptionActions.errorHandler(dispatch, response, ActionType.INVALID_RESPONSE_FORMAT);
                    }
                })
                .catch((error) => {
                    ExceptionActions.errorHandler(dispatch, error.response, ActionType.FAILURE);
                });
        }
    };
}

export function fetch(apiPath, params, appendData = false) {
    return (dispatch, getState) => {
        //--------------------------------------------------------
        //Check in cache, then call API
        let cacheKey = apiPath + '_' + JSON.stringify(params);
        let cacheValue = getState().cache.lists.get(cacheKey);
        //--------------------------------------------------------
        //console.group('HomeActions :: fetching ::', (cacheValue ? 'FROM CACHE' : 'FROM SERVER'));
        //console.info({apiPath: apiPath, params: params, prevState: getState(), cacheKey: cacheKey, cacheValue: cacheValue});
        //console.groupEnd();

        if (cacheValue) {
            dispatch({
                type: ActionType.LOAD_RESULTS_FROM_CACHE,
                payload: cacheValue
            });
        } else {
            const axiosService = new AxiosService();
            dispatch(ApiActions.apiRequest(axiosService, 'HomeActions :: fetch :: ' + apiPath));//start-request

            axiosService.fetch(apiPath, params)
                .then((response) => {
                    dispatch(ApiActions.apiResponse(response));//end-request

                    //console.log('HomeActions :: fetch :: ', response);
                    if (response && response.data) {
                        let payload = _.assign({}, {
                            apiPath: apiPath,
                            params: params,
                            //request: axiosService
                        }, response.data);
                        //***********************************************************
                        dispatch({
                            type: ActionType.LOAD_RESULTS,
                            payload: payload
                            /*apiPath: apiPath,
                            params: params,
                            data: response.data.results,
                            pagination: response.data.pagination,
                            request: axiosService,*/
                            //getState: getState()
                        });
                        //***********************************************************
                        dispatch({
                            type: ActionType.CACHE_ADD_LIST,
                            payload: {
                                key: cacheKey,
                                value: payload
                            }
                        });
                    } else {
                        ExceptionActions.errorHandler(dispatch, response, ActionType.INVALID_RESPONSE_FORMAT);
                    }
                })
                .catch((error) => {
                    ExceptionActions.errorHandler(dispatch, error.response, ActionType.FAILURE);
                });
        }
    };

}


