import React, {Component, lazy, Suspense} from 'react';
import PropTypes from 'prop-types'; // ES6
import {connect} from 'react-redux';

import axios from 'axios';
import cookie from 'react-cookie';

import Loading from "common/components/loaders/Loading";
import ArtistCard from 'common/pureComponents/cards/artist/ArtistCard';

class FilteredSongs extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        //console.lgroup('FilteredArtists :: Render :: ', {props: this.props, state: this.state});
        const {results, pagination} = this.props || {};
        //console.linfo(results, pagination);
        //console.lgroupEnd();

        //const {results, pagination} = this.props;
        //console.log('render :: FilteredArtists',results, pagination);

        return (
            <section className="songs">
                <div className="row my-3 ml-3">
                    {!pagination &&
                    <Loading/>
                    }
                    {pagination && pagination.total > 0 &&
                    <h6 className="col-md">{pagination.total} result(s)</h6>
                    }
                </div>
                <div className="row my-3">
                    {results && results.map((artist, index) =>
                        <div key={artist.id} className="col-md-3">
                            <Suspense fallback={<Loading/>}>
                                <ArtistCard key={artist.id} artist={artist}/>
                            </Suspense>
                        </div>
                    )}
                </div>
            </section>
        );
    }
}
export default FilteredSongs
