import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from 'react';

import {Card, Button} from 'react-bootstrap';
import {Animated} from "react-animated-css";
import Content from "common/components/content/content.component";
import {Link, NavLink} from "react-router-dom";
import AudioPlayer, {globalPlayer} from "common/components/player/media-player/AudioPlayer";

class LeaderboardSongCard extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            song: {}
        };
    }

    handlePlaySong = () => {
        globalPlayer.enqueueSong(this.props.song)
    };

    render() {
        const {position, song} = this.props;
        //console.log(song);

        return (
            <Card className="leaderboard-song-card mb-3 p-2">
                <div className="row">
                    <div className="col-sm-12 leaderboard-song-card-img">
                        <img src={song.user.img_link} alt={song.user.username} className="rounded w-100"/>
                    </div>
                </div>
                <div className="row">
                        <div className="col-sm-4 leaderboard-song-card-position h4">
                            {position}
                        </div>
                        <div className="col-sm-8 leaderboard-song-card-score text-right h5">
                            {song.score}%
                        </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 leaderboard-song-card-details">
                        <div className="pl-2 text-left">
                            <div className="clearfix h6">
                                <div className="link" onClick={this.handlePlaySong}>
                                    <div className="float-left"
                                         style={{'overflow': 'hidden', 'textOverflow': 'ellipsis', 'whiteSpace': 'nowrap', 'width': '160px'}}>
                                        <FontAwesomeIcon icon="play-circle" size="sm"/> {song.title}
                                    </div>

                                </div>
                            </div>
                            <div className="clearfix  py-1" style={{'borderTop': '2px solid #c61822', 'clear': 'both'}}>
                                <NavLink to={'/artist/' + song.user.id} activeClassName="active" className=" link">
                                    {song.user.username}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }
}

export default LeaderboardSongCard
