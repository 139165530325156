import VideoCard from "common/pureComponents/cards/VideoCard";
import LoadMore from "common/components/loaders/LoadMore";
import React, {PureComponent, Suspense} from 'react';

import AxiosService from "services/AxiosService";

import NavBreadcrumb from 'common/components/nav/NavBreadcrumb';
import ArtistProfile from 'components/routes/public/profile/ArtistProfile';


class Artist extends PureComponent {

    constructor(props) {
        super(props);

        this.axios = new AxiosService();
        this.state = {
            isLoading: false,
            artist: {
                id: null,
                username: null
            }
        };
        this._onLoad = this._onLoad.bind(this);
    }

    componentDidMount() {
       //console.log('Artist :: componentDidMount', this);
        this._onLoad();
    }

    componentWillUnmount() {
       //console.log('Artist :: componentWillUnmount', this);
        this.axios.cancel();
    }

    _onLoad = async () => {
        try {
          //console.log('Artist :: _onLoad', this.props);

            this.setState({isLoading: true});
            this.axios.fetch('artist/' + this.props.match.params.id, {})
                .then((response) => {
                   //console.log('Artist :: _onLoad :: ', response);
                    if (response && response.data) {
                        this.setState({
                            isLoading: true,
                            artist: response.data.result
                        });
                    }
                })
        } catch (err) {
           //console.log('Artist :: _onLoad :: Error : ', err.message);
            this.setState({isLoading: false});
        }
    };

    render() {
       //console.log('Render Artist :: ', this.props, this.state);

        if (!this.state.artist.id) {
           //console.log('renderIf :: Artist :: Not loaded yet');
            return <div></div>;
        } else {
          //console.log('renderIf :: Render Artist :: ', this.state.artist);
            return (
                <>
                    <NavBreadcrumb artist={this.state.artist}/>
                    <ArtistProfile artist={this.state.artist} onRefresh={this._onRefresh}/> {/* TODO : REFACTOR IN ARTIST PROFILE... BIO IS INTHERE */}
                </>
            );
        }
    }
}

export default Artist
