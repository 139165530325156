import React, {Component} from 'react';

import {Card, Form, Col, Button} from 'react-bootstrap';


class FiltersSongs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {onSubmit, onChange, formRef} = this.props;

        return (
            <section className="filters">
                <div className="row my-3">
                    <div className="col-md-12">
                        <Card>
                            <Card.Body className="py-2">
                                <Form onSubmit={onSubmit} ref={formRef}  >
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridGenre">
                                            <Form.Label>Filter By Genre</Form.Label>
                                            <Form.Control as="select" name="filterByGenre" onChange={onChange}>
                                                <option value="hot">Hot100</option>
                                                <option value="hop">HipHop / R&B</option>
                                                <option value="pop">Pop</option>
                                                <option value="rok">Rock</option>
                                                <option value="cty">Country</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridSort">
                                            <Form.Label>Sort By</Form.Label>
                                            <Form.Control as="select" name="sortBy" onChange={onChange}>
                                                <option value="top-dna">Top DNA</option>
                                                <option value="popularity">Popularity</option>
                                                <option value="most-recent">Most Recent</option>
                                                <option value="alphabetically">Alphabetically</option>
                                            </Form.Control>
                                        </Form.Group>
                                        {/*<Form.Group as={Col} controlId="formGridSort">
                                            <Form.Label>&nbsp;</Form.Label>
                                            <div>
                                                <Button variant="primary" type="submit" className="align-bottom">
                                                    Submit
                                                </Button>
                                            </div>
                                        </Form.Group>*/}
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </section>
        );
    }
}

export default FiltersSongs;
