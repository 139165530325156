import {ActionType} from 'redux/store/actions/Actions';
//**********************************************************************************

export function enqueueSong(song) {
    return (dispatch) => {
       //console.log('PlayerActions :: enqueueSong ::', song);
        return dispatch({
            type: ActionType.ENQUEUE_SONG,
            payload: {
                song: song
            }
        });
    };
}
