import React, {PureComponent, useMemo, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import DashboardSongListCard from "common/pureComponents/cards/songlist/DashboardSongListCard";
import FileCard from 'common/pureComponents/cards/FileCard';
import {useDropzone} from 'react-dropzone'
import AxiosRequest from "services/AxiosService";


function Uploader(props) {
    const [files, setFiles] = useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
        rejectedFiles
    } = useDropzone({
        accept: 'audio/mp3, audio/m4a',
        minSize: props.minSize,
        maxSize: props.maxSize,
        multiple: true,
        onDrop: acceptedFiles => {
            //console.log('acceptedFiles', acceptedFiles);

            setFiles(acceptedFiles.map(file => {
                const axios = new AxiosRequest();

                let formData = new FormData();
                formData.append("file", file);

                axios.upload('upload/songs', formData)
                    .then((response) => {
                        if (response && response.data && response.data.results && response.data.results.file && response.data.results.file.song) {
                            //console.log('Uploader :: onDrop :: response', response.data.results.file.song.title, response);
                            props.onUploadSuccess({
                                song: response.data.results.file.song
                            });
                        }
                    });
                return Object.assign(file, {request: axios});
            }));

            //console.log('Uploader :: onDrop :: ', files);

            /* axios.all(uploaders).then(() => {
                //console.log('Dropzone :: onDrop :: all uploaders are successful');
             });*/
        }
    });

    /*useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        //files.forEach(file => URL.revokeObjectURL(file.preview));
       //console.log('useEffect', files);
    }, [files]);*/


    const acceptedFilesItems = acceptedFiles
        .filter(file => {
            //console.log('acceptedFilesItems :: filter', file, (!file.request || file.request.isPending));
            return !file.request || file.request.isPending || file.request.isCanceled
        }).map(file => {
            //console.log('acceptedFilesItems :: filter', file);
            return (
                <div key={file.name} className="row">
                    <div className="col-md"><FileCard file={file}/></div>
                </div>
            )
        });


    const rejectedFilesItems = rejectedFiles
        .map(file => {
            //console.log('rejectedFilesItems', file, props.minSize, props.maxSize);
            const fileTooSmall = file && file.size < props.minSize ? "File is too small." : "";
            const fileTooLarge = file && file.size > props.maxSize ? "File is too large." : "";
            const fileType = !fileTooSmall && fileTooLarge ? "Invalid file type" : "";

            const message = fileTooSmall + fileTooLarge + fileType;

            return (
                <div className="row">
                    <div className="col-md"><FileCard file={file} message={message}/></div>
                </div>
            )
        });

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        margin: '15px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const activeStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject
    ]);

    //console.log("Uploader :: return");
    return (
        <section className="container">
            <div {...getRootProps({className: 'dropzone', style})}>
                <input {...getInputProps()} />
                <div>Drag 'n' drop some files here, or click to select files</div>
            </div>
            <section className="container">
                {acceptedFilesItems && acceptedFilesItems.length > 0 &&
                <div>{acceptedFilesItems}</div>
                }
                {acceptedFilesItems && acceptedFilesItems.length > 0 &&
                <div>{rejectedFilesItems}</div>
                }
            </section>
        </section>
    );
}


class ArtistSongList extends PureComponent {

    constructor(props) {
        super(props);

        //console.log("SongList :: constructor", props);

        this.axios = new AxiosRequest();
        this.state = {
            isLoading: false,
            results: props.results,
            pagination : null
        };
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
    }

    reorder = (list, startIndex, endIndex) => {
        const reordering = Array.from(list);
        const [removed] = reordering.splice(startIndex, 1);
        reordering.splice(endIndex, 0, removed);
        return reordering;
    };

    onBeforeDragStart = () => {
        /*...*/
    };

    onDragStart = () => {
        /*...*/
    };
    onDragUpdate = () => {
        /*...*/
    };
    onDragEnd = (draggedSong) => {
        const {source, destination} = draggedSong;

        // dropped outside the list
        if (!destination) {
            return;
        }

        //console.log("SongList :: onDragEnd :: ", draggedSong.source.index, draggedSong.destination.index);

        const songsReordered = this.reorder(
            this.state.results,
            draggedSong.source.index,
            draggedSong.destination.index
        );

        this.setState({
            results: songsReordered
        });
    };

    componentDidUpdate(prevProps) {
        //console.log('SongList :: componentDidUpdate', prevProps);
        if (prevProps.results !== this.props.results) {
            this.setState({
                results: this.props.results
            });
        }
    }

    onUploadSuccess(data) {
       //console.log('onUploadSuccess', this.state, data);

        this.setState({
            draggedSong: this.state.results.unshift(data.song)
        });
    }

    render() {
        const {results} = this.state;
       //console.log("Render :: SongList ::", results);

        //if (results == undefined || !Array.isArray(results) || results.length === 0) {
        //    return <div>Songlist is empty</div>
        //} else {
            return (
                <section className="songlist">
                    <div className="mb-3" style={{'backgroundColor': 'white', 'border': '2px dashed #aaa', 'borderRadius': '20px'}}>
                        <Uploader minSize={256} maxSize={10485760} onUploadSuccess={this.onUploadSuccess}/>
                    </div>
                    <div className="">
                        <DragDropContext
                            onBeforeDragStart={this.onBeforeDragStart}
                            onDragStart={this.onDragStart}
                            onDragUpdate={this.onDragUpdate}
                            onDragEnd={this.onDragEnd}>

                            <Droppable droppableId="droppable-1" type="SONG">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef}>
                                        {results && results.map((song, index) => (
                                            <Draggable draggableId={song.id} key={song.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <DashboardSongListCard song={song}/>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </section>
            )
        //}
    }
}

export default ArtistSongList
