import React, { Component } from "react";
import { purchaseSubscription } from "../../../../services/PaymentService";
import AxiosService from "../../../../services/AxiosService";
import { connect } from "react-redux";
import _ from "lodash";
import configureActions from "redux/store/configureActions";
import { DnDActions, RequestActions } from "redux/store/actions/Actions";
import Loading from "common/components/loaders/Loading";

class Subscriptions extends Component {
    state = {
        subscription: null,
        user: null,
        isLoading: true,
    };

    constructor(props) {
        super(props);

        this.PaymentContainer.bind(this);
        this.SubscriptionExists.bind(this);
    }

    componentDidMount = () => {
        console.log("Discover :: componentDidMount", this);
        this._onLoad();
    };

    UNSAFE_componentWillReceiveProps = (props) => {
        console.log(props);

            props.user &&
            this.setState({
                subscription: props.subscription,
                user: props.user,
                isLoading: props.isLoading,
            });
    };

    _onLoad() {
        this.props.actions.load("user/discover", {
            pageSize: 16,
            page: 1,
        });
    }

    onPurchaseClick = async (email, referenceId, subscriptionType) => {
        this.loading();
        await purchaseSubscription(email, referenceId, subscriptionType);
        this.loaded();
    };

    onCancelSubscription = (e) => {
        e.preventDefault();
        this.loading();
        const axiosService = new AxiosService();
        axiosService.post("user/subscription?action=cancel", {}).then(() => {
            this._onLoad();
        });
    };

    onReactivateSubscription = (e) => {
        e.preventDefault();
        this.loading();
        const axiosService = new AxiosService();
        axiosService.post("user/subscription?action=reactivate", {}).then(() => {
            this._onLoad();
        });
    };

    PaymentOptions = (user) => (
        <React.Fragment>
            <div className="col-lg-4">
                <div className="card mb-5 mb-lg-0">
                    <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center">Monthly</h5>
                        <h6 className="card-price text-center">
                            $9.99
                            <span className="period">/month</span>
                        </h6>
                        <a className="btn btn-block btn-primary text-uppercase" onClick={() => this.onPurchaseClick(user.email, user.id.toString(), "monthly")}>
                            Sign Up
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title text-muted text-uppercase text-center">Yearly</h5>
                        <h6 className="card-price text-center">
                            $79.99
                            <span className="period">/Year</span>
                        </h6>
                        <a className="btn btn-block btn-primary text-uppercase" onClick={() => this.onPurchaseClick(user.email, user.id.toString(), "yearly")}>
                            Sign Up
                        </a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

    PaymentContainer = () => {
        const { user } = this.state;

        return (
            <section className="pricing py-5">
                <div className="container">
                    <div className="row">{this.PaymentOptions(user)}</div>
                </div>
            </section>
        );
    };

    CancelledSubscription = (endsOn) => (
        <section className="pricing py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-16">
                        <h4>Your subscription will end on : {endsOn.toDateString()}</h4>
                        <a className="btn btn-block btn-primary text-uppercase" onClick={this.onReactivateSubscription}>
                            Reactivate Subscription
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );

    RenewableSubscription = (renewsOn) => (
        <section className="pricing py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-16">
                        <h4>Thanks for subscribing. Your subscription renews on: {renewsOn.toDateString()}</h4>

                        <a className="btn btn-block btn-primary text-uppercase" onClick={this.onCancelSubscription}>
                            Cancel Subscription
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );

    SubscriptionExists = () => {
        const { subscription } = this.state;

        const renewsOn = new Date(subscription.renews_on);
        const endsOn = new Date(subscription.ends_on);
        const isCancelled = subscription.cancelled;

        return !isCancelled ? this.RenewableSubscription(renewsOn) : this.CancelledSubscription(endsOn);
    };

    loaded() {
        this.setState({
            isLoading: false,
        });
    }

    loading() {
        this.setState({
            isLoading: true,
        });
    }

    render() {
        const { subscription, isLoading } = this.state;

        return isLoading ? <Loading /> : subscription ? this.SubscriptionExists() : this.PaymentContainer();
    }
}

export default connect(
    (state) => {
        let results;

        if (state.data && state.data instanceof Map && state.data.has("user/discover")) {
            const data = state.data.get("user/discover");
            results = { user: data.user, subscription: data.user.subscription, isLoading: false };
        } else {
            results = { user: null, subscription: null, isLoading: true };
        }
        //console.groupEnd();
        return results;
    },
    (dispatch) => {
        let actions = configureActions(dispatch, _.assign({}, RequestActions, DnDActions));
        return {
            actions: actions,
        };
    }
)(Subscriptions);
