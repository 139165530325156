import React from 'react';

import {Card, Button} from 'react-bootstrap';
import {Animated} from "react-animated-css";
import Content from "common/components/content/content.component";

class CallToActionCard extends React.PureComponent {
    render() {
        const {cta} = this.props;

        return (
            <>
                {cta.section && <div className="h5">{cta.section}</div>}
                <Card className="call-to-action-card mb-2">
                    <div className="row">
                        <div className="col-xs col-md-3">
                            <div className="card-img">
                            <Card.Img variant="primary" src={cta.img} alt={cta.title}/>
                            </div>
                        </div>
                        <div className="col-xs col-md-9">
                            <div className="card-block pr-3">
                                <div className="card-title h5">
                                    <div className="row">
                                        <div className="col-md text-left">{cta.title}</div>
                                        {cta.location && <div className="col-md text-right">{cta.location}</div>}
                                    </div>
                                </div>
                                <p className="card-text my-1">
                                    {cta.text}
                                </p>
                                <div className="row">
                                    <div className="col-md my-2">
                                        <a href={cta.link.href} target={cta.link.target} className="btn btn-primary" variant="primary">{cta.link.title}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </>
        )
    }
}

export default CallToActionCard
