import React, { Component } from "react";
import { Button, Modal, Table, InputGroup, FormControl, Form, Spinner } from "react-bootstrap";
import AxiosService from "services/AxiosService";

class EditModal extends Component {
    constructor(props) {
        super(props);
        //console.log('LatestAnalysisModal :: constructor', this);
        this.state = {
            show: null,
            artist: null,
            album: null,
            title: null,
            id: null,
            loaded: false,
            message: null,
            coverart: null,
            errors: {},
            saving: false,
        };
        this.axios = new AxiosService();
        this._onExited = this._onExited.bind(this);
    }

    _onExited() {
        this.setState({
            song: null,
        });
    }

    componentWillReceiveProps = (props) => {
        const { song } = props;

        song &&
            this.setState({
                album: song.album,
                artist: song.artist,
                title: song.title,
                id: song.id,
            });
    };

    _onChange = (name, value) => {
        this.setState({
            [name]: value,
        });
    };

    _onSave = () => {
        const { album, artist, title, id, coverart } = this.state;
        const { onSave, onHide } = this.props;
        this.setState({
            saving: true,
        });

        this.axios
            .patch("/songs/" + id, {
                data: {
                    type: "song",
                    id: id,
                    attributes: {
                        album: album,
                        artist: artist,
                        title: title,
                    },
                },
            })
            .then((song) => {
                if (coverart) {
                    let formdata = new FormData();
                    formdata.append("coverart", coverart, coverart.name);

                    this.axios
                        .post("/songs/" + id + "/coverart", formdata)
                        .then((r) => {
                            onSave();
                            onHide();
                            this.setState({
                                saving: false,
                            });
                        })
                        .catch((e) => {
                            let coverartError = "Something went wrong. Try again.";

                            if (e && e.response && e.response.data && e.response.data.errors && e.response.data.errors.coverart) {
                                coverartError = e.response.data.errors.coverart.reduce((accumulator, currentValue) => accumulator + ". " + currentValue);
                            }

                            this.setState({
                                errors: {
                                    coverart: coverartError,
                                },
                                saving: false,
                            });
                        });
                } else {
                    this.setState({
                        saving: false,
                    });

                    onSave();
                    onHide();
                }
            });
    };
    render() {
        const { album, artist, title, message, errors, saving } = this.state;
        const coverArtError = "coverart" in errors ? errors["coverart"] : null;

        return (
            <>
                <Modal show={this.props.show} onHide={this.props.onHide} onExited={this._onExited} size="xl" scrollable="true">
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Song</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Artist Name</InputGroup.Text>
                            <FormControl
                                placeholder="Artist"
                                aria-label="Artist"
                                aria-describedby="basic-addon1"
                                value={artist}
                                onChange={(e) => this._onChange("artist", e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Album</InputGroup.Text>
                            <FormControl
                                placeholder="Album"
                                aria-label="Album"
                                aria-describedby="basic-addon1"
                                value={album}
                                onChange={(e) => this._onChange("album", e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Title</InputGroup.Text>
                            <FormControl
                                placeholder="Title"
                                aria-label="Title"
                                aria-describedby="basic-addon1"
                                value={title}
                                onChange={(e) => this._onChange("title", e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <Form.File
                                id="coverart"
                                label="Upload Cover Art"
                                onChange={(e) => {
                                    this._onChange("coverart", e.target.files[0]);
                                    this.setState({
                                        errors: {},
                                    });
                                }}
                                isInvalid={!!coverArtError}
                                feedback={coverArtError}
                            />
                        </InputGroup>
                        <Button variant="primary" type="submit" onClick={this._onSave} disabled={saving}>
                            {(saving && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />) || "Save"}
                        </Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.onHide} disabled={saving}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default EditModal;
