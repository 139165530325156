import React, {Component} from 'react';
import CallToActionCard from "common/pureComponents/cards/call-to-action.card.component";
import {Button, Card} from "react-bootstrap";


class TopBanner extends Component {


    render() {
        const cta1 = {
            type: 'BANNER',
            img: '/img/logo-hitlab-250x250.png',
            title : "Summer Hitlab Emerging Artists Competition",
            text : "EAC is Hitlab's answer to the growing demand and popularity of televised signing competitions. What makes EAC unique is the fact that we bring the talent competition directly to the consumer via their mobile device, anywhere anytime.",
            link:{
                title : "Contest Details",
                href : "//www.hitlab.com/fanlab-1",
                target : '_blank'
            }
        };

        const cta2 = {
            type: 'WINNER',
            section : "Grammy Contest Winner",
            img: '/img/cta-winner-jade-novah.jpg',
            title :  "Jade Novah",
            location :  "Philadelphia, USA",
            text : "HITLAB powered a Grammy Emerging Artist Competition in partnership with AKON, where the winners performed at official GRAMMY event, walked the red carpet, and attended official after parties. The winner of that contest got signed to Universal.",
            link:{
                title : "Contest Details",
                href : "//contests.hitlab.com/winner",
                target : '_blank'
            }
        };


        return (
            <section className="top-banner">
                <div className="row">
                    <div className="col-md">
                        <CallToActionCard cta={cta1}/>
                        <CallToActionCard cta={cta2}/>
                    </div>
                </div>
            </section>
        );
    }
}

export default TopBanner
