import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";

const BackToLogin = () => (
    <NavLink to={'/login'} activeClassName="active" className="link">
        <FontAwesomeIcon icon={['fas', 'chevron-left']}/> Back to Login
    </NavLink>
)

export default BackToLogin
