import React, {PureComponent} from 'react';
// Import custom components
import DemoHeader from 'common/components/header/DemoHeader';
import SidebarLeft from 'common/func/sidebars/SidebarLeft';
import SidebarRight from 'common/func/sidebars/SidebarRight';
import ContentCompact from "../../common/components/content/content.compact.component";
import Footer from 'common/components/footer/Footer';

export default function (ChildComponent) {
    return class DiscoverLayout extends PureComponent {
        render() {
            return (
                <>
                    <DemoHeader/>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <SidebarLeft/>
                                <ContentCompact>
                                    <ChildComponent {...this.props} />
                                </ContentCompact>
                                <SidebarRight/>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </>
            );
        }
    }
}
