import React, { Component } from 'react';


class SingleClickButton extends Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: props.disabled
        };
    }


    static getDerivedStateFromProps(props, state) {
       //console.log('SingleClickButton :: componentDidUpdate', props, state);
        if (props.disabled !== state.items.length) {
            return {
                items: props.items
            };
        }
        return null;
    }

    _onClick() {
        //let handler = this.props.handler();

        // If promise is returned disable button
        //if (handler && Promise.resolve(handler)) {
        /*    // Disable button
        if(this.props.disabled){
            this.setState({disabled: true});
        }*/
            this.setState({disabled: true});
            // Un-disable button after promise has resolved or rejected
/*            return handler.then(() => {
                return this.setState({disabled: false});
            }).catch(() => {
                return this.setState({disabled: false});
            });*/
        //}
    }

    attrs() {
        return {
            className : this.props.className,
            //children : this.props.name,
            type     : 'button',
            role     : 'button',
            onClick  : this._onClick.bind(this),
            disabled : this.state.disabled
        };
    }

    render() {
       //console.log('SingleClickButton :: render', this.attrs());

        return <button {...this.attrs()}>{this.props.children}</button>;
    }
}

SingleClickButton.defaultProps = {
    handler: () => {}
};

export default SingleClickButton;
