
import _ from "lodash";
import React, {Component} from 'react';
import PropTypes from 'prop-types'; // ES6
import {connect} from 'react-redux';

import {HomeActions} from "redux/store/actions/Actions";
import configureActions from "redux/store/configureActions";

import Loading from "common/components/loaders/Loading";
import LoadMore from "common/components/loaders/LoadMore";
import SongCard from 'common/pureComponents/cards/song/SongCard';


class NewTopSongs extends Component {

    constructor(props) {
        super(props);
        this._onLoad = this._onLoad.bind(this);
        this._onLoadMore = this._onLoadMore.bind(this);
    }

    componentDidMount() {
       //console.log('NewTopSongs :: componentDidMount', this);
        this._onLoad();
    }

    componentWillUnmount() {
       //console.log('NewTopSongs :: componentWillUnmount', this);
        if (this.props.request) {
            this.props.request.cancel();
        }
    }
    _onLoad() {
       //console.log('NewTopSongs :: _onLoad', this);
        this.props.actions.fetch('home/newTopSongs', {
            pageSize: 4,
            page: 1
        });
    }
    _onLoadMore() {
       //console.log('NewTopSongs :: _onLoadMore', this);
        const {pagination, results} = this.props.results.newTopSongs;
        this.props.actions.fetchMore('home/newTopSongs', {
            pageSize: pagination ? pagination.per_page : 4,
            page: pagination ? (pagination.current_page + 1) : 1
        });
    }
    render() {
       //console.group('NewTopSongs :: Render :: ', {props: this.props, state: this.state});
        const {newTopSongs} = this.props.results || {};
       //console.info(newTopSongs);
       //console.groupEnd();

        if (!newTopSongs || !newTopSongs.results) {
            return <Loading/>;
        } else {
            return (
                <section className="new-top-songs">
                    <div className="row ">
                        <h2 className="col-md h5">New Top Songs</h2>
                    </div>
                    <div className="row ">
                        {newTopSongs.results && newTopSongs.results.map((song, index) =>
                            <div key={song.id} className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                <SongCard key={song.id} song={song}/>
                            </div>
                        )}
                    </div>
                    <LoadMore onClick={this._onLoadMore}/>
                </section>
            );
        }
    }
}



export default connect(
    (state) => {
       //console.group('MapState :: NewTopSongs :: ',  state);
        let results;
        if (state.data && state.data instanceof Map) {
            if (state.data && state.data instanceof Map && state.data.has('home/newTopSongs')) {
                let newTopSongs = state.data.get('home/newTopSongs');
               //console.info(newTopSongs);
                results = {results: newTopSongs};
            }else{
                results = {results: null};
            }
        } else {
            results = {results: null};
        }

       //console.groupEnd();
        return results;
    },
    (dispatch) => {
       //console.group('MapDispatch :: NewTopSongs');
        let actions = configureActions(dispatch, _.assign({}, HomeActions));
       //console.info({actions: actions});
       //console.groupEnd();
        return {
            actions: actions
        }
    }
)(NewTopSongs);
