import React, {PureComponent} from 'react';
import {Animated} from "react-animated-css";

// Import custom components
import DefaultHeader from 'common/components/header/DefaultHeader';
import SidebarLeft from 'common/func/sidebars/SidebarLeft';
import SidebarRight from 'common/func/sidebars/SidebarRight';
import Content from '../../common/components/content/content.component';
import Footer from 'common/components/footer/Footer';

export default function (PublicRouteComponent) {
    return class PublicLayout extends PureComponent {
        render() {
            return (
                <>
                    <DefaultHeader/>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <SidebarLeft/>
                                <Content>
                                    <PublicRouteComponent {...this.props} />
                                </Content>
                                <SidebarRight/>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </>
            );
        }
    }
}
