import React from 'react';

import {Card, Button} from 'react-bootstrap';
import {Animated} from "react-animated-css";
import Content from "common/components/content/content.component";

class VideoCard extends React.PureComponent {
    render() {
        const {video} = this.props;///

        return (
            <Card className="video-card my-2">
                <Card.Img variant="top" src="//via.placeholder.com/300x300" className="card-img-top"/>
                <Card.Body>
                    <Card.Title>
                        <div className="row">
                            <div className="col-md text-center">{video.title}</div>
                        </div>
                    </Card.Title>
                    <div className="row py-1">
                        <div className="col-md text-center">{video.artist.username}</div>
                    </div>
                    <div className="row py-1">
                        <div className="col-md text-left">[...] plays</div>
                        <div className="col-md text-right">xyz Genre</div>
                    </div>
                </Card.Body>
            </Card>
        )
    }
}

export default VideoCard
