//libraries
import _ from 'lodash';

// Import constants
import * as ActionType from 'redux/store/actions/ActionType';


const initialState = {
    entities: new Map(),
    lists: new Map()
};

/**
 * THE CACHE REDUCER NEVER RETURNS A NEW STATE AS IT SHOULD NEVER TRIGGER A RENDER
 */
export default function (state = initialState, action) {
    switch (action.type) {
        case ActionType.CACHE_ADD_LIST:
           //console.group(action.type, ' => EXECUTING', {payload: action.payload});

            if (!state.lists.has(action.payload.key)) {
               //console.info(' *** STORING ...');
                state.lists.set(action.payload.key, action.payload.value);
            } else {
               //console.info(' *** DO NOTHING ...');
            }
           //console.groupEnd();
            return state;

        case ActionType.CACHE_ADD_ENTITY:
           //console.group(action.type, ' => EXECUTING', {payload: action.payload});

            if (!state.entities.has(action.payload.key)) {
               //console.info(' *** STORING ...');
                state.entities.set(action.payload.key, action.payload.value);
            } else {
               //console.info(' *** DO NOTHING ...');
            }
           //console.groupEnd();
            return state;

        /*case ActionType.CACHE_LOAD_LIST:
           //console.log(action.type, ' => EXECUTING (internal)');

            let found = state.lists.get(action.key);
           //console.log('Found => ', found);

            return _.assign({}, state, {
                found: found
            });*/

        /*case ActionType.SELECT_ITEM:
           //console.log(action.type, ' => EXECUTING');
            newState = _.cloneDeep(state);
            newState.selectedItem[action.entity] = action.response.data;
            return newState;

        case ActionType.UPDATE_SELECTED_ITEM:
           //console.log(action.type, ' => EXECUTING');
            newState = _.cloneDeep(state);
            newState.selectedItem[action.entity][action.key] = action.value;
            return newState;

        case ActionType.DELETE:
           //console.log(action.type, ' => EXECUTING');
            newState = _.cloneDeep(state);
            let data = newState[action.entity + 's'];
            let index = _.indexOf(data, _.find(data, {id: action.id}));
            data.splice(index, 1);
            return newState;

        case ActionType.CLEAR_LIST:
           //console.log(action.type, ' => EXECUTING');
            newState = _.cloneDeep(state);
            newState[action.entity + 's'] = {};
            return newState;

        case ActionType.CLEAR_SELECTED_ITEM:
           //console.log(action.type, ' => EXECUTING');
            newState = _.cloneDeep(state);
            newState.selectedItem[action.entity] = {};
            return newState;*/

        default:
            return state;
    }
}
