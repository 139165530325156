import PropTypes from "prop-types";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ClickLink = ({title, faIcon, onClick}) => {

    let isFaIconLeft = faIcon && faIcon.iconPos === 'left';
    let isFaIconRight = faIcon && faIcon.iconPos === 'right';
    let isTextLink = !isFaIconLeft && !isFaIconRight;

    return (
        <a className="link" onClick={onClick}>
            {isFaIconLeft &&
                <><FontAwesomeIcon icon={['fas', faIcon.icon]}/> {title}</>
            }
            {isFaIconRight &&
                <>{title} <FontAwesomeIcon icon={['fas', faIcon.icon]}/></>
            }
            {isTextLink &&
                <>{title}</>
            }
        </a>
    )
}

ClickLink.propTypes = {
    title :  PropTypes.string.isRequired,
    faIcon: PropTypes.shape({
        iconPos: PropTypes.oneOf(['left', 'right']).isRequired,
        icon: PropTypes.string.isRequired
    }),
    onClick: PropTypes.func.isRequired,
}
export default ClickLink
