import _ from "lodash";
import React, {Component} from 'react';
import PropTypes from 'prop-types'; // ES6
import {connect} from 'react-redux';
import {HomeActions} from "redux/store/actions/Actions";
import configureActions from "redux/store/configureActions";

import Loading from "common/components/loaders/Loading";
import LoadMore from "common/components/loaders/LoadMore";
import ArtistCard from 'common/pureComponents/cards/artist/ArtistCard';


class SuggestedArtists extends Component {
    /*static defaultProps = {
        name: 'stranger'
    };*/

    /* static propTypes = {
         page: PropTypes.number,
         pageSize: PropTypes.number
     };*/

    constructor(props) {
        super(props);
        this._onLoadMore = this._onLoadMore.bind(this);
    }

    componentDidMount() {
       //console.log('SuggestedArtists :: componentDidMount', this);
        this._onLoad();
    }

    componentWillUnmount() {
       //console.log('SuggestedArtists :: componentWillUnmount', this);
        if (this.props.request) {
            this.props.request.cancel();
        }
    }

    _onLoad() {
       //console.log('SuggestedArtists :: _onLoad', this);

        this.props.actions.fetch('home/suggestedArtists', {
            pageSize: 4,
            page: 1
        });
    }

    _onLoadMore() {
        //console.log('SuggestedArtists :: _onLoadMore', this);
        const {pagination, results} = this.props.results.suggestedArtists;
        this.props.actions.fetchMore('home/suggestedArtists', {
            pageSize: pagination ? pagination.per_page : 4,
            page: pagination ? (pagination.current_page + 1) : 1
        });
    }

    render() {
        //console.group('SuggestedArtists :: Render :: ', {props: this.props, state: this.state});
        const {suggestedArtists} = this.props.results || {};
        //console.info(suggestedArtists);
        //console.groupEnd();

        if (!suggestedArtists || !suggestedArtists.results) {
            return <Loading/>;
        } else {
            return (
                <section className="suggested-artists">
                    <div className="row ">
                        <h5 className="col-md">Suggested Artists</h5>
                    </div>
                    <div className="row ">
                        {suggestedArtists.results && suggestedArtists.results.map((artist, index) =>
                            <div key={artist.id} className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                                <ArtistCard key={artist.id} artist={artist}/>
                            </div>
                        )}
                    </div>
                    <LoadMore onClick={this._onLoadMore}/>
                </section>
            );
        }
    }
}


export default connect(
    (state) => {
        //console.group('MapState :: SuggestedArtists :: ', state);
        let results;

        if (state.data &&
            state.data instanceof Map &&
            state.data.has('home/suggestedArtists')) {
            let suggestedArtists = state.data.get('home/suggestedArtists');
            //console.info(suggestedArtists);
            results = {results: suggestedArtists};
        } else {
            results = {results: null};
        }
        //console.groupEnd();
        return results;
    },
    (dispatch) => {
        //console.group('MapDispatch :: SuggestedArtists');
        let actions = configureActions(dispatch, _.assign({}, HomeActions));
        //console.info({actions: actions});
        //console.groupEnd();
        return {
            actions: actions
        }
    }
)(SuggestedArtists);

