import React from 'react';
import UserMenu from "common/components/header/UserMenu";
import {NavLink} from 'react-router-dom';
import EnvironmentTag from "../../func/EnvironmentTag";

const DemoHeader = () => (
    <section className="header fixed-top">
        <header className="main-header">
            <nav className="navbar navbar-expand-lg m-0 p-0 ">
                <div className="col-lg-1 d-none d-lg-block">&nbsp;</div>
                <div className="col-lg-10 col-12">
                    <div className="row">
                        <div className="col-5 float-left text-left m-0 p-0">
                            <NavLink to={'/discover'} className="navbar-brand m-0 p-0 pb-1">
                                <img src="/img/logo/logo-hitlab-dna.svg" alt="Hitlab Logo" className="w-100"/>
                            </NavLink>
                        </div>
                        <div className="col-2 mt-2">
                            <EnvironmentTag/>
                        </div>
                        <div className="col-5 float-right text-right">
                            <UserMenu/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-1 d-none d-lg-block">&nbsp;</div>
            </nav>
        </header>
    </section>
);

export default DemoHeader;
