import { Auth } from "aws-amplify";
import FormInput from "common/func/form/FormInput";
import FormMessage from "common/func/form/FormMessage";
import SubmitButton from "common/func/form/SubmitButton";
import BackToLogin from "common/func/navlinks/BackToLogin";
import { getQueryParam } from "common/func/UrlParams";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { NavLink } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { RequestActions } from "redux/store/actions/Actions";
import configureActions from "redux/store/configureActions";
import { history } from "redux/store/configureStore";
import AxiosService from "services/AxiosService";
import TermsConditionsModel from "./TermsConditionsModel";

class Register extends Component {
    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            message: null,
            axiosRef: new AxiosService(),
            showTermsConditions: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async fetchReferralCode(referralCode) {
        try {
            const { axiosRef } = this.state;

            return axiosRef.fetch("referralCodes", {
                "filter[code.eq]": referralCode,
                include: "owner",
            });
        } catch (error) {
            this.setState({
                message: error.message,
            });
        }
    }

    setMessage(message) {
        this.setState({
            message: message,
        });
    }

    resetMessage() {
        this.setMessage("");
    }

    async registerUserOnCognito(formProps, referredBy) {
        return Auth.signUp({
            username: formProps.username,
            password: formProps.password,
            attributes: {
                email: formProps.email,
                "custom:referredBy": referredBy,
            },
        })
            .then((data) => {
                this.setState(
                    {
                        message:
                            "User created! Please check your email for your validation code. You'll be redirected to login in 5 seconds.",
                    },
                    () =>
                        setTimeout(() => {
                            history.push("/login");
                        }, 10000) // 10 seconds
                );
            })
            .catch((err) => {
                //console.log(err)
                this.setState({
                    message: err.message,
                });
            });
    }

    async handleSubmit(formProps) {
        try {
            const { referralCode } = formProps;
            await this.fetchReferralCode(referralCode)
                .then(({ data }) => {
                    let referredBy = {};

                    if (data.length !== 0) {
                        this.resetMessage();

                        const referralCodeOwner =
                            data[0].included[0].attributes;

                        referredBy = {
                            referralCode: referralCode,
                            referrer: {
                                username: referralCodeOwner.username,
                                firstname: referralCodeOwner.firstname,
                                lastname: referralCodeOwner.lastname,
                                email: referralCodeOwner.email,
                            },
                        };
                    }

                    this.registerUserOnCognito(
                        formProps,
                        JSON.stringify(referredBy)
                    );
                })
                .catch((err) => {
                    this.setState({
                        message: err.message,
                    });
                });
        } catch (error) {
            //console.log('error signing up', error);
            this.setState({
                message: error.message,
            });
        }
    }

    render() {
        //console.log('Register :: Render', this.props);
        const { handleSubmit, submitting } = this.props;
        const { message, showTermsConditions } = this.state;
        const termsConditions = <span>Do you accept <span  className="link" onClick={()=> this.setState({showTermsConditions: true})}>terms & conditions</span>?</span>

        return (
            <React.Fragment>
                
            <section className="login py-5">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-4">
                        <h2 className="">Register</h2>
                        <p className="">Register to start your session</p>

                        <form
                            method="post"
                            onSubmit={handleSubmit(this.handleSubmit)}
                            >
                            <div className="">
                                <Field
                                    name="username"
                                    component={FormInput}
                                    type="username"
                                    label="Username"
                                    ></Field>

                                <Field
                                    name="email"
                                    component={FormInput}
                                    type="email"
                                    label="Email"
                                ></Field>
                                <Field
                                    name="password"
                                    component={FormInput}
                                    type="password"
                                    label="Password"
                                    autoComplete="off"
                                    ></Field>
                                <Field
                                    name="passwordConfirm"
                                    component={FormInput}
                                    type="password"
                                    label="Confirm Password"
                                    autoComplete="off"
                                    ></Field>
                                <Field
                                    name="termsConditions"
                                    component={FormInput}
                                    type="checkbox"
                                    autoComplete="off"
                                    title={termsConditions}
                                    ></Field>
                                {/* <Field
                                    name="referralCode"
                                    component={FormInput}
                                    type="referralCode"
                                    label="Referral Code"
                                    autoComplete="off"
                                ></Field> */}
                            </div>

                            <FormMessage message={message} />

                            <div className="float-left">
                                <BackToLogin />
                            </div>

                            <div className="float-right">
                                <SubmitButton
                                    submitting={submitting}
                                    title="Register"
                                    />
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <TermsConditionsModel show={showTermsConditions} onHide={() => this.setState({showTermsConditions: false})} />
            </React.Fragment>
        );
    }
}

/**
 * Connect the component to the Redux store.
 */
export default connect(
    (state) => ({
        token: state.auth && state.auth.token,
        isAuthenticated: state.auth && state.auth.isAuthenticated,
        message: "", //state.flash.message
    }),
    (dispatch) => ({
        actions: configureActions(dispatch, _.assign({}, RequestActions)),
    })
)(
    reduxForm({
        form: "Register", // ←A Unique identifier for this form
        validate: (values) => {
            const errors = {};

            if (!values.username) {
                errors.username = "(The username field is required.)";
            } else if (
                values.username &&
                !/^\S+\w{4,32}\S{1,}$/i.test(values.username)
            ) {
                errors.username = "Invalid username.";
            }

            if (!values.email && !values.phone) {
                errors.email = "(The email field is required.)";
            } else if (
                values.email &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = "Invalid email address.";
            }

            const phoneNumberHasEnoughDigits = !/^\+\d{11}$/i.test(
                values.phone_number
            );

            // E.164 - Standards
            if (values.phone_number && phoneNumberHasEnoughDigits) {
                errors.phone_number = "Invalid phone number.";
            }

            if (!values.password) {
                errors.password = "(The password field is required.)";
            } else {
                if (!values.passwordConfirm) {
                    errors.passwordConfirm =
                        "(The password confirmation is required.)";
                } else if (values.password !== values.passwordConfirm) {
                    errors.passwordConfirm =
                        "(The password confirmation does not match the password.)";
                }
            }

            if (!values.termsConditions) {
                errors.termsConditions = "(The terms and conditions need to be accepted.)";
            }

            // if (!values.referralCode) {
            //     errors.referralCode =
            //         "(The referral code needs to be provided.)";
            // }

            return errors;
        }, // ←Callback function for client-side validation
        initialValues: {
            referralCode: getQueryParam("refc"),
        },
    })(Register)
);
