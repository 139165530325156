//libraries
import _ from 'lodash';

// Import constants
import * as ActionType from 'redux/store/actions/ActionType';


const entityTemplate = {
    apiPath: null,
    params: [],
    request: null,
    //------------------
    entity: null,
    //------------------
};
const listTemplate = {
    apiPath: null,
    params: [],
    request: null,
    //------------------
    list: null,
    //------------------
};
const resultsTemplate = {
    apiPath: null,
    params: [],
    request: null,
    //------------------
    results: null,
    //------------------
};
const dataTemplate = {
    apiPath: null,
    params: [],
    request: null,
    //------------------
    data: null,
    //------------------
};
const paginationTemplate = {
    page: 1,
    pageSize: 16,
    count: 0,
    current_page: 1,
    links: {previous: '', next: ''},
    per_page: 16,
    total: 0,
    total_pages: 84
};
let initialState = new Map();


/**
 * A reducer takes two arguments, the current state and an action.
 */
export default function (state = initialState, action) {
    let prevState, newState, wasEmpty, dataKey;
    switch (action.type) {
        case ActionType.LOAD_ENTITY_FROM_CACHE:
        case ActionType.LOAD_ENTITY:
           //console.group('DataReducer :: ' + action.type + ' => EXECUTING', action.payload);
            //-----------------------------------------------------------------------------------
            wasEmpty = state.size === 0;//VERY IMPORTANT
            dataKey = action.payload.apiPath;
            state.set(dataKey, action.payload.result);
            //-----------------------------------------------------------------------------------
            newState = _.cloneDeep(state);
            //-----------------------------------------------------------------------------------
           //console.info({action: action, prevState: state, nextState: newState, wasEmpty: wasEmpty});
           //console.groupEnd();
            return newState;

        case ActionType.LOAD_LIST_FROM_CACHE:
        case ActionType.LOAD_LIST:
        case ActionType.LOAD_LEADERBOARD_FROM_CACHE:
        case ActionType.LOAD_LEADERBOARD:
        case ActionType.LOAD_RESULTS_FROM_CACHE:
        case ActionType.LOAD_RESULTS:
           //console.group('DataReducer :: ' + action.type + ' => EXECUTING', action.payload);
            //-----------------------------------------------------------------------------------
            wasEmpty = state.size === 0;//VERY IMPORTANT
            dataKey = action.payload.apiPath;
            state.set(dataKey, action.payload.results);
            //-----------------------------------------------------------------------------------
            newState = _.cloneDeep(state);
            //-----------------------------------------------------------------------------------
           //console.info({action: action, prevState: state, nextState: newState, wasEmpty: wasEmpty});
           //console.groupEnd();
            return newState;

        case ActionType.DATA_APPEND_RESULTS:
           //console.group('DataReducer :: ' + action.type + ' => EXECUTING', action.payload);
            //-----------------------------------------------------------------------------------
            if (action.payload && action.payload.apiPath && action.payload.params && action.payload.results) {
                newState = _.cloneDeep(state);
                if (state.has(action.payload.apiPath)) {
                    //APPEND
                    let prevResults = _.pick(state.get(action.payload.apiPath), _.keys(action.payload.results));

                    for (let prop in action.payload.results) {
                        let prevPerPage = prevResults[prop].pagination.per_page;
                        let newPerPage = prevPerPage + action.payload.results[prop].pagination.per_page;
                        let newCount = prevResults[prop].pagination.count + action.payload.results[prop].pagination.count;
                        action.payload.results[prop].pagination = _.assign({}, prevResults[prop].pagination, {
                            count: newCount,
                            current_page: action.payload.results[prop].pagination.current_page,
                            links: action.payload.results[prop].pagination.links
                        });
                        //--------------------------------------------------------------------------------------------------------------------------------------
                        for (let elem in action.payload.results[prop].pagination.links) {
                            let link = action.payload.results[prop].pagination.links[elem];
                            action.payload.results[prop].pagination.links[elem] = link.replace('pageSize=' + prevPerPage, 'pageSize=' + newPerPage);
                        }
                        //--------------------------------------------------------------------------------------------------------------------------------------
                        action.payload.results[prop].results = _.concat(prevResults[prop].results, action.payload.results[prop].results);
                    }
                    newState.set(action.payload.apiPath, action.payload.results);
                } else {
                    //NORMAL ADD
                    newState.set(dataKey, action.payload.results);
                }
            } else {
               //console.error("Invalid action payload");
                newState = state;
            }
            //-----------------------------------------------------------------------------------
           //console.info({action: action, prevState: state, nextState: newState, wasEmpty: wasEmpty});
           //console.groupEnd();
            return newState;

        case ActionType.ENQUEUE_SONG:
           //console.group('DataReducer :: ' + action.type + ' => EXECUTING', action.payload);
            //-----------------------------------------------------------------------------------

            state.set('player', {
                nowPlaying : action.payload.song
            });
            //-----------------------------------------------------------------------------------
            newState = _.cloneDeep(state);
            //-----------------------------------------------------------------------------------
           //console.info({action: action, prevState: state, nextState: newState, wasEmpty: wasEmpty});
           //console.groupEnd();
            return newState;

        case ActionType.DATA_CLEAR:
            //case '@@router/LOCATION_CHANGE':
           //console.group('DataReducer :: ' + action.type + ' => EXECUTING');
           //console.info("Resetting to initial state");
           //console.groupEnd();
            return initialState;//_.cloneDeep(initialState);

        default:
            return state;
    }
}
