import {ActionType, ApiActions, ExceptionActions} from 'redux/store/actions/Actions';
//**********************************************************************************
import AxiosService from "services/AxiosService";
import _ from "lodash";
//******************************************************************************

export function fetchLeaderboard(params) {
    return (dispatch, getState) => {
        let apiPath = 'leaderboard';
        //--------------------------------------------------------
        //Check in cache, then call API
        let cacheKey = apiPath + '_' + JSON.stringify(params);
        let cacheValue = getState().cache.lists.get(cacheKey);
        //--------------------------------------------------------
       //console.group('ApiAction :: fetching ::', (cacheValue ? 'FROM CACHE' : 'FROM SERVER'));
       //console.info({apiPath: apiPath, params: params, prevState: getState(), cacheKey: cacheKey, cacheValue: cacheValue});
       //console.groupEnd();


        if (cacheValue) {
            dispatch({
                type: ActionType.LOAD_LEADERBOARD_FROM_CACHE,
                payload : cacheValue
                /*apiPath: apiPath,
                params: params,
                data: cacheValue.data,
                pagination: cacheValue.pagination,
                request: null,*/
                //getState: getState()
            });
        } else {
            const axiosService = new AxiosService();
            dispatch(ApiActions.apiRequest(axiosService, 'LeaderboardActions :: fetch :: ' + apiPath));//start-request

            axiosService.fetch(apiPath, params)
                .then((response) => {
                    dispatch(ApiActions.apiResponse(response));//end-request

                    if (response && response.data) {
                        let payload = _.assign({}, {
                            apiPath: apiPath,
                            params: params,
                            //request: axiosService
                        }, response.data);
                        //***********************************************************
                        dispatch({
                            type: ActionType.LOAD_LEADERBOARD,
                            payload: payload
                        });
                        //***********************************************************
                        dispatch({
                            type: ActionType.CACHE_ADD_LIST,
                            payload: {
                                key: cacheKey,
                                value: payload
                            }
                        });
                        //***********************************************************
                    } else {
                        ExceptionActions.errorHandler(dispatch, response, ActionType.INVALID_RESPONSE_FORMAT);
                    }
                })
                .catch((error) => {
                    ExceptionActions.errorHandler(dispatch, error, ActionType.FAILURE);
                });
        }
    };
}


