import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SingleClickButton from "common/components/buttons/SingleClickButton";
import React from 'react';

import {Card, Button} from 'react-bootstrap';
import {Animated} from "react-animated-css";
import Spinner from "react-bootstrap/Spinner";
import AxiosService from "services/AxiosService";
import AudioPlayer, {globalPlayer} from "common/components/player/media-player/AudioPlayer";
import moment from "moment";

class RecommendationSongListCard extends React.PureComponent {

    constructor(props) {
        super(props);

        this.axios = new AxiosService();
        this.state = {
            song: null,
            icon: 'play-circle',
        };

        this._onStop = this._onStop.bind(this);
        this._handlePlaySong = this._handlePlaySong.bind(this);
    }

    componentWillUnmount() {
        this._onStop = null
    }

    _onStop() {
        this.setState({'icon': 'play-circle'});
    }

    _handlePlaySong() {
        globalPlayer.enqueueSong(this.props.song, this._onStop);
        this.setState({'icon': 'pause-circle'});
    };


    render() {
        const {song, onShowRecommendations, onDelete} = this.props;

        let isOnRecommendationEnabled = Boolean(song.is_features_valid && onShowRecommendations);
        let isOnDeleteEnabled = Boolean(onDelete);

        return (
            <Card className="songlist-card mb-2 p-2">
                <div className="row align-items-center">
                    <div className="col-6">
                        <div className="text-left link" onClick={this._handlePlaySong}>
                            <FontAwesomeIcon icon={this.state.icon} size="lg"/> {song.artist} - {song.title}
                        </div>
                        <div className="text-left"><small><i>{song.album}</i></small></div>
                    </div>
                    <div className="col-3">
                        <div className="text-right"><b>{song.year}</b></div>
                        <div className="text-right"><small><b>{song.genre}</b></small></div>
                    </div>
                    <div className="col-3">
                        <div className="text-right">
                            {!song.is_features_valid &&
                            <span className="mx-1"><Spinner animation="border" variant="dark" size="sm"/><small>&nbsp;Fingerprinting...</small></span>
                            }
                            {isOnRecommendationEnabled &&
                            <button className="btn btn-sm btn-primary mx-1" onClick={onShowRecommendations}><small>Recommendations</small></button>
                            }
                            {isOnDeleteEnabled &&
                            <button className="btn btn-sm btn-primary mx-1" onClick={onDelete}><small>Delete</small></button>
                            }
                        </div>
                    </div>
                    {/*<div className="col-md-1">
                        <FontAwesomeIcon icon={['fas', 'grip-lines']} className="text-secondary icon-full"/>
                    </div>*/}
                </div>
            </Card>
        )
    }
}


export default RecommendationSongListCard
