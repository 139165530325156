import React from "react";
import PropTypes from "prop-types";

const FormInput = ({ input, label, type, autoComplete, value, title, meta: { touched, error, invalid, warning }, children }) => (
    <div className={`form-group has-feedback ${touched && invalid ? "has-error" : ""}`}>
        {title && type=="checkbox" && (
            <div className="form-check">
                <input className="form-check-input" {...input} type={type} autoComplete={autoComplete} />
                <label className="form-check-label">{title}</label>
            </div>
        )}
        {!title && <input {...input} type={type} className="form-control" placeholder={label} autoComplete={autoComplete} />}
        {children}
        <div className="help-block">{touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}</div>
    </div>
);

FormInput.propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
    autoComplete: PropTypes.oneOf(["on", "off", "new-password", "current-password"]),
    meta: PropTypes.object,
    children: PropTypes.element,
    title: PropTypes.element
};

FormInput.defaultProps = {
    autoComplete: "off",
};

export default FormInput;
